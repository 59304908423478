import * as React from 'react';
import { getStringConstants } from '../../../../../config/localization';
import css from '../emailauth.module.css';

export default function AMThankYou() {
  const strings = getStringConstants();

  const onAppStoreClick = () => {
    window.location.href = `https://apps.apple.com/us/app/allocare-transplant-health/id1497962881`;
  };

  const onPlayStoreClick = () => {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.caredx.allocare';
  };

  return (
    <div className={css.tyContainer}>
      <div className={css.tyTitle}>{strings.TY_TITLE}</div>
      <div className={css.tyText}>{strings.SU_TY_YOU_HAVE_CONNECTED}</div>
      <div className={css.tyText}>{strings.SU_TY_IF_IPHONE}</div>
      <div className={css.suTyAppStore} onClick={onAppStoreClick}></div>
      <div className={css.tyText}>{strings.SU_TY_IF_ANDROID}</div>
      <div className={css.suTyPlayStore} onClick={onPlayStoreClick}></div>
      <div className={css.tyText}>{strings.SU_TY_TO_INSTALL_SCAN}</div>
      <div className={css.suTyQRCode}></div>
    </div>
  );
}
