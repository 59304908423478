import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_URL + '/';
// types
import { apiReportType } from '../types/api/report';

export const apiReport: apiReportType = {
  getReport: async (): Promise<{ data: any }> => {
    return await axios({
      url: `${baseUrl}chat/Dialog.json`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
    });
  },
};
