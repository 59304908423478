import { put, call, delay } from 'redux-saga/effects';
// api
import { apiModeratedMessage } from '../../api/moderatedMessage';
// actions
import appAction from '../actions/app';
import moderatedMessageAction from '../actions/moderatedMessage';
// types
import { getModeratedMessageActionReturnType } from '../../types/actions/moderatedMessage';
// helpers
import { handleLoginRedirect } from './helpers';

export function* sagaGetModeratedMessage(action: getModeratedMessageActionReturnType): any {
  console.log(action);
  try {
    yield delay(1);
    yield put(moderatedMessageAction.moderatedMessageLoading(false, 0));
    yield put(moderatedMessageAction.moderatedMessageLoading(true, 90));
    const responseMessage = yield call(apiModeratedMessage.getModeratedMessage);
    yield put(moderatedMessageAction.moderatedMessageLoading(true, 100));
    yield put(moderatedMessageAction.getModeratedMessage(responseMessage.data));
    yield delay(1000);
    yield put(moderatedMessageAction.moderatedMessageLoading(false, 0));
  } catch (e) {
    if (e.response.status === 401) {
      handleLoginRedirect();
    } else {
      yield put(moderatedMessageAction.moderatedMessageLoading(true, 100));
      yield put(moderatedMessageAction.moderatedMessageLoading(false, 100));
      yield put(
        appAction.appNotification(
          true,
          {
            header: 'Error',
            message: e.response.data.message || e.response.data.errors[0] || e.response.data.errors.base[0] || 'Error',
          },
          'Error',
        ),
      );
      yield delay(1000);
      yield put(moderatedMessageAction.moderatedMessageLoading(false, 0));
    }
  }
}
