import React, { useState, useEffect, FC } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import AllocareOktaSignInWidget from './AllocareOktaSignInWidget';
import { useOktaAuth } from '@okta/okta-react';

//actions
import appAction from '../../../../../redux/actions/app';
// types
import { OktaSignInConfigType } from '../../../../../types/app';
import css from '../emailauth.module.css';
import authTransition from '../../../transitions/authTransition.module.css';
import { apiApp } from '../../../../../api/app';
import AMAlreadyLinked from './AMAlreadyLinked';
import Confirmation from '../Confirmation';

import CareDxLogo from './CareDxLogo.svg';
import AMThankYou from './AMThankyou';

interface SignInProps {
  signUp: () => void;
  mapToken: string;
  mapInfo: any;
  oktaConfig: OktaSignInConfigType;
}

const AuthPage: FC<SignInProps> = (props: SignInProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { oktaAuth, authState } = useOktaAuth();
  const [token, setToken] = useState<string | null>(authState?.accessToken?.value);
  const [allocareInfo, setAllocareInfo] = useState<any>(null);
  const [hasConfirmed, setHasConfirmed] = useState(false);

  useEffect(() => {
    if (token && !allocareInfo) {
      const patientId = apiApp.decodeToken(token);
      apiApp.getAllocareFhirPatient(token, patientId as string).then((x) => {
        const phone = x.data.telecom[0].value;
        const dob = x.data.birthDate;
        const firstName = x.data.name[0]?.given[0];
        const lastName = x.data.name[0]?.family;
        setAllocareInfo({ phone, dob, firstName, lastName });
      });
    }
  }, [token]);

  const onLoginSuccess = (tokens: { accessToken: { accessToken: string } }) => {
    oktaAuth.tokenManager.setTokens(tokens);
    setToken(tokens.accessToken.accessToken);
  };

  const onLoginError = (err: any) => {
    dispatch(
      appAction.appNotification(
        true,
        {
          header: 'Error',
          message: err,
        },
        'Error',
      ),
    );
  };

  async function onMapDataConfirmed(matchesAllocare: boolean) {
    // if it doesn't match allocare we need to manually link
    // either way we need to call map and post that the user has confirmed
    if (
      !matchesAllocare ||
      allocareInfo.firstName !== props.mapInfo.firstName ||
      allocareInfo.lastName !== props.mapInfo.lastName
    ) {
      await apiApp.manuallyLinkAllocareMap(token as string, props.mapInfo.mapid);
    }
    await apiApp.confirmMap(props.mapToken);
    setHasConfirmed(true);
  }

  return (
    <div style={{ paddingLeft: '1px', paddingRight: '1px', paddingBottom: '25px' }}>
      {authState?.isAuthenticated && allocareInfo ? (
        !hasConfirmed ? (
          <Confirmation
            allocareDob={allocareInfo.dob}
            allocarePhone={allocareInfo.phone}
            mapDob={props.mapInfo.dateOfBirth}
            mapPhone={props.mapInfo.phoneNumber}
            onConfirmed={onMapDataConfirmed}
            isSignup={false}
          />
        ) : (
          <AMThankYou />
        )
      ) : (
        <AllocareOktaSignInWidget
          config={{
            ...props.oktaConfig,
            ...{
              username: props.mapInfo.email,
              features: {
                showPasswordToggleOnSignInPage: true,
              },
            },
          }}
          onSuccess={onLoginSuccess}
          onError={onLoginError}
          onSignUpClick={props.signUp}
        />
      )}
    </div>
  );
};

export default AuthPage;
