import React, { FC, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
// actions
import appAction from '../../../redux/actions/app';
// style
import css from './userDropdown.module.css';

const UserDropdown: FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();

  const callback = async () => {
    oktaAuth.signOut();
    history.push('/login');
  };

  const handleLogout = () => {
    dispatch(appAction.logout(callback));
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setIsVisible(false)}>
      <div className={css.userDropdownWrapper}>
        <div onClick={() => setIsVisible(!isVisible)}>
          <span onClick={handleLogout}>Logout</span>
          {/* <span>Username</span> */}
        </div>
        {/* {isVisible && (
          <div className={css.userDropdownList}>
            <ul>
              <li>
                <Link to="/profile">Profile</Link>
              </li>
              <li>
                <Link to="/activity">Activity</Link>
              </li>
              <li>
                <Link to="/instruction">Instruction</Link>
              </li>
              <li onClick={handleLogout}>Logout</li>
            </ul>
          </div>
        )} */}
      </div>
    </OutsideClickHandler>
  );
};

export default UserDropdown;
