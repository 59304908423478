import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
// actions
import appAction from '../../../redux/actions/app';
// types
import { notificationType } from '../../../types/actions/app';
// icons
import IconError from '../../../assets/icons/error.svg';
import IconSuccess from '../../../assets/icons/success.svg';
import IconWarning from '../../../assets/icons/warning.svg';
// styles
import css from './notification.module.css';

let timeID: any = false;
const Notification: FC = () => {
  const notification = useSelector((state: { app: { notification: notificationType } }) => state.app.notification);

  const dispatch = useDispatch();

  useEffect(() => {
    if (notification.status) {
      clearTimeout(timeID);
      if (timeID) {
        clearTimeout(timeID);
      }
      timeID = setTimeout(() => {
        dispatch(
          appAction.appNotification(
            false,
            { header: notification.message.header, message: notification.message.body },
            notification.type,
          ),
        );
      }, 10000);
    }
  }, [notification.status]);

  const close = () => {
    dispatch(
      appAction.appNotification(
        false,
        { header: notification.message.header, message: notification.message.body },
        notification.type,
      ),
    );
  };

  const initNotificationIcon = (type: string) => {
    switch (type) {
      case 'Error':
        return `${IconError}`;
      case 'Warning':
        return `${IconWarning}`;
      case 'Success':
        return `${IconSuccess}`;

      default:
        return '';
    }
  };

  return (
    <div>
      <CSSTransition in={notification.status} timeout={1000} classNames={{ ...css }} unmountOnExit={true} appear={true}>
        <div
          className={`${
            notification.type === 'Warning'
              ? css.notificationWarning
              : notification.type === 'Error'
              ? css.notificationError
              : css.notificationSuccess
          } ${!notification.message.body ? css.empty : ''}`}
        >
          <div className={css.iconClose} onClick={close}></div>
          <img src={initNotificationIcon(notification.type)} alt="notification" />
          <div className={css.messageContainer}>
            <span className={css.textSubheader}>{notification.message.header}</span>
            <p className={css.textCaption}>{notification.message.body}</p>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Notification;
