import React, { FC, useEffect, useRef } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
// types
import { OktaSignInConfigType } from '../../../types/app';
// style
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

interface OktaWidgetProps {
  config: OktaSignInConfigType;
  onSuccess: () => void;
  onError: () => void;
}

const OktaWidget: FC<any> = ({ config, onSuccess, onError }: OktaWidgetProps) => {
  const widgetRef = useRef<any>();

  useEffect(() => {
    const widget = new OktaSignIn(config);

    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(onSuccess)
      .catch(onError);

    return () => widget.remove();
  }, [config, onSuccess, onError]);

  return <div ref={widgetRef} />;
};
export default OktaWidget;
