import React, { useState, useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// components
import LoadingBar from '../../LoadingBar/LoadingBar';
// actions
import usersAction from '../../../../redux/actions/users';
// types
import { loadingType } from '../../../../types/actions/app';
import { usersListType } from '../../../../types/users';
//style
import css from './banUserModal.module.css';

interface BanUserModalProps {
  id: number;
  handlerModal: () => void;
  status: boolean;
  loading: loadingType;
  name: string;
}

const BanUserModal: FC<BanUserModalProps> = ({ id, handlerModal, status, loading, name }: BanUserModalProps) => {
  const [loadingBarProgress, updateProgress] = useState<number>(0);

  const dispatch = useDispatch();

  //store
  const users = useSelector((state: { users: { list: usersListType } }) => state.users.list);

  useEffect(() => {
    updateProgress(loading.progress);
  }, [loading]);

  const handleSubmit = () => {
    dispatch(usersAction.userStatus(id, !status, users, handlerModal));
  };

  return (
    <div className={css.banUserModalWrapper}>
      <LoadingBar
        loadingBarProgress={loadingBarProgress}
        onLoaderFinished={() => {
          updateProgress(0);
        }}
      />
      <div className={css.banUserModalInner}>
        <h3>Are you sure you want to {`${status ? 'unban' : 'ban'} ${name}`}?</h3>
        <div>
          <button disabled={loading.status} onClick={handleSubmit} className={css.banUserBtn}>
            {status ? 'Unban' : 'Ban'}
          </button>
          <button disabled={loading.status} onClick={handlerModal} className={css.banUserBtn}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default BanUserModal;
