// types
import {
  topicCreateReturnType,
  topicStatusReturnType,
  topicLoadingReturnType,
  getTopicActionReturnType,
  getTopicReturnType,
  topicEditReturnType,
  statusLoadingReturnType,
  topicActionType,
} from '../../types/actions/topic';
import { createTopicParamsType, topicType } from '../../types/topics';
// consts
import * as types from '../constants/actionConstants';

const topicAction: topicActionType = {
  getTopicAction(id: string): getTopicActionReturnType {
    return {
      type: types.GET_TOPIC,
      id,
    };
  },
  getTopic(topic: topicType): getTopicReturnType {
    return {
      type: types.TOPIC,
      payload: { topic },
    };
  },
  clearTopic(): getTopicReturnType {
    return {
      type: types.TOPIC,
      payload: {
        topic: {
          created_at: 0,
          created_time: '',
          last_message: '',
          last_message_date_sent: 0,
          last_message_id: '',
          last_message_user_id: 0,
          name: '',
          description: '',
          participants: [],
          photo: '',
          topic_type: 0,
          unread_messages_count: 0,
          updated_at: 0,
          updated_time: '',
          user_id: 0,
          xmpp_room_jid: 0,
          _id: '',
          public_dialog_id: '',
          is_hidden: false,
        },
      },
    };
  },
  topicEdit(data: { name: string; description: string }, id: string): topicEditReturnType {
    return {
      type: types.TOPIC_EDIT,
      data,
      id,
    };
  },
  topicLoading(status: boolean, progress: number): topicLoadingReturnType {
    const loading = {
      status: status,
      progress: progress,
    };
    return {
      type: types.TOPIC_LOADING,
      payload: {
        loading: { ...loading },
      },
    };
  },
  topicCreate(data: createTopicParamsType, callback: () => void): topicCreateReturnType {
    return {
      type: types.TOPIC_CREATE,
      data,
      callback,
    };
  },
  topicStatus(topicId: string, callback: () => void, isHidden: boolean): topicStatusReturnType {
    return {
      type: types.TOPIC_STATUS,
      topicId,
      callback,
      isHidden,
    };
  },
  statusLoading(status: boolean, progress: number): statusLoadingReturnType {
    const loading = {
      status: status,
      progress: progress,
    };
    return {
      type: types.TOPIC_STATUS_LOADING,
      payload: {
        statusLoading: { ...loading },
      },
    };
  },
};

export default topicAction;
