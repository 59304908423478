import { loadingType } from '../../types/actions/app';
import { surveyType } from '../../types/survey';

const initialState = {
  survey: {
    created_at: 0,
    participants_id: [],
    survey_ans: ['MedicationA', 'MedicationB', 'MedicationC', 'MedicationD', 'MedicationE'],
    survey_question: '',
    survey_votes: [1, 0, 0, 0, 0],
    updated_at: 0,
    user_id: 0,
    _id: '',
    _parent_id: '',
  },
  loading: {
    status: false,
    progress: 0,
  },
};

const survey = (
  state = initialState,
  action: {
    type: string;
    payload: {
      survey: surveyType;
      loading: loadingType;
    };
  },
): {
  survey: surveyType;
  loading: loadingType;
} => {
  switch (action.type) {
    case 'SURVEY':
      return Object.assign({}, state, {
        survey: action.payload.survey,
      });
    case 'SURVEY_LOADING':
      return Object.assign({}, state, {
        loading: action.payload.loading,
      });

    default:
      return state;
  }
};

export default survey;
