import * as React from 'react';
import css from '../emailauth.module.css';
import { getStringConstants } from '../../../../../config/localization';

interface AMAlreadyLinkedProps {
  firstName: string;
  lastName: string;
}

export default function AMAlreadyLinked(props: AMAlreadyLinkedProps) {
  const strings = getStringConstants();
  return (
    <div className={css.alreadyLinkedWrapper}>
      <div className={css.alreadyMappedContainer}></div>
      <div className={css.alTextContainer}>
        <div className={css.alYouAreConnected}>{strings.AL_YOU_ARE_CONNECTED}</div>
        <div className={css.alFnLn}>
          {props.firstName}, {props.lastName}
        </div>
        <div className={css.alOpenApp}>{strings.AL_OPEN_APP}</div>
        <div className={css.alQuestions}>{strings.AL_QUESTIONS}</div>
      </div>
    </div>
  );
}
