import React, { FC } from 'react';
//components
import Notification from '../../containers/Notification/Notification';
import Header from '../../containers/Header/Header';
// style
import css from './topicsTemplate.module.css';

interface TopicsTemplateProps {
  children: React.ReactNode;
}

const TopicsTemplate: FC<TopicsTemplateProps> = ({ children }: TopicsTemplateProps) => {
  return (
    <>
      <Notification />
      <Header logout />
      <div className={css.topicsContainer}>{children}</div>
    </>
  );
};

export default TopicsTemplate;
