import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_URL + '/';
// types
import { apiModeratedMessageType } from '../types/api/moderatedMessage';

export const apiModeratedMessage: apiModeratedMessageType = {
  getModeratedMessage: async (): Promise<{ data: any }> => {
    return await axios({
      url: `${baseUrl}chat/Dialog.json`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
    });
  },
};
