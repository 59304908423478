import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_URL + '/';
// types
import { apiTopicType } from '../types/api/topic';
import { customTopicType, topicType } from '../types/topics';

export const apiTopic: apiTopicType = {
  getTopic: async (id: string): Promise<{ data: topicType }> => {
    return await axios({
      url: `${baseUrl}data/ChatTopic.json?_id=${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
    });
  },
  // editTopic: async (data: { name: string; description: string }, id: string): Promise<{ data: topicType }> => {
  //   return await axios({
  //     url: `${baseUrl}chat/Dialog/${id}.json`,
  //     method: 'PUT',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'qb-token': `${localStorage.getItem('token')}`,
  //     },
  //     data,
  //   });
  // },
  createPublicTopic: async (data: { type: string; name: string }): Promise<{ data: topicType }> => {
    return await axios({
      url: `${baseUrl}chat/Dialog.json`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
      data,
    });
  },
  createCustomTopic: async (data: customTopicType): Promise<{ data: topicType }> => {
    return await axios({
      url: `${baseUrl}data/ChatTopic.json`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
      data,
    });
  },
  editTopic: async (topicId: string, data: { is_hidden?: boolean; comments?: number }): Promise<{ data: string }> => {
    return await axios({
      url: `${baseUrl}data/ChatTopic/${topicId}.json`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
      data,
    });
  },
};
