import React, { FC, useState } from 'react';
import { Modal } from 'semantic-ui-react';
// components
import ChangeTopicStatusModal from '../modals/ChangeTopicStatusModal/ChangeTopicStatusModal';
// style
import css from '../modals/ChangeTopicStatusModal/changeTopicStatusModal.module.css';

interface ChangeTopicStatusProps {
  disabled: boolean;
  isHidden: boolean;
  topicId: string;
}

const ChangeTopicStatus: FC<ChangeTopicStatusProps> = ({ disabled, isHidden, topicId }: ChangeTopicStatusProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handlerModal = () => {
    !disabled && setModalOpen(!modalOpen);
  };

  return (
    <>
      <Modal
        size="tiny"
        open={modalOpen}
        onClose={handlerModal}
        className={css.changeTopicStatusModal}
        closeOnDimmerClick={!disabled}
        trigger={
          <button onClick={handlerModal} type="button" className={css.topicStatusChangeBtn} disabled={disabled}>
            {isHidden ? 'Restore topic' : 'Delete topic'}
          </button>
        }
      >
        <div className={css.iconClose} onClick={handlerModal} />
        <ChangeTopicStatusModal handlerModal={handlerModal} topicId={topicId} isHidden={isHidden} />
      </Modal>
    </>
  );
};

export default ChangeTopicStatus;
