import { compose, createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers/index';
import createSagaMiddleware from 'redux-saga';
import { sagaWatcher } from './sagas/index';

const saga = createSagaMiddleware();

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const checkTokenExpirationMiddleware =
  () =>
  (next: (action: { type: string; payload?: any; data?: any }) => void) =>
  (action: { type: string; payload?: any; data?: any }) => {
    const token = localStorage.getItem('token');
    if (!token && action.type !== 'APP_LOGIN' && action.type !== 'APP_NOTIFICATION') {
      next(action);
      localStorage.clear();
      window.location.href = '/login';
    }
    next(action);
  };

const composeSetup =
  process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

export const store = createStore(rootReducer, composeSetup(applyMiddleware(checkTokenExpirationMiddleware, saga)));

saga.run(sagaWatcher);

export default store;
