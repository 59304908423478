import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
// components
import Notification from '../Notification/Notification';
import LoadingBar from '../LoadingBar/LoadingBar';
// actions
import topicAction from '../../../redux/actions/topic';
//types
import { loadingType } from '../../../types/actions/app';
import { paramsType } from '../../../types/app';
// style
import css from './createTopic.module.css';
// helpers
import { options } from '../../../config/topicNames';

interface CreateTopicContainerProps {
  match: paramsType;
}

const CreateTopicContainer: FC<CreateTopicContainerProps> = ({ match }: CreateTopicContainerProps) => {
  const [name, setName] = useState<string>('Kidney');
  const [description, setDescription] = useState<string>('');
  const [loadingBarProgress, updateProgress] = useState<number>(0);
  const [answers, setAnswers] = useState<any>({
    answer1: '',
    answer2: '',
    answer3: '',
    answer4: '',
    answer5: '',
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const type = match.params.id;

  // store
  const createTopicLoading = useSelector((state: { topic: { loading: loadingType } }) => state.topic.loading);

  useEffect(() => {
    updateProgress(createTopicLoading.progress);
    return () => {
      updateProgress(0);
    };
  }, [createTopicLoading]);

  const newAnswers: any = Object.values(answers).filter((item) => item) || [''];

  const data = {
    type,
    name,
    description,
    answers: newAnswers.length > 1 ? newAnswers : [''],
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setName(e.target.value);
  };

  // array forming for layout
  const answersArr = () => {
    const arr = [];
    for (let i = 1; i <= 5; i++) {
      arr.push({ [`${'answer'}`]: i, value: '' });
    }

    return arr;
  };

  // handle empty answers
  const handleAnswerValues = (name: string, value: string) => {
    const answerNumber = Number(name.slice(name.length - 1, name.length));

    if (value === '' && answers[`answer${answerNumber + 1}`] && answers[`answer${answerNumber + 1}`].length > 0) {
      handleAnswerDelete(answerNumber);
    } else {
      setAnswers({
        ...answers,
        [name]: value,
      });
    }
  };

  // handleChange for answers inputs
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    handleAnswerValues(name, value);
  };

  // handle deleting answers via button or input
  const handleAnswerDelete = (answerNumber: number) => {
    const obj: any = {};

    for (let i = 1; i <= 5; i++) {
      if (i >= answerNumber) {
        obj[`answer${i}`] = answers[`answer${i + 1}`] || '';
      } else {
        obj[`answer${i}`] = answers[`answer${i}`] || '';
      }
    }

    setAnswers(obj);
  };

  const callback = () => {
    history.push('/');
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(topicAction.topicCreate(data, callback));
  };

  return (
    <div className={css.createTopic}>
      <Notification />
      <div className={css.createTopicWrapper}>
        <div className={css.createTopicInner}>
          <h2 className={css.createTopicHeading}>Create a {type === '1' ? 'chat' : 'survey'}</h2>
          <form onSubmit={submitHandler} className={css.createTopicForm}>
            <div className={css.createTopicLoading}>
              <LoadingBar loadingBarProgress={loadingBarProgress} onLoaderFinished={() => updateProgress(0)} />
            </div>
            <label htmlFor="topic-name">Category:</label>
            <select
              name="topicName"
              id="topic-name"
              onChange={handleSelect}
              required
              value={name}
              disabled={createTopicLoading.status}
            >
              {options.map((item) => (
                <option key={item}>{item}</option>
              ))}
            </select>
            <label htmlFor="topic-description">{type === '1' ? 'Description' : 'Question'}</label>
            <textarea
              id="topic-description"
              disabled={createTopicLoading.status}
              name="topicDescription"
              onChange={(e) => setDescription(e.target.value)}
              required
              value={description}
              placeholder={`Write your ${type === '1' ? 'message' : 'question'} here...`}
              maxLength={250}
            />
            {type === '1' && (
              <span className={css.createTopicText}>
                {description.length === 250 ? 'Max characters' : 250 - description.length + ' characters left'}
              </span>
            )}
            {type === '2' && (
              <>
                <hr />
                <div className={css.createTopicAnswersContainer}>
                  {answersArr().map(
                    (item) =>
                      (item.answer === 1 ||
                        item.answer === 2 ||
                        (answers.answer1.length > 0 &&
                          answers.answer2.length > 0 &&
                          answers[`answer${Number(item.answer) - 1}`].length > 0)) && (
                        <div className={css.createTopicAnswersRow} key={item.answer}>
                          <div className={css.createTopicAnswersRowInner}>
                            <label htmlFor={`answer${item.answer}`}>Answer #{item.answer}</label>
                            <input
                              id={`answer${item.answer}`}
                              name={`answer${item.answer}`}
                              type="text"
                              value={answers[`answer${item.answer}`]}
                              onChange={handleChange}
                              disabled={createTopicLoading.status}
                            />
                          </div>
                          <div
                            onClick={() =>
                              answers[`answer${item.answer}`].length > 0 &&
                              !createTopicLoading.status &&
                              handleAnswerValues(`answer${item.answer}`, '')
                            }
                            className={`${css.createTopicDeleteIcon} ${
                              answers[`answer${item.answer}`].length === 0 || createTopicLoading.status
                                ? css.createTopicDeleteIconDisabled
                                : ''
                            }
                          }`}
                          />
                        </div>
                      ),
                  )}
                </div>
              </>
            )}
            <div className={css.createTopicButtonsWrapper}>
              <button
                className={css.createTopicSubmitBtn}
                disabled={
                  createTopicLoading.status || description.length < 1 || (type === '2' && newAnswers.length < 2)
                }
                type="submit"
              >
                Create
              </button>
              <button
                className={`${css.createTopicSubmitBtn} ${css.createTopicCancelBtn}`}
                disabled={createTopicLoading.status}
                onClick={() => history.push('/')}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateTopicContainer;
