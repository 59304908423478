import * as React from 'react';
import { getStringConstants } from '../../../../../config/localization';
import css from '../emailauth.module.css';

export default function AMThankYou() {
  const strings = getStringConstants();
  return (
    <div className={css.tyContainer}>
      <div className={css.tyTitle}>{strings.TY_TITLE}</div>
      <div className={css.tyText}>{strings.TY_TEXT}</div>
      <div className={css.tyImg}></div>
    </div>
  );
}
