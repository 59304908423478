const oktaAuthConfig = {
  issuer: process.env.REACT_APP_OKTA_ISSUER as string,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID as string,
  redirectUri: window.location.origin + '/login/callback',
};

const oktaSignInConfig = {
  baseUrl: process.env.REACT_APP_OKTA_BASE_URL as string,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID as string,
  idps: [
    { type: 'APPLE', id: process.env.REACT_APP_OKTA_APPLE_IDP },
    { type: 'GOOGLE', id: process.env.REACT_APP_OKTA_GOOGLE_IDP },
  ],
  redirectUri: window.location.origin + '/login/callback',
  authParams: {
    issuer: process.env.REACT_APP_OKTA_ISSUER as string,
  },
};

export { oktaAuthConfig, oktaSignInConfig };
