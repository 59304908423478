import { put, call, delay } from 'redux-saga/effects';
// api
import { apiReports } from '../../api/reports';
// actions
import appAction from '../actions/app';
import reportsAction from '../actions/reports';
// types
import { getReportsListActionReturnType } from '../../types/actions/reports';
// helpers
import { handleLoginRedirect } from './helpers';

export function* sagaGetReports(action: getReportsListActionReturnType): any {
  console.log(action);
  try {
    yield delay(1);
    yield put(reportsAction.reportsListLoading(false, 0));
    yield put(reportsAction.reportsListLoading(true, 90));
    const responseReports = yield call(apiReports.getReports);
    yield put(reportsAction.reportsListLoading(true, 100));
    yield put(reportsAction.getReportsList(responseReports.data));
    yield delay(1000);
    yield put(reportsAction.reportsListLoading(false, 0));
  } catch (e) {
    if (e.response.status === 401) {
      handleLoginRedirect();
    } else {
      yield put(reportsAction.reportsListLoading(true, 100));
      yield put(reportsAction.reportsListLoading(false, 100));
      yield put(
        appAction.appNotification(
          true,
          {
            header: 'Error',
            message: e.response.data.message || e.response.data.errors[0] || e.response.data.errors.base[0] || 'Error',
          },
          'Error',
        ),
      );
      yield delay(1000);
      yield put(reportsAction.reportsListLoading(false, 0));
    }
  }
}
