import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
// components
import SearchField from '../SearchField/SearchField';
import ITable from '../Table/Table';
// actions
import usersAction from '../../../redux/actions/users';
// types
import { usersListType } from '../../../types/users';
import { loadingType } from '../../../types/actions/app';
// style
import css from './participantsContainer.module.css';
// configs
import { columns } from '../../../config/tables/participants/index';
interface ParticipantsContainerProps {
  users: usersListType;
  participants: Array<number>;
  mainLoading: boolean;
  loading: loadingType;
  className: string;
}

const ParticipantsContainer: FC<ParticipantsContainerProps> = ({
  users,
  participants,
  mainLoading,
  loading,
  className,
}: ParticipantsContainerProps) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const dispatch = useDispatch();

  // users filter by name and email method
  const filterUsers = (searchValue: string) => {
    return (
      users &&
      users.filter(
        (item) =>
          item.email?.toLowerCase().includes(searchValue) || item.full_name?.toLowerCase().includes(searchValue),
      )
    );
  };

  // imitation of loading with saga
  const searchCallback = (search_value?: string) => {
    dispatch(usersAction.searcUsersAction(() => setSearchValue(search_value ? search_value : '')));
  };

  const filteredUsers = filterUsers(searchValue);

  return (
    <div className={`${css.participantsContainer} ${className}`}>
      <div className={css.participantsHeader}>
        <span className={css.participantsHeading}>Participants ({filteredUsers?.length || 0})</span>
        <SearchField
          loading={loading.status || mainLoading}
          searchCallback={searchCallback}
          searchedList={users}
          placeholder="by Email, Name"
          disabled={!participants || participants.length === 0}
          id="participantSearch"
        />
      </div>
      <ITable
        columns={columns}
        data={{ total_entries: 0, skip: 0, limit: 0, items: filteredUsers }}
        loading={loading}
        type="participants"
        className="tableParticipants"
      />
    </div>
  );
};

export default ParticipantsContainer;
