import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Table from 'rc-table';
import { PaginationProps as IPaginationProps } from 'semantic-ui-react';
import { ColumnsType, DefaultRecordType } from 'rc-table/lib/interface';
// components
import LoadingBar from '../LoadingBar/LoadingBar';
import Pagination from '../Pagination/Pagination';
// actions
import appAction from '../../../redux/actions/app';
// types
import { tableDataType } from '../../../types/app';
import { loadingType } from '../../../types/actions/app';
import { filtersType } from '../../../types/app';
// style
import css from './table.module.css';
import './table.css';
// mapping
import { mapping } from '../../../config/mapping';

interface ITableProps {
  columns: ColumnsType<DefaultRecordType>;
  data: tableDataType;
  loading: loadingType;
  type: string;
  className?: string;
  action?: (data: filtersType) => void;
  filters?: filtersType;
  customSetIsLoadingFinished?: (value: boolean) => void;
}

const ITable: FC<ITableProps> = ({
  columns,
  data,
  loading,
  type,
  className,
  action,
  filters,
  customSetIsLoadingFinished,
}: ITableProps) => {
  const [loadingBarProgress, updateProgress] = useState<number>(0);
  const [isLoadingFinished, setIsLoadingFinished] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    updateProgress(0);
  }, []);

  useEffect(() => {
    loading && updateProgress(loading.progress);
    if (data.items && data.items.length === 0) {
      setIsLoadingFinished(false);
      customSetIsLoadingFinished && customSetIsLoadingFinished(false);
    }
  }, [loading]);

  const handlePaginationChange = (e: React.MouseEvent<HTMLAnchorElement>, { activePage }: IPaginationProps) => {
    actionDataToPage(activePage);
  };

  const actionDataToPage = (indexPage: string | number | undefined) => {
    const data = {
      skip: (Number(indexPage) - 1) * 12,
      limit: 12,
      search_value: filters?.search_value,
      topic_type: filters?.topic_type,
      name: filters?.name,
      sort: filters?.sort ? filters.sort : null,
      is_hidden: filters?.is_hidden,
    };

    action && dispatch(action(data));
    dispatch(appAction.queryString(data));
  };

  return (
    <div className={data.items && data.items.length > 0 && isLoadingFinished ? '' : css.tableEmpty}>
      <div className={`${className ? css[className] : css.table}`}>
        <div className={css.tableWrapper}>
          <div className={css.tableLoading}>
            <LoadingBar
              loadingBarProgress={loadingBarProgress}
              onLoaderFinished={() => {
                setIsLoadingFinished(true);
                customSetIsLoadingFinished && customSetIsLoadingFinished(true);
                updateProgress(0);
              }}
            />
          </div>
          <Table
            columns={columns}
            data={isLoadingFinished && data.items && mapping.buildTableByType(data, type)}
            emptyText=""
          />
        </div>
        {data.total_entries > 12 && isLoadingFinished && (
          <Pagination length={data.total_entries} handleChange={handlePaginationChange} skip={data.skip} />
        )}
      </div>
    </div>
  );
};

export default ITable;
