import { loadingType } from '../../types/actions/app';
import { userActivityType, userType } from '../../types/users';

const initialState = {
  activity: { banned_number: '', reviewed_number: '', deleted_messages: '' },
  user: {
    age_over16: false,
    blob_id: 0,
    created_at: '',
    custom_data: '',
    email: '',
    external_user_id: 0,
    facebook_id: 0,
    full_name: '',
    id: 0,
    last_request_at: '',
    login: '',
    parents_contacts: '',
    phone: '',
    twitter_id: 0,
    updated_at: '',
    user_tags: '',
    website: '',
    status: false,
  },
  loading: {
    status: false,
    progress: 0,
  },
};

const users = (
  state = initialState,
  action: { type: string; payload: { loading: loadingType; user: userType; activity: userActivityType } },
): { loading: loadingType; user: userType; activity: userActivityType } => {
  switch (action.type) {
    case 'USER':
      return Object.assign({}, state, {
        user: action.payload.user,
      });
    case 'USER_ACTIVITY':
      return Object.assign({}, state, {
        activity: action.payload.activity,
      });
    case 'USER_LOADING':
      return Object.assign({}, state, {
        loading: action.payload.loading,
      });

    default:
      return state;
  }
};

export default users;
