// types
import {
  appActionType,
  appLoadingReturnType,
  appNotificationClearReturnType,
  appNotificationReturnType,
  loginReturnType,
  logoutReturnType,
  prepareFileType,
  uploadFileType,
  confirmFileType,
  queryStringReturnType,
  emailLandingReturnType,
} from '../../types/actions/app';
import { filtersType } from '../../types/app';

// consts
import * as types from '../constants/actionConstants';

const appAction: appActionType = {
  login(token: string, callback: () => void, loginRedirect: () => void): loginReturnType {
    return {
      type: types.APP_LOGIN,
      token,
      callback,
      loginRedirect,
    };
  },
  emaillogin(token: string, callback: () => void): emailLandingReturnType {
    return {
      type: types.APP_EMAIL_LANDING,
      token,
      callback,
    };
  },
  logout(callback: () => void): logoutReturnType {
    return {
      type: types.APP_LOGOUT,
      callback,
    };
  },
  createToken(token: string): void {
    localStorage.setItem('token', token);
  },
  deleteToken(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('okta-token-storage');
  },
  appNotification(status: boolean, error: any, type: string): appNotificationReturnType {
    return {
      type: types.APP_NOTIFICATION,
      payload: {
        notification: {
          status: status,
          type: type,
          message: {
            header: error.header,
            body: error.message,
          },
        },
      },
    };
  },
  appNotificationClear(): appNotificationClearReturnType {
    return {
      type: types.APP_NOTIFICATION,
      payload: {
        notification: {
          status: false,
          type: {},
          message: '',
        },
      },
    };
  },
  queryString(data: filtersType): queryStringReturnType {
    return {
      type: 'APP_FILTERS',
      payload: {
        filters: data,
      },
    };
  },
  appLoading(status: boolean, progress: number): appLoadingReturnType {
    const loading = {
      status: status,
      progress: progress,
    };
    return {
      type: types.APP_LOADING,
      payload: {
        loading: { ...loading },
      },
    };
  },
  prepareFile(entries: { type: string; name: string; size: number }, callback: any): prepareFileType {
    return {
      type: types.FILE_PREPARE,
      payload: { entries, callback },
    };
  },
  uploadFile(id: any, params: any, entries: any): uploadFileType {
    return {
      type: types.FILE_UPLOAD,
      payload: { id, params, entries },
    };
  },
  confirmFile(id: any, size: any): confirmFileType {
    return {
      type: types.FILE_CONFIRM,
      payload: { id, size },
    };
  },
};

export default appAction;
