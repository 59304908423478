import { delay, put, call } from 'redux-saga/effects';
// api
import { apiUserProfile } from '../../api/userProfile';
// actions
import appAction from '../actions/app';
import userProfileAction from '../actions/userProfile';
// types
import {
  getUserActivityActionReturnType,
  getCurrentAvatarType,
  currentUserReturnType,
  updateUserType,
} from '../../types/actions/userProfile';

export function* sagaGetUserActivity(action: getUserActivityActionReturnType): any {
  console.log(action);
  try {
    yield put(appAction.appNotificationClear());
    yield delay(1);
    yield put(userProfileAction.userLoading(false, 0));
    yield put(userProfileAction.clearUserActivity());
    yield put(userProfileAction.userLoading(true, 90));
    const responseReports = yield call(apiUserProfile.getActivity);
    yield put(userProfileAction.userLoading(true, 100));
    yield put(userProfileAction.getActivity(responseReports.data));
    yield delay(1000);
    yield put(userProfileAction.userLoading(false, 0));
  } catch (e) {
    yield put(userProfileAction.userLoading(true, 100));
    yield put(userProfileAction.userLoading(false, 100));
    yield put(
      appAction.appNotification(
        true,
        {
          header: 'Error',
          message:
            e.response.data.message ||
            (e.response.data.errors && e.response.data.errors[0]) ||
            (e.response.data.errors.base && e.response.data.errors.base[0]) ||
            (e.response.data.errors.id && e.response.data.errors.id[0]) ||
            'Error',
        },
        'Error',
      ),
    );
    yield delay(1000);
    yield put(userProfileAction.userLoading(false, 0));
  }
}

export function* sagaGetCurrentAvatar(action: getCurrentAvatarType): any {
  try {
    const responceCurrentAvatar = yield call(
      apiUserProfile.getCurrentAvatar,
      action.payload.blob_id,
      action.payload.callback,
    );
    action.payload.callback(responceCurrentAvatar.data.blob.uid);
  } catch (e) {
    console.log(e);
  }
}

export function* sagaGetUser(action: currentUserReturnType): any {
  try {
    const responseUser = yield call(apiUserProfile.getCurrentUser, action.payload);
    yield put(userProfileAction.getUser(responseUser.data.user));
  } catch (e) {
    console.log(e);
  }
}

export function* sagaUpdateUser(action: updateUserType): any {
  try {
    const responseChange = yield call(apiUserProfile.updateUser, action.payload);
    yield put(userProfileAction.getUser(responseChange.data.user));
  } catch (e) {
    console.log(e);
  }
}
