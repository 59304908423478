import { loadingType } from '../../types/actions/app';
import { reportType } from '../../types/reports';

const initialState = {
  report: {
    id: '',
    category: '',
    type: '',
    reported_by: '',
    reported_at: '',
  },
  loading: {
    status: false,
    progress: 0,
  },
};

const report = (
  state = initialState,
  action: {
    type: string;
    payload: {
      report: reportType;
      loading: loadingType;
    };
  },
): {
  report: reportType;
  loading: loadingType;
} => {
  switch (action.type) {
    case 'REPORT':
      return Object.assign({}, state, {
        report: action.payload.report,
      });
    case 'REPORT_LOADING':
      return Object.assign({}, state, {
        loading: action.payload.loading,
      });
    default:
      return state;
  }
};

export default report;
