import { format, fromUnixTime } from 'date-fns';
// date formatting
export const handleDateFormat = (date: number): string => {
  return format(fromUnixTime(date), 'MMM dd, yyyy . HH:mm').replace('.', 'at');
};

// handler for topic type names
export const topicTypeHandler = (type: number): string => {
  return type === 1 ? 'Chat' : 'Survey';
};

// handling go back by url
export const handleGoBack = (pathname: string): string => {
  const pathnameArr = pathname?.split('/');
  const newPathname = pathnameArr?.slice(0, 2).join('/');
  return newPathname ? newPathname : '/';
};
