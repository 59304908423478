import { put, call, delay } from 'redux-saga/effects';
// api
import { apiTopic } from '../../api/topic';
import { apiUserProfile } from '../../api/userProfile';
import { apiSurvey } from '../../api/survey';
// actions
import appAction from '../actions/app';
import topicAction from '../actions/topic';
import usersAction from '../actions/users';
// types
import {
  getTopicActionReturnType,
  topicCreateReturnType,
  topicStatusReturnType,
  // topicEditReturnType,
} from '../../types/actions/topic';
// helpers
import { handleLoginRedirect } from './helpers';
// mapping
import { mapping } from '../../config/mapping';

export function* sagaGetTopic(action: getTopicActionReturnType): any {
  try {
    yield put(appAction.appNotificationClear());
    yield delay(1);
    yield put(topicAction.topicLoading(false, 0));
    yield put(topicAction.clearTopic());
    yield put(topicAction.topicLoading(true, 90));
    const responseTopic = yield call(apiTopic.getTopic, action.id);
    yield put(usersAction.getUsersListAction(responseTopic.data.items[0].participants));
    yield put(
      topicAction.getTopic({
        ...responseTopic.data.items[0],
        survey: { name: 'Survey name example', answers: ['Answer 1', 'Answer 2'] },
      }),
    );
    yield put(topicAction.topicLoading(true, 100));
    yield delay(1000);
    yield put(topicAction.topicLoading(false, 0));
  } catch (e: any) {
    if (e.response.status === 401) {
      handleLoginRedirect();
    } else {
      yield put(topicAction.topicLoading(true, 100));
      yield put(topicAction.topicLoading(false, 100));
      yield put(
        appAction.appNotification(
          true,
          {
            header: 'Error',
            message: e.response.data.message || e.response.data.errors[0] || e.response.data.errors.base[0] || 'Error',
          },
          'Error',
        ),
      );
      yield delay(1000);
      yield put(topicAction.topicLoading(false, 0));
    }
  }
}

// export function* sagaEditTopic(action: topicEditReturnType): any {
//   try {
//     yield put(topicAction.topicLoading(false, 0));
//     yield put(appAction.appNotificationClear());
//     yield delay(1);
//     yield put(topicAction.topicLoading(true, 0));
//     yield put(topicAction.topicLoading(true, 90));
//     const responseTopic = yield call(apiTopic.editTopic, action.data, action.id);
//     yield put(
//       topicAction.getTopic({
//         ...responseTopic.data,
//         survey: { name: 'Survey name example', answers: ['Answer 1', 'Answer 2'] },
//       }),
//     );
//     yield put(topicAction.topicLoading(true, 100));
//     yield put(appAction.appNotification(true, { header: 'Success', message: 'Topic has been edited' }, 'Success'));
//     yield delay(1000);
//     yield put(topicAction.topicLoading(false, 0));
//   } catch (e: any) {
//     if (e.response.status === 401) {
//       handleLoginRedirect();
//     } else {
//       yield put(topicAction.topicLoading(true, 100));
//       yield put(
//         appAction.appNotification(
//           true,
//           {
//             header: 'Error',
//             message: e.response.data.message || e.response.data.errors[0] || e.response.data.errors.base[0] || 'Error',
//           },
//           'Error',
//         ),
//       );
//       yield delay(1000);
//       yield put(topicAction.topicLoading(false, 0));
//     }
//   }
// }

export function* sagaCreateTopic(action: topicCreateReturnType): any {
  try {
    yield put(topicAction.topicLoading(false, 0));
    yield put(appAction.appNotificationClear());
    yield delay(1);
    yield put(topicAction.topicLoading(true, 0));
    const publicTopicResponse = yield call(apiTopic.createPublicTopic, action.data);
    yield put(topicAction.topicLoading(true, 70));
    const userProfileResponse = yield call(apiUserProfile.getCurrentUser, publicTopicResponse.data.user_id);
    const profileId = JSON.parse(userProfileResponse.data.user.custom_data).PatientId;
    const userFullName = userProfileResponse.data.user.full_name.split(/(?=[A-Z])/).join(' ');
    yield put(topicAction.topicLoading(true, 80));
    const customTopicReponse = yield call(
      apiTopic.createCustomTopic,
      mapping.buildCustomTopic(
        publicTopicResponse.data.name,
        action.data.description,
        profileId,
        action.data.type,
        publicTopicResponse.data._id,
        userProfileResponse.data.user.id,
        userFullName,
      ),
    );
    yield put(topicAction.topicLoading(true, 90));
    action.data.type === '2'
      ? yield call(
          apiSurvey.createCustomSurvey,
          mapping.buildCustomSurvey(action.data.description, action.data.answers, customTopicReponse.data._id),
        )
      : null;
    yield put(topicAction.topicLoading(true, 100));
    yield delay(1500);
    yield put(topicAction.topicLoading(false, 0));
    yield put(appAction.appNotification(true, { header: 'Success', message: 'Topic has been created' }, 'Success'));
    action.callback();
  } catch (e: any) {
    if (e.response.status === 401) {
      handleLoginRedirect();
    } else {
      yield put(topicAction.topicLoading(true, 100));
      yield put(topicAction.topicLoading(false, 100));
      yield put(appAction.appNotification(true, { header: 'Error', message: 'Topic has not been created' }, 'Error'));
      yield put(topicAction.topicLoading(false, 0));
      yield delay(1000);
      action.callback();
    }
  }
}

export function* sagaChangeTopicStatus(action: topicStatusReturnType): any {
  try {
    yield put(topicAction.statusLoading(false, 0));
    yield put(appAction.appNotificationClear());
    yield delay(1);
    yield put(topicAction.statusLoading(true, 0));
    const responseTopic = yield call(apiTopic.getTopic, action.topicId);
    const topicStatus = responseTopic.data.items[0].is_hidden;
    const redirect = topicStatus !== action.isHidden;
    if (!redirect) yield put(topicAction.getTopic(responseTopic.data.items[0]));
    yield put(topicAction.statusLoading(true, 90));
    if (redirect) yield call(apiTopic.editTopic, action.topicId, { is_hidden: action.isHidden });
    yield put(topicAction.statusLoading(true, 100));
    yield delay(1000);
    yield put(topicAction.statusLoading(false, 0));
    yield put(
      appAction.appNotification(
        true,
        {
          header: redirect ? 'Success' : 'Warning',
          message: redirect
            ? `Topic ${action.isHidden ? 'deleted' : 'restored'} successfully`
            : `Topic is already ${topicStatus === true ? 'deleted' : 'restored'}`,
        },
        redirect ? 'Success' : 'Warning',
      ),
    );
    action.callback();
  } catch (e: any) {
    if (e.response.status === 401) {
      handleLoginRedirect();
    } else {
      yield put(topicAction.statusLoading(true, 100));
      yield put(topicAction.statusLoading(false, 100));
      yield put(
        appAction.appNotification(
          true,
          {
            header: 'Error',
            message: e.response.data.message || e.response.data.errors[0] || e.response.data.errors.base[0] || 'Error',
          },
          'Error',
        ),
      );
      e.response.data.errors[0] === "The resource wasn't found" && action.callback();
    }
  }
}
