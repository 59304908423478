import React, { useState, useEffect, FC } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import OktaSignInWidget from './OktaWidget';
import { useOktaAuth } from '@okta/okta-react';
// components
import Header from '../../containers/Header/Header';
import Notification from '../../containers/Notification/Notification';
//actions
import appAction from '../../../redux/actions/app';
// types
import { OktaSignInConfigType } from '../../../types/app';
// style
import css from './auth.module.css';
import authTransition from '../../transitions/authTransition.module.css';

interface AuthPageProps {
  config: OktaSignInConfigType;
}

const AuthPage: FC<AuthPageProps> = ({ config }: AuthPageProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const { oktaAuth, authState } = useOktaAuth();

  const onSuccess = (tokens: { accessToken: { accessToken: string } }) => {
    const token = tokens.accessToken.accessToken;
    const callback = () => oktaAuth.handleLoginRedirect(tokens);
    const logirRedirect = () => history.push('/login');

    dispatch(appAction.login(token, callback, logirRedirect));
  };

  const onError = (err: any) => {
    dispatch(
      appAction.appNotification(
        true,
        {
          header: 'Error',
          message: err,
        },
        'Error',
      ),
    );
  };

  return (
    <div className={css.authWrapper}>
      <Header />
      <Notification />
      <section className={css.authSection}>
        <CSSTransition
          classNames={authTransition}
          onExit={() => setIsLoaded(false)}
          in={isLoaded}
          unmountOnExit
          timeout={1000}
        >
          {authState?.isAuthenticated ? (
            <Redirect to={{ pathname: '/' }} />
          ) : (
            <OktaSignInWidget config={config} onSuccess={onSuccess} onError={onError} />
          )}
        </CSSTransition>
      </section>
    </div>
  );
};

export default AuthPage;
