// types
import {
  userProfileActionType,
  getUserActivityActionReturnType,
  getActivityReturnType,
  clearUserActivityReturnType,
  userLoadingReturnType,
  currentUserReturnType,
  updateUserType,
  userReturnType,
} from '../../types/actions/userProfile';
import { userActivityType } from '../../types/users';
// consts
import * as types from '../constants/actionConstants';

const userProfileAction: userProfileActionType = {
  getUserActivityAction(id: string): getUserActivityActionReturnType {
    return {
      type: types.GET_USER_ACTIVITY,
      id,
    };
  },
  getActivity(activity: userActivityType): getActivityReturnType {
    return {
      type: types.USER_ACTIVITY,
      payload: { activity },
    };
  },
  clearUserActivity(): clearUserActivityReturnType {
    return {
      type: types.USER_ACTIVITY,
      payload: {
        age_over16: false,
        blob_id: 0,
        created_at: '',
        custom_data: '',
        email: '',
        external_user_id: 0,
        facebook_id: 0,
        full_name: '',
        id: 0,
        last_request_at: '',
        login: '',
        parents_contacts: '',
        phone: '',
        twitter_id: 0,
        updated_at: '',
        user_tags: '',
        website: '',
      },
    };
  },
  userLoading(status: boolean, progress: number): userLoadingReturnType {
    const loading = {
      status: status,
      progress: progress,
    };
    return {
      type: types.USER_LOADING,
      payload: {
        loading: { ...loading },
      },
    };
  },
  getCurrentAvatar(blob_id: any, callback: any): any {
    return {
      type: types.GET_CURRENT_AVATAR,
      payload: { blob_id, callback },
    };
  },
  getCurrentUser(login: string): currentUserReturnType {
    return {
      type: types.GET_USER,
      payload: login,
    };
  },
  getUser(user: string): userReturnType {
    return {
      type: 'USER',
      payload: user,
    };
  },
  updateUser(userData: any): updateUserType {
    return {
      type: types.UPDATE_USER,
      payload: userData,
    };
  },
};

export default userProfileAction;
