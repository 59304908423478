// types
import {
  clearModeratedMessageReturnType,
  getModeratedMessageActionReturnType,
  getModeratedMessageReturnType,
  moderatedMessageActionType,
  moderatedMessageLoadingReturnType,
} from '../../types/actions/moderatedMessage';
import { moderatedMessageType } from '../../types/moderatedMessages';
// consts
import * as types from '../constants/actionConstants';

const moderatedMessageAction: moderatedMessageActionType = {
  getModeratedMessageAction(id: string): getModeratedMessageActionReturnType {
    return {
      type: types.GET_MODERATED_MESSAGE,
      id,
    };
  },
  getModeratedMessage(moderatedMessage: moderatedMessageType): getModeratedMessageReturnType {
    return {
      type: types.MODERATED_MESSAGE,
      payload: { moderatedMessage },
    };
  },
  clearModeratedMessage(): clearModeratedMessageReturnType {
    return {
      type: types.MODERATED_MESSAGE,
      payload: {
        moderatedMessage: {
          message: '',
          sender_id: '',
          recipient_id: '',
          attachments: '',
          custom_parameters: '',
          date_sent: '',
        },
      },
    };
  },
  moderatedMessageLoading(status: boolean, progress: number): moderatedMessageLoadingReturnType {
    const loading = {
      status: status,
      progress: progress,
    };
    return {
      type: types.MODERATED_MESSAGE_LOADING,
      payload: {
        loading: { ...loading },
      },
    };
  },
};

export default moderatedMessageAction;
