type StringName =
  | 'L_DEFAULT'
  | 'LOADING'
  | 'AL_YOU_ARE_CONNECTED'
  | 'AL_OPEN_APP'
  | 'BROUGHT_TO_YOU_BY'
  | 'AL_QUESTIONS'
  | 'CONTINUE_WITH_APPLE'
  | 'CONTINUE_WITH_GOOGLE'
  | 'EMAIL_SPACE'
  | 'FORGOT_PASSWORD'
  | 'REQUIRED'
  | 'RESET_PASSWORD_DESC'
  | 'DISMISS'
  | 'SIGN_UP_HERE'
  | 'SIGNIN_ANY_QUESTIONS'
  | 'DONT_HAVE_AN_ACCOUT'
  | 'BL_OOPS'
  | 'BL_YOU_CLICKED'
  | 'BL_PLEASE_CONTACT'
  | 'BL_HAVE_QUESTIONS'
  | 'TY_TITLE'
  | 'TY_TEXT'
  | 'CONF_ERROR_PHONE'
  | 'CONF_ERROR_DOB'
  | 'CONF_GENERIC_ERROR_A'
  | 'CONF_GENERIC_ERROR_B'
  | 'CONF_YES_I_WANT_TO_CONNECT'
  | 'CONF_WE_RETIEVED'
  | 'CONF_PLEASE_CONFIRM'
  | 'CONF_PLEASE_ENTER_DOB'
  | 'CONF_PLEASE_ENTER_PHONE'
  | 'DATE_OF_BIRTH'
  | 'PHONE_NUMBER'
  | 'EDITING_WILL_NOT_UPDATE_ALLOCARE'
  | 'CONFIRM_AND_SUBMIT_INFO'
  | 'SUBMIT'
  | 'LOGIN'
  | 'CONFIRM_YOUR_PHONE'
  | 'SMS_AUTHENTICATION'
  | 'FIRST_NAME'
  | 'LAST_NAME'
  | 'EMAIL_ADDRESS'
  | 'CONF_DOB_INPUT'
  | 'PHONE_NUMBER'
  | 'PASSWORD'
  | 'CREATE_AN_ACCOUNT'
  | 'ALREADY_REGISTERED'
  | 'SIGN_UP_FOR_ALLOCARE'
  | 'SU_WE_RETRIEVED'
  | 'SU_BY_SIGNING'
  | 'SU_PW_NEED_BLANK_MORE_CHARS'
  | 'SU_PW_NEED_BLANK_LESS_CHARS'
  | 'SU_PW_NEED_LOWERCASE'
  | 'SU_PW_NEED_UPPERCASE'
  | 'SU_PW_NEED_NUMERIC'
  | 'SU_PW_NEED_SPECIAL'
  | 'SU_PW_CONTAINS_NAME'
  | 'PASSWORD_REQUIREMENTS_NOT_MET'
  | 'TERMS_OF_USE'
  | 'PRIVACY_POLICY'
  | 'ADDITIONAL_CONSENTS'
  | 'TERMS_OF_USE_AGREEMENT'
  | 'SU_2F_HI'
  | 'SU_2F_WE_WILL_TEXT_YOU'
  | 'SU_2F_ENTER_CODE'
  | 'SU_2F_STD_MSG_DATA'
  | 'SU_2F_SEND_CODE'
  | 'SU_2F_SENT_CODE'
  | 'SU_2F_RESEND_CODE'
  | 'SU_2F_VERIFY'
  | 'SU_PLEASE_WAIT'
  | 'SU_TY_YOU_HAVE_CONNECTED'
  | 'SU_TY_IF_IPHONE'
  | 'SU_TY_IF_ANDROID'
  | 'SU_TY_TO_INSTALL_SCAN'
  | 'AND'
  | 'SU_2F_INVALID_CODE';

export interface TextValues {
  name: StringName;
  en: string;
  es: string;
}

const DATA: Array<TextValues> = [
  { name: 'L_DEFAULT', en: 'en', es: 'es' },
  { name: 'LOADING', en: 'Loading', es: 'Cargando' },
  { name: 'BROUGHT_TO_YOU_BY', en: 'Brought to you by', es: 'Traído a usted por' },
  {
    name: 'AL_YOU_ARE_CONNECTED',
    en: 'You are connected to MAP Pro and have access to health information for patient:',
    es: 'Está conectado a MAP Pro y tiene acceso a la información de salud del paciente:',
  },
  {
    name: 'AL_OPEN_APP',
    en: 'Please open the AlloCare app on your mobile device to view and accept the medication list from MAP Pro.',
    es: 'Abra la aplicación AlloCare en su dispositivo móvil para ver y aceptar la lista de medicamentos de MAP Pro.',
  },
  {
    name: 'AL_QUESTIONS',
    en: `If you have any questions about viewing the medication list from MAP or other information in the AlloCare app,
please contact our Customer Support​ team at 1-888-255-6227.`,
    es: `Si tiene alguna pregunta sobre cómo ver la lista de medicamentos de MAP u otra información en la aplicación AlloCare, comuníquese con nuestro equipo de atención al cliente al 1-888-255-6227.`,
  },
  {
    name: 'REQUIRED',
    en: 'Required',
    es: 'Requerida',
  },
  {
    name: 'CONTINUE_WITH_APPLE',
    en: 'Continue with Apple',
    es: 'Continuar con Apple',
  },
  { name: 'CONTINUE_WITH_GOOGLE', en: 'Continue with Google', es: 'Continuar con Google' },
  { name: 'EMAIL_SPACE', en: 'Email&nbsp;', es: 'Correo electrónico&nbsp;' },
  { name: 'FORGOT_PASSWORD', en: 'Forgot Password?', es: '¿Has olvidado tu contraseña?' },
  {
    name: 'RESET_PASSWORD_DESC',
    en: `To Reset your password, go to the login page on the AlloCare app on your mobile device.`,
    es: `Para restablecer su contraseña, vaya a la página de inicio de sesión en la aplicación AlloCare en su dispositivo móvil.`,
  },
  {
    name: 'DISMISS',
    en: `Dismiss`,
    es: `Despedir`,
  },
  {
    name: 'SIGN_UP_HERE',
    en: 'Sign up here',
    es: 'Registrate aquí',
  },
  {
    name: 'SIGNIN_ANY_QUESTIONS',
    en: `If you have any questions about using the AlloCare app, please contact our Customer Support team at
    1-888-255-6227.`,
    es: `Si tiene alguna pregunta sobre el uso de la aplicación AlloCare, comuníquese con nuestro equipo de atención al cliente al 1-888-255-6227.`,
  },
  {
    name: 'DONT_HAVE_AN_ACCOUT',
    en: `Don't have an account?`,
    es: `¿No tienes una cuenta?`,
  },
  {
    name: 'BL_OOPS',
    en: 'OOPS.....',
    es: 'UPS.....',
  },
  {
    name: 'BL_YOU_CLICKED',
    en: `You clicked on an expired link. Please check for newer emails. Be sure to click the button in the email or
    copy the full link and paste it in your browser’s address bar.`,
    es: `Hiciste clic en un enlace caducado. Por favor, busque nuevos correos electrónicos. Asegúrese de hacer clic en el botón del correo electrónico o\n copie el enlace completo y péguelo en la barra de direcciones de su navegador.`,
  },
  {
    name: 'BL_PLEASE_CONTACT',
    en: `Please contact your healthcare provider if you need further assistance.`,
    es: `Comuníquese con su proveedor de atención médica si necesita más ayuda.`,
  },
  {
    name: 'BL_HAVE_QUESTIONS',
    en: `Have questions about the app? AlloCare Support at 1-888-255-6227.`,
    es: `¿Tiene preguntas sobre la aplicación? Soporte de AlloCare al 1-888-255-6227.`,
  },
  {
    name: 'TY_TITLE',
    en: `Thank you`,
    es: 'Gracias',
  },
  {
    name: 'TY_TEXT',
    en: `You are now connected and have access to your medication schedule from MAP Pro within AlloCare. Please open the
    AlloCare app on your mobile device to accept the new medication list. It may take up to 10 minutes for your
    medication list to appear in AlloCare.`,
    es: `Ahora está conectado y tiene acceso a su programa de medicamentos de MAP Pro dentro de AlloCare. Abra la aplicación AlloCare en su dispositivo móvil para aceptar la nueva lista de medicamentos. Su lista de medicamentos puede tardar hasta 10 minutos en aparecer en AlloCare.`,
  },
  {
    name: 'CONF_ERROR_PHONE',
    en: 'Phone number entered does not match the patient record in MAP.',
    es: 'El número de teléfono ingresado no coincide con el registro del paciente de MAP.',
  },
  {
    name: 'CONF_ERROR_DOB',
    en: 'Date of Birth entered does not match the patient record in MAP.',
    es: 'La fecha de nacimiento ingresada no coincide con el registro del paciente de MAP.',
  },
  {
    name: 'CONF_GENERIC_ERROR_A',
    en: 'Please try again or contact your provider for assistance.',
    es: 'Vuelva a intentarlo o póngase en contacto con su proveedor para obtener ayuda.',
  },
  {
    name: 'CONF_GENERIC_ERROR_B',
    en: 'One or more answer did not match the patient record in MAP. Please contact your provider or the AlloCare support team at 1-888-255-6227 for assistance.',
    es: 'Una o más respuestas no coincidieron con el registro del paciente en MAP. Comuníquese con su proveedor o con el equipo de soporte de AlloCare al 1-888-255-6227 para obtener ayuda.',
  },
  {
    name: 'CONF_YES_I_WANT_TO_CONNECT',
    en: 'Yes, I want AlloCare to connect with my healthcare provider.',
    es: 'Sí, quiero que AlloCare se conecte con mi proveedor de atención médica.',
  },
  {
    name: 'CONF_WE_RETIEVED',
    en: 'We retrieved this information from your AlloCare account.',
    es: 'Recuperamos esta información de su cuenta de AlloCare.',
  },
  {
    name: 'CONF_PLEASE_CONFIRM',
    en: 'Please confirm the following patient information.',
    es: 'Confirme la siguiente información del paciente.',
  },
  {
    name: 'CONF_PLEASE_ENTER_PHONE',
    en: 'Please enter phone number',
    es: 'Por favor ingrese el número de teléfono',
  },
  {
    name: 'CONF_PLEASE_ENTER_DOB',
    en: 'Select date',
    es: 'Seleccione fecha',
  },
  {
    name: 'DATE_OF_BIRTH',
    en: 'Date of Birth',
    es: 'Fecha de nacimiento',
  },
  {
    name: 'PHONE_NUMBER',
    en: 'Phone Number',
    es: 'Número de teléfono',
  },
  {
    name: 'EDITING_WILL_NOT_UPDATE_ALLOCARE',
    en: 'Editing these fields here will not update the information on your AlloCare account.',
    es: 'Editar estos campos aquí no actualizará la información en su cuenta de AlloCare.',
  },
  {
    name: 'CONFIRM_AND_SUBMIT_INFO',
    en: 'Confirm and Submit Info',
    es: 'Confirmar y enviar información',
  },
  {
    name: 'SUBMIT',
    en: 'Submit',
    es: 'Entregar',
  },
  {
    name: 'LOGIN',
    en: 'Login',
    es: 'Acceso',
  },
  {
    name: 'CONFIRM_YOUR_PHONE',
    en: 'Confirm your phone number',
    es: 'Confirme su teléfono',
  },
  {
    name: 'SMS_AUTHENTICATION',
    en: `SMS Authentication`,
    es: `Autenticación SMS`,
  },
  { name: 'FIRST_NAME', en: 'First name', es: 'Primer nombre' },
  { name: 'LAST_NAME', en: 'Last name', es: 'Apellido' },
  { name: 'EMAIL_ADDRESS', en: 'Email', es: 'Correo electrónico' },
  { name: 'CONF_DOB_INPUT', en: 'Date of birth', es: 'Fecha de nacimiento' },
  { name: 'PHONE_NUMBER', en: 'Phone number', es: 'Número de teléfono' },
  { name: 'PASSWORD', en: 'Password', es: 'Contraseña' },
  { name: 'CREATE_AN_ACCOUNT', en: 'Create an account', es: 'Crear una cuenta' },
  { name: 'ALREADY_REGISTERED', en: 'Already registered?', es: '¿Ya registrado?' },
  { name: 'SIGN_UP_FOR_ALLOCARE', en: 'Signup for AlloCare', es: 'Registrarte en AlloCare' },
  {
    name: 'SU_WE_RETRIEVED',
    en: 'We retrieved some of your information as entered by your provider in MAP Pro',
    es: 'Recuperamos parte de su información tal como la ingresó su proveedor en MAP Pro',
  },
  {
    name: 'SU_BY_SIGNING',
    en: 'By signing up for AlloCare. you are agreeing to our ',
    es: 'Al registrarse en AlloCare, acepta nuestros ',
  },
  {
    name: 'TERMS_OF_USE',
    en: 'Terms of Use',
    es: 'Terminos de uso',
  },
  {
    name: 'TERMS_OF_USE_AGREEMENT',
    en: 'Terms of use agreement',
    es: 'Acuerdo de condiciones de uso',
  },
  {
    name: 'PRIVACY_POLICY',
    en: 'Privacy Policy',
    es: 'Política de privacidad',
  },
  {
    name: 'ADDITIONAL_CONSENTS',
    en: 'and additional consents.',
    es: 'y consentimientos adicionales.',
  },
  {
    name: 'PASSWORD_REQUIREMENTS_NOT_MET',
    en: 'Password Requirements not met:',
    es: 'Requisitos de contraseña no cumplidos:',
  },
  { name: 'SU_PW_NEED_LOWERCASE', en: 'Need at least 1 lowercase letter', es: 'Necesita al menos 1 letra minúscula' },
  { name: 'SU_PW_NEED_UPPERCASE', en: 'Need at least 1 uppercase letter', es: 'Necesita al menos 1 letra mayúscula' },
  { name: 'SU_PW_NEED_NUMERIC', en: 'Need at least 1 numeric character', es: 'Necesita al menos 1 carácter numérico' },
  {
    name: 'SU_PW_NEED_SPECIAL',
    en: 'Need at least 1 special character (-,$,&,@,?,!,#,%,^,*)',
    es: 'Necesita al menos 1 carácter especial (-,$,&,@,?,!,#,%,^,*)',
  },
  { name: 'SU_PW_NEED_BLANK_MORE_CHARS', en: 'Need ${0} more character(s)', es: 'Necesita ${0} caracteres más' },
  {
    name: 'SU_PW_NEED_BLANK_LESS_CHARS',
    en: 'Exceeded ${0} character(s), please remove ${0} character(s)',
    es: 'Se excedieron ${0} caracteres, elimine ${0} caracteres',
  },
  {
    name: 'SU_PW_CONTAINS_NAME',
    en: ' Contains parts of name (first and/or last name)',
    es: 'Contiene partes del nombre (nombre y/o apellido)',
  },
  {
    name: 'SU_2F_HI',
    en: 'Hi',
    es: 'Hola',
  },
  {
    name: 'SU_2F_WE_WILL_TEXT_YOU',
    en: 'We will text you a one-time code to confirm your number.',
    es: 'Le enviaremos un código de un solo uso para confirmar su número.',
  },
  {
    name: 'SU_2F_ENTER_CODE',
    en: 'Enter Code',
    es: 'Introduzca el código',
  },
  {
    name: 'SU_2F_STD_MSG_DATA',
    en: 'Standard Message and data rates may apply',
    es: 'Se pueden aplicar tarifas estándar de mensajes y datos',
  },
  {
    name: 'SU_2F_VERIFY',
    en: 'Verify',
    es: 'Verificar',
  },
  {
    name: 'SU_2F_SEND_CODE',
    en: 'Send code',
    es: 'Enviar código',
  },
  {
    name: 'SU_2F_SENT_CODE',
    en: 'Sent code',
    es: 'Código enviado',
  },
  {
    name: 'SU_2F_RESEND_CODE',
    en: 'Re-Send code',
    es: 'Reenviar codigo',
  },
  {
    name: 'SU_2F_INVALID_CODE',
    en: 'Invalid code',
    es: 'SPANISH INVALID CODE',
  },
  {
    name: 'SU_PLEASE_WAIT',
    en: 'Please wait while we create your account',
    es: 'Por favor espere mientras creamos su cuenta',
  },
  {
    name: 'SU_TY_YOU_HAVE_CONNECTED',
    en: 'You have connected AlloCare and your healthcare provider. To start using AlloCare, please download the app.',
    es: 'Ha conectado AlloCare y su proveedor de atención médica. Para comenzar a usar AlloCare, descargue la aplicación.',
  },
  {
    name: 'SU_TY_IF_IPHONE',
    en: 'If you use an iPhone, get AlloCare on the App Store:',
    es: 'Si usa un iPhone, obtenga AlloCare en la App Store:',
  },
  {
    name: 'SU_TY_IF_ANDROID',
    en: 'If you use an Android device, get AlloCare on Google Play',
    es: 'Si usa un dispositivo Android, obtenga AlloCare en Google Play',
  },
  {
    name: 'SU_TY_TO_INSTALL_SCAN',
    en: 'To install AlloCare on another iPhone or Android device, scan the QR code:',
    es: 'Para instalar AlloCare en otro dispositivo iPhone o Android, escanee el código QR:',
  },
  {
    name: 'AND',
    en: 'and',
    es: 'y',
  },
];

export function getLanguage() {
  if (navigator.language) {
    const splitNavigatorLang = navigator.language.split('-');
    if (splitNavigatorLang.length > 0) {
      return splitNavigatorLang[0] as 'en' | 'es';
    }
  }
  return 'en';
}

export function getStringConstants(lang?: 'en' | 'es'): { [key in StringName]: string } {
  if (!lang) {
    lang = getLanguage();
  }
  const ret: any = {
    L_DEFAULT: '',
  };
  for (const data of DATA) {
    ret[data.name] = data[lang || 'en'];
  }
  return ret;
}
