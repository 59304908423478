import React, { FC } from 'react';
// components
import Header from '../../containers/Header/Header';
import CreateTopicContainer from '../../containers/CreateTopicContainer/CreateTopicContainer';
// types
import { paramsType } from '../../../types/app';

interface CreateTopicPageProps {
  match: paramsType;
}

const CreateTopicPage: FC<CreateTopicPageProps> = ({ match }: CreateTopicPageProps) => (
  <>
    <Header logout />
    <CreateTopicContainer match={match} />
  </>
);

export default CreateTopicPage;
