// types
import {
  topicsActionType,
  getTopicsListReturnType,
  getTopicsListActionReturnType,
  clearTopicsListReturnType,
  topicsListLoadingReturnType,
} from '../../types/actions/topics';
import { filtersType } from '../../types/app';
import { topicsListResponseType } from '../../types/topics';
// consts
import * as types from '../constants/actionConstants';

const topicsAction: topicsActionType = {
  getTopicsListAction(data: filtersType): getTopicsListActionReturnType {
    return {
      type: types.GET_TOPICS_LIST,
      data,
    };
  },
  getTopicsList(topics: topicsListResponseType): getTopicsListReturnType {
    return {
      type: types.TOPICS_LIST,
      payload: { topics },
    };
  },
  clearTopicsList(): clearTopicsListReturnType {
    return {
      type: types.TOPICS_LIST,
      payload: { topics: { items: [], total_entries: 0, skip: 0, limit: 12 } },
    };
  },
  topicsListLoading(status: boolean, progress: number): topicsListLoadingReturnType {
    const loading = {
      status: status,
      progress: progress,
    };
    return {
      type: types.TOPICS_LIST_LOADING,
      payload: {
        loading: { ...loading },
      },
    };
  },
};

export default topicsAction;
