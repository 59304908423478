import React, { useEffect, FC, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OutsideClickHandler from 'react-outside-click-handler';
// components
import SearchField from '../SearchField/SearchField';
import LoadingBar from '../LoadingBar/LoadingBar';
// import SendMessage from '../SendMessage/SendMessage';
import Message from './Message/Message';
// actions
import messagesAction from '../../../redux/actions/messages';
// types
import { messagesFiltersType, messagesListResponseType } from '../../../types/messages';
import { loadingType } from '../../../types/actions/app';
import { usersListType } from '../../../types/users';
// style
import css from './messagesContainer.module.css';

interface MessagesContainerProps {
  topicId: string;
  customTopicId: string;
  mainLoading: boolean;
  messagesLoading: loadingType;
  className: string;
}

const MessagesContainer: FC<MessagesContainerProps> = ({
  topicId,
  customTopicId,
  mainLoading,
  messagesLoading,
  className,
}: MessagesContainerProps) => {
  const [loadingBarProgress, updateProgress] = useState<number>(0);
  const [searchInput, setSearchInput] = useState<string>('');
  const [userSearchInput, setUserSearchInput] = useState<string>('');
  const [isLoadingFinished, setIsLoadingFinished] = useState<boolean>(false);
  // const [isReported, setIsReported] = useState<boolean>(false);
  const [participant, setParticipant] = useState<string>('');
  const [isSuggestionVisible, setIsSuggestionVisible] = useState<boolean>(false);
  const [lastChangedInputId, setLastChangedInputId] = useState<string>('');

  // store
  const messages = useSelector(
    (state: { messages: { messages: messagesListResponseType } }) => state.messages.messages,
  );
  const participants = useSelector((state: { users: { list: usersListType } }) => state.users.list);

  const dispatch = useDispatch();
  const messagesEndRef = useRef<any>(null);

  const data: messagesFiltersType = {
    dialog_id: topicId,
    search_value: searchInput,
    reported: false /*isReported*/,
    sender_id: participant,
    skip: messages?.skip || 0,
    limit: messages?.limit || 100,
    total_entries: messages?.total_entries,
  };

  // participants list filtered by searchValue
  const filteredParticipants = participants?.filter((item) => item.full_name.toLowerCase().includes(userSearchInput));

  useEffect(() => {
    dispatch(messagesAction.clearMessagesList());
    handleMessagesList(data);

    return () => {
      dispatch(messagesAction.clearMessagesList());
    };
  }, []);

  useEffect(() => {
    updateProgress(messagesLoading.progress);
  }, [messagesLoading]);

  // get messages list without sender_id if search in messages by user is empty and sender_id is set
  useEffect(() => {
    if (userSearchInput.length === 0 && participant) {
      setParticipant('');
      handleMessagesList({ ...data, skip: 0, sender_id: '' });
    }
  }, [userSearchInput]);

  // set last changed input id to focus or not on certain input after loading
  useEffect(() => {
    setLastChangedInputId('messagesParticipant');
  }, [searchInput]);

  useEffect(() => {
    setLastChangedInputId('messagesParticipantSearch');
  }, [userSearchInput]);

  // api request for messages list
  const handleMessagesList = (data: messagesFiltersType) => {
    dispatch(messagesAction.getMessagesListAction(data));
  };

  // search in messages handler
  const handleSearch = (search_value?: string) => {
    dispatch(messagesAction.clearMessagesList());

    handleMessagesList({ ...data, skip: 0, search_value: search_value ? search_value : '' });
    scrollToBottom();
  };

  // certain participant messages handler
  const handleParticipant = (id: string) => {
    dispatch(messagesAction.clearMessagesList());

    setParticipant(id);

    handleMessagesList({ ...data, skip: 0, sender_id: id });
    scrollToBottom();
  };

  // refresh messages handler
  const handleRefreshMessages = () => {
    setLastChangedInputId('');
    dispatch(messagesAction.clearMessagesList());
    handleMessagesList({ ...data, skip: 0 });
  };

  // callback for deleting message
  const deleteMessageCallback = (message_id: string) => {
    const messageData = { message_id, messages: messages.items };
    dispatch(
      messagesAction.deleteMessageAction(
        customTopicId,
        messageData,
        { ...data, total_entries: messages.total_entries - 1 },
        handleMessagesList,
      ),
    );
  };

  // scroll to bottom method
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ block: 'nearest' });
  };

  // messages container top scroll handler
  const handleTopScroll = (e: React.UIEvent<HTMLElement>) => {
    if (
      !messagesLoading.status &&
      e.currentTarget.scrollHeight + e.currentTarget.scrollTop === e.currentTarget.clientHeight &&
      messages?.total_entries > messages.items.length
    ) {
      handleMessagesList({ ...data, skip: messages.skip + 100, limit: 100, messages: messages.items });
    }
  };

  const onOutsideClick = () => {
    setLastChangedInputId('');
    setIsSuggestionVisible(false);
  };

  // reported messages handler
  // const handleReportedMessages = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   e.target.value === 'Reported' ? setIsReported(true) : setIsReported(false);
  //   data.reported = e.target.value === 'Reported' ? true : false;

  //   dispatch(messagesAction.getMessagesListAction(data));
  // };

  return (
    <div className={`${className} ${css.messagesContainer}`}>
      <div className={css.messagesHeader}>
        <span className={css.messagesHeading}>Comments ({messages?.items?.length || 0})</span>
        <div className={css.messagesForm}>
          <div className={css.messagesFormItem}>
            <SearchField
              loading={
                messagesLoading.status || mainLoading || (messages?.items?.length === 0 && searchInput.length === 0)
              }
              searchCallback={handleSearch}
              searchedList={messages?.items}
              placeholder="Comment text"
              customInput={searchInput}
              customSetInput={setSearchInput}
              id="messagesParticipant"
              lastChangedInputId={lastChangedInputId}
            />
          </div>
          <div className={css.usersSearchWrapper}>
            <OutsideClickHandler onOutsideClick={onOutsideClick}>
              <SearchField
                loading={
                  messagesLoading.status ||
                  mainLoading ||
                  (messages?.items?.length === 0 && userSearchInput.length === 0)
                }
                searchedList={messages?.items}
                placeholder="Username"
                customInput={userSearchInput}
                customSetInput={setUserSearchInput}
                onFocus={() => setIsSuggestionVisible(true)}
                customLabel="Participant"
                id="messagesParticipantSearch"
                lastChangedInputId={lastChangedInputId}
              />
              {userSearchInput.length > 0 && isSuggestionVisible && !messagesLoading.status && !mainLoading && (
                <div className={css.usersSuggestions}>
                  {participants && filteredParticipants.length ? (
                    filteredParticipants.map((item) => (
                      <span
                        className={css.usersSuggestionsItem}
                        key={item.id}
                        onClick={() => handleParticipant(String(item.id))}
                      >
                        {item.full_name}
                      </span>
                    ))
                  ) : (
                    <div className={css.usersSuggestionsEmpty}>
                      <span>Nothing found</span>
                    </div>
                  )}
                </div>
              )}
            </OutsideClickHandler>
          </div>
          {/* </div> */}
          {/* <div className={css.messagesFormItem}>
              <label>Comments: </label>
              <select onChange={handleReportedMessages} disabled={messagesLoading.status || mainLoading}>
                <option>All</option>
                <option>Reported</option>
              </select>
            </div> */}
          {/* <div className={css.messagesFormItem}>
            <label>Participant: </label>
            <select
              defaultValue={participantMessages}
              onChange={handleParticipantMessages}
              disabled={messagesLoading.status || mainLoading}
            >
              <option value="">All</option>
              {participants &&
                participants.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.full_name}
                  </option>
                ))}
            </select>
          </div> */}
        </div>
      </div>
      <div className={css.messagesLoading}>
        <LoadingBar
          loadingBarProgress={loadingBarProgress}
          onLoaderFinished={() => {
            updateProgress(0);
            setIsLoadingFinished(true);
          }}
        />
      </div>
      <div className={css.messagesContent} onScroll={handleTopScroll}>
        <div ref={messagesEndRef} />
        {messages?.items &&
          messages.items.map((message) => (
            <React.Fragment key={message._id}>
              <Message
                message={message}
                loading={messagesLoading}
                mainLoading={mainLoading}
                deleteMessageCallback={deleteMessageCallback}
              />
            </React.Fragment>
          ))}
      </div>
      <button
        className={`${messagesLoading.status || mainLoading ? css.messagesRefreshBtnDisabled : css.messagesRefreshBtn}`}
        onClick={handleRefreshMessages}
        disabled={!isLoadingFinished || messagesLoading.status || mainLoading}
      >
        <div className={css.messagesRefreshIcon} />
        Refresh comments
      </button>
    </div>
  );
};

export default MessagesContainer;
