import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_URL + '/';
// types
import { apiUserProfileType } from '../types/api/userProfile';
import { userType } from '../types/users';

export const apiUserProfile: apiUserProfileType = {
  getActivity: async (): Promise<{ data: any }> => {
    return await axios({
      url: `${baseUrl}users.json`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
    });
  },
  getCurrentUser: async (id: string): Promise<{ data: { user: userType } }> => {
    return await axios({
      url: `${baseUrl}users/${id}.json`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
    });
  },
  updateUser: async (userData: any): Promise<{ data: any }> => {
    // changing profile name
    return await axios({
      url: `${baseUrl}users/${userData.user.id}.json`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
      data: userData,
    });
  },
  getCurrentAvatar: async (blob_id: any): Promise<{ data: any }> => {
    return await axios({
      url: `${baseUrl}blobs/${blob_id}.json`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
    });
  },
};
