import * as React from 'react';
import { getStringConstants } from '../../../../config/localization';
import css from './emailauth.module.css';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input/input';

const strings = getStringConstants();

function isNumeric(n: string) {
  return !isNaN(parseFloat(n));
}

function getNumbersStringFromPhoneString(phoneString: string): string {
  let phoneNumbers = '';
  for (let i = 0; i < phoneString.length; i++) {
    if (isNumeric(phoneString[i])) {
      phoneNumbers += phoneString[i];
    }
  }
  return phoneNumbers;
}

function comparePhones(phone1: string, phone2: string) {
  if (phone1.length < 9 || phone2.length < 9) {
    return false;
  }
  const phone1Numbers = getNumbersStringFromPhoneString(phone1);
  const phone2Numbers = getNumbersStringFromPhoneString(phone2);
  if (phone1Numbers.includes(phone2Numbers) || phone2Numbers.includes(phone1Numbers)) {
    return true;
  } else {
    return false;
  }
}

interface ConfirmationProps {
  isSignup: boolean;
  allocarePhone: string;
  allocareDob: string;
  mapPhone: string;
  mapDob: string;
  onConfirmed: (matchesAllocare: boolean) => void;
}

export default function Confirmation(props: ConfirmationProps) {
  const [phone, setPhone] = React.useState(getNumbersStringFromPhoneString(props.allocarePhone));
  const [phoneErrorS, setPhoneErrorS] = React.useState('');
  const [dob, setDob] = React.useState(props.allocareDob);
  const [dobErrorS, setDobErrorS] = React.useState('');
  const [submitAttempts, setSubmitAttempts] = React.useState(0);

  function validate(ev: any) {
    ev.preventDefault();
    const phonesMatch = comparePhones(phone, props.mapPhone);
    if (phonesMatch) {
      setPhoneErrorS('');
    } else {
      setPhoneErrorS(strings.CONF_ERROR_PHONE);
    }
    const dobsMatch = dob == props.mapDob;
    if (dobsMatch) {
      setDobErrorS('');
    } else {
      setDobErrorS(strings.CONF_ERROR_DOB);
    }
    if (dobsMatch && phonesMatch) {
      const doAllocareMapDobsMatch = props.allocareDob == props.mapDob;
      const doAllocareMapPhonesMatch = comparePhones(props.allocarePhone, props.mapPhone);
      props.onConfirmed(doAllocareMapDobsMatch && doAllocareMapPhonesMatch);
    }
    setSubmitAttempts(submitAttempts + 1);
  }

  let generalErrorMessage = '';
  if (submitAttempts < 3) {
    if (dobErrorS.length > 0 || phoneErrorS.length > 0) {
      generalErrorMessage = strings.CONF_GENERIC_ERROR_A;
    }
  } else {
    if (dobErrorS.length > 0 || phoneErrorS.length > 0) {
      generalErrorMessage = strings.CONF_GENERIC_ERROR_B;
    }
  }
  return (
    <div>
      <div className={css.titleSubtitle}>
        <div className={css.yesIWantToConnect}>{strings.CONF_YES_I_WANT_TO_CONNECT}</div>
        {generalErrorMessage.length == 0 && (
          <div className={css.weRetrieved}>
            {props.isSignup ? strings.CONF_PLEASE_CONFIRM : strings.CONF_WE_RETIEVED}
          </div>
        )}
        {generalErrorMessage.length > 0 && (
          <div className={css.generalError}>
            <label>{generalErrorMessage}</label>
          </div>
        )}
      </div>
      <div className={css.cInputArea + `${props.isSignup ? 'SIGNUP' : ''}`}>
        <div className={css.formRequired}>{strings.REQUIRED}</div>
        <form
          onSubmitCapture={validate}
          className={generalErrorMessage.length != 0 ? css.cInputAreaFormWithErrors : ''}
        >
          <div className={css.formItem}>
            <label className={css.formItemLabel} htmlFor="dob">
              {strings.DATE_OF_BIRTH}
            </label>
            <div className={css.formItemValue}>
              <input
                className={`CONF_DOB_INPUT${props.isSignup ? ' CONF_SIGNUP' : ''}`}
                style={{ width: '100%', border: 'none', fontSize: 'large' }}
                type="date"
                name="dob"
                autoComplete={dob}
                placeholder={strings.CONF_PLEASE_ENTER_DOB}
                value={dob}
                onChange={(x) => setDob(x.target.value)}
                onDoubleClick={() => {
                  const input = document.querySelector('.CONF_DOB_INPUT');
                  if (input) {
                    console.log('showing picker', input, Object.keys(input));
                    if (typeof input['showPicker'] != 'undefined') {
                      (input as HTMLInputElement).showPicker();
                    }
                  }
                }}
              />
            </div>
          </div>

          {dobErrorS.length > 0 && (
            <div className={css.specificError} style={{ marginBottom: '5px' }}>
              <label color="red" htmlFor="dob">
                {dobErrorS}
              </label>
            </div>
          )}

          <div className={css.formItem}>
            <label className={css.formItemLabel} htmlFor="phone">
              {strings.PHONE_NUMBER}
            </label>
            <div className={css.formItemValue}>
              <PhoneInput
                placeholder={strings.CONF_PLEASE_ENTER_PHONE}
                value={'+1' + phone}
                country="US"
                autoComplete="off"
                style={{ width: '100%', border: 'none', fontSize: 'large' }}
                onChange={(x) => {
                  if (x && typeof x == 'string' && x.length > 0) {
                    setPhone(x.replace('+1', ''));
                  }
                }}
              />
            </div>
          </div>
          {phoneErrorS.length > 0 && (
            <div className={css.specificError}>
              <label htmlFor="dob">{phoneErrorS}</label>
            </div>
          )}
          {!props.isSignup && (
            <div className={css.formItem} style={{ marginTop: '1em', fontSize: 'small' }}>
              {strings.EDITING_WILL_NOT_UPDATE_ALLOCARE}
            </div>
          )}
          <div className={css.formItem}>
            <input
              className={css.formItemButton}
              style={{ color: 'white', border: 'none', cursor: 'pointer' }}
              type="submit"
              value={props.isSignup ? strings.SUBMIT : strings.CONFIRM_AND_SUBMIT_INFO}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
