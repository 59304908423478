import { loadingType } from '../../types/actions/app';
import { usersListType, userType } from '../../types/users';

const initialState = {
  list: [],
  loading: {
    status: false,
    progress: 0,
  },
  statusLoading: {
    status: false,
    progress: 0,
  },
  user: {},
};

const users = (
  state = initialState,
  action: {
    type: string;
    payload: { list: usersListType; loading: loadingType; statusLoading: loadingType; user: userType };
  },
): { list: usersListType; loading: loadingType } => {
  switch (action.type) {
    case 'USERS_LIST':
      return Object.assign({}, state, {
        list: action.payload.list,
      });
    case 'USERS_LOADING':
      return Object.assign({}, state, {
        loading: action.payload.loading,
      });
    case 'USER_STATUS_LOADING':
      return Object.assign({}, state, {
        statusLoading: action.payload.loading,
      });
    case 'USER':
      return Object.assign({}, state, {
        user: action.payload,
      });
    default:
      return state;
  }
};

export default users;
