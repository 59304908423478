import { put, call, delay } from 'redux-saga/effects';
// api
import { apiModeratedMessages } from '../../api/moderatedMessages';
// actions
import appAction from '../actions/app';
import moderatedMessagesAction from '../actions/moderatedMessages';
// types
import { getModeratedMessagesListActionReturnType } from '../../types/actions/moderatedMessages';
// helpers
import { handleLoginRedirect } from './helpers';

export function* sagaGetModeratedMessages(action: getModeratedMessagesListActionReturnType): any {
  console.log(action);
  try {
    yield delay(1);
    yield put(moderatedMessagesAction.moderatedMessagesListLoading(false, 0));
    yield put(moderatedMessagesAction.moderatedMessagesListLoading(true, 90));
    const responseMessages = yield call(apiModeratedMessages.getModeratedMessages);
    yield put(moderatedMessagesAction.moderatedMessagesListLoading(true, 100));
    yield put(moderatedMessagesAction.getModeratedMessagesList(responseMessages.data));
    yield delay(1000);
    yield put(moderatedMessagesAction.moderatedMessagesListLoading(false, 0));
  } catch (e) {
    if (e.response.status === 401) {
      handleLoginRedirect();
    } else {
      yield put(moderatedMessagesAction.moderatedMessagesListLoading(true, 100));
      yield put(moderatedMessagesAction.moderatedMessagesListLoading(false, 100));
      yield put(
        appAction.appNotification(
          true,
          {
            header: 'Error',
            message: e.response.data.message || e.response.data.errors[0] || e.response.data.errors.base[0] || 'Error',
          },
          'Error',
        ),
      );
      yield delay(1000);
      yield put(moderatedMessagesAction.moderatedMessagesListLoading(false, 0));
    }
  }
}
