// types
import {
  clearModeratedMessagesListReturnType,
  getModeratedMessagesListActionReturnType,
  getModeratedMessagesListReturnType,
  moderatedMessagesActionType,
  moderatedMessagesListLoadingReturnType,
} from '../../types/actions/moderatedMessages';
import { moderatedMessagesListResponseType } from '../../types/moderatedMessages';
// consts
import * as types from '../constants/actionConstants';

const moderatedMessagesAction: moderatedMessagesActionType = {
  getModeratedMessagesListAction(data: { skip: number; limit: number }): getModeratedMessagesListActionReturnType {
    return {
      type: types.GET_MODERATED_MESSAGES_LIST,
      data,
    };
  },
  getModeratedMessagesList(moderatedMessages: moderatedMessagesListResponseType): getModeratedMessagesListReturnType {
    return {
      type: types.MODERATED_MESSAGES_LIST,
      payload: { moderatedMessages },
    };
  },
  clearModeratedMessagesList(): clearModeratedMessagesListReturnType {
    return {
      type: types.MODERATED_MESSAGES_LIST,
      payload: { moderatedMessages: { items: [], total_entries: 0, skip: 0, limit: 12 } },
    };
  },
  moderatedMessagesListLoading(status: boolean, progress: number): moderatedMessagesListLoadingReturnType {
    const loading = {
      status: status,
      progress: progress,
    };
    return {
      type: types.MODERATED_MESSAGES_LIST_LOADING,
      payload: {
        loading: { ...loading },
      },
    };
  },
};

export default moderatedMessagesAction;
