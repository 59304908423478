import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// components
import LoadingBar from '../LoadingBar/LoadingBar';
// actions
import topicAction from '../../../redux/actions/topic';
// types
import { loadingType } from '../../../types/actions/app';
import { topicType } from '../../../types/topics';
// helpers
import { handleDateFormat, topicTypeHandler } from '../../../config/helpers';
// style
import css from './editTopicForm.module.css';

interface EditTopicFormProps {
  topic: topicType;
  loading: loadingType;
  isReset: boolean;
  setIsReset: (arg0: boolean) => void;
  className: string;
}

const EditTopicForm: FC<EditTopicFormProps> = ({
  topic,
  loading,
  isReset,
  setIsReset,
  className,
}: EditTopicFormProps) => {
  const [loadingBarProgress, updateProgress] = useState<number>(0);
  const [isLoadingFinished, setIsLoadingFinished] = useState<boolean>(false);
  const [values, setValues] = useState({ name: '', description: '', surveyName: '', answers: [''] });

  const dispatch = useDispatch();

  useEffect(() => {
    updateProgress(0);
    setIsLoadingFinished(false);
  }, []);

  // initial set input values from topic api request
  useEffect(() => {
    setValues({
      name: topic.name,
      description: topic.description,
      surveyName: topic.survey ? topic.survey.name : '',
      answers: topic.survey ? topic.survey.answers : [''],
    });
  }, [topic.name]);

  useEffect(() => {
    updateProgress(loading.progress);
  }, [loading]);

  // reset form inputs from parent component
  useEffect(() => {
    isReset && handleFormReset();
  }, [isReset]);

  // form submit handler
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(topicAction.topicEdit(values, topic._id));
  };

  // form reset handler
  const handleFormReset = () => {
    if (
      topic.name !== values.name ||
      values.description !== 'description' ||
      topic.survey?.name !== values.surveyName
    ) {
      setValues({
        name: topic.name,
        description: 'description',
        surveyName: topic.survey ? topic.survey.name : '',
        answers: topic.survey ? topic.survey.answers : [''],
      });
    }
    setIsReset(false);
  };

  // input change handler
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <div className={`${css.editTopicFormWrapper} ${className}`}>
      <div className={css.editTopicFormHeader}>
        <span className={css.editTopicFormHeading}>Overview</span>
      </div>
      <div className={css.editTopicFormLoading}>
        <LoadingBar
          loadingBarProgress={loadingBarProgress}
          onLoaderFinished={() => {
            updateProgress(0);
            setIsLoadingFinished(true);
          }}
        />
      </div>
      {isLoadingFinished && !loading.status && (
        <form id="edit-topic-form" action="" className={css.editTopicForm} onSubmit={handleSubmit}>
          <div className={css.editTopicFormRow}>
            <div className={css.editTopicFormInputWrapper}>
              <label htmlFor="topic-name">Category</label>
              <input id="topic-name" disabled name="name" type="text" value={values.name} onChange={handleChange} />
            </div>
          </div>
          <div className={css.editTopicFormRow}>
            <div className={css.editTopicFormTextareaWrapper}>
              <label htmlFor="topic-description">Description</label>
              <textarea
                disabled
                name="description"
                value={values.description}
                id="topic-description"
                onChange={handleChange}
              ></textarea>
            </div>
          </div>
          <div className={css.editTopicFormRow}>
            <div className={css.editTopicFormInputWrapper}>
              <label>Type</label>
              <input disabled value={topicTypeHandler(topic.topic_type)} type="text" />
            </div>
            <div className={css.editTopicFormInputWrapper}>
              <label>Creator</label>
              <input disabled value={topic.user_id} type="text" />
            </div>
          </div>
          <div className={css.editTopicFormRow}>
            <div className={css.editTopicFormInputWrapper}>
              <label>Created at</label>
              <input disabled value={handleDateFormat(topic.created_at)} type="text" />
            </div>
            <div className={css.editTopicFormInputWrapper}>
              <label>Updated at</label>
              <input disabled value={handleDateFormat(topic.updated_at)} type="text" />
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditTopicForm;
