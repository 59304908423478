/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
// components
import BanUser from '../../../components/containers/BanUser/BanUser';
// style
import css from '../../../components/containers/Table/table.module.css';

export const columns = [
  {
    className: css.participantsEmail,
    title: 'Email address',
    dataIndex: 'email',
    key: 'email',
  },
  {
    className: css.participantsName,
    title: 'Full name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    className: css.participantsStatus,
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    // eslint-disable-next-line react/display-name
    render: (value, row) => {
      return (
        <div className={css.iconCellWrapper}>
          {value}
          <BanUser id={row.id} status={row.status} name={row.name} />
        </div>
      );
    },
  },
];
