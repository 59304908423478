// types
import {
  surveyActionType,
  getSurveyActionReturnType,
  getSurveyReturnType,
  surveyLoadingReturnType,
  clearSurveyReturnType,
} from '../../types/actions/survey';
import { surveyType } from '../../types/survey';
// consts
import * as types from '../constants/actionConstants';

const surveyAction: surveyActionType = {
  getSurveyAction(id: string): getSurveyActionReturnType {
    return {
      type: types.GET_SURVEY,
      id,
    };
  },
  getSurvey(survey: surveyType): getSurveyReturnType {
    return {
      type: types.SURVEY,
      payload: { survey },
    };
  },
  surveyLoading(status: boolean, progress: number): surveyLoadingReturnType {
    const loading = {
      status: status,
      progress: progress,
    };
    return {
      type: types.SURVEY_LOADING,
      payload: {
        loading: { ...loading },
      },
    };
  },
  clearSurvey(): clearSurveyReturnType {
    return {
      type: types.SURVEY,
      payload: {
        survey: {
          created_at: 0,
          participants_id: [],
          survey_ans: [],
          survey_question: '',
          survey_votes: [],
          updated_at: 0,
          user_id: 0,
          _id: '',
          _parent_id: '',
        },
      },
    };
  },
};

export default surveyAction;
