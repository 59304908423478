import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_URL + '/';
// types
import { apiSurveyType } from '../types/api/survey';
import { customSurveyType, surveyType } from '../types/survey';
import { topicType } from '../types/topics';

export const apiSurvey: apiSurveyType = {
  getSurvey: async (id: string): Promise<{ data: surveyType }> => {
    return await axios({
      url: `${baseUrl}data/ChatTopic/${id}/Survey.json`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
    });
  },
  createCustomSurvey: async (data: customSurveyType): Promise<{ data: topicType }> => {
    return await axios({
      url: `${baseUrl}data/ChatTopic/${data.parent_id}/Survey.json`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
      data,
    });
  },
};
