import React, { FC } from 'react';
import { Pagination as IPagination, PaginationProps as IPaginationProps } from 'semantic-ui-react';
// styles
import './style.css';

interface PaginationProps {
  skip: number;
  length: number;
  handleChange: (e: React.MouseEvent<HTMLAnchorElement>, data: IPaginationProps) => void;
}

const Pagination: FC<PaginationProps> = ({ skip, length, handleChange }: PaginationProps) => {
  return (
    <IPagination
      activePage={skip / 12 + 1}
      totalPages={Math.ceil(length / 12)}
      onPageChange={handleChange}
      disabled={false}
      boundaryRange={1}
      siblingRange={0}
      ellipsisItem={undefined}
      firstItem={null}
      lastItem={null}
      prevItem="Previous"
      nextItem="Next"
    />
  );
};

export default Pagination;
