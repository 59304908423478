// types
import {
  messagesActionType,
  getMessagesListReturnType,
  clearMessagesListReturnType,
  getMessagesListActionReturnType,
  messagesListLoadingReturnType,
  deleteMessageActionReturnType,
  sendMessageActionReturnType,
  sendMessageLoadingReturnType,
} from '../../types/actions/messages';
import {
  messagesFiltersType,
  messagesListType,
  messagesListResponseType,
  sendMessageParamsType,
} from '../../types/messages';
// consts
import * as types from '../constants/actionConstants';

const messagesAction: messagesActionType = {
  getMessagesListAction(data: {
    dialog_id: string;
    search_value: string;
    reported: boolean;
    sender_id: string;
    skip: number;
    limit: number;
    total_entries: number;
  }): getMessagesListActionReturnType {
    return {
      type: types.GET_MESSAGES_LIST,
      data,
    };
  },
  deleteMessageAction(
    customTopicId: string,
    messageData: { message_id: string; messages: messagesListType },
    data: messagesFiltersType,
    callback: (data: messagesFiltersType) => void,
  ): deleteMessageActionReturnType {
    return {
      type: types.DELETE_MESSAGE,
      customTopicId,
      messageData,
      data,
      callback,
    };
  },
  getMessagesList(messages: messagesListResponseType): getMessagesListReturnType {
    return {
      type: types.MESSAGES_LIST,
      payload: { messages },
    };
  },
  clearMessagesList(): clearMessagesListReturnType {
    return {
      type: types.MESSAGES_LIST,
      payload: { items: [], total_entries: 0, skip: 0, limit: 100 },
    };
  },
  messagesListLoading(status: boolean, progress: number): messagesListLoadingReturnType {
    const loading = {
      status: status,
      progress: progress,
    };
    return {
      type: types.MESSAGES_LIST_LOADING,
      payload: {
        loading: { ...loading },
      },
    };
  },
  sendMessageAction(data: sendMessageParamsType, callback: () => void): sendMessageActionReturnType {
    return {
      type: types.SEND_MESSAGE,
      data,
      callback,
    };
  },
  sendMessageLoading(status: boolean, progress: number): sendMessageLoadingReturnType {
    const loading = {
      status: status,
      progress: progress,
    };
    return {
      type: types.SEND_MESSAGE_LOADING,
      payload: {
        sendMessageLoading: { ...loading },
      },
    };
  },
};

export default messagesAction;
