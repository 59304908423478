import { useEffect, useRef, useState, FC } from 'react';
import * as React from 'react';

// types
import { messagesListType } from '../../../types/messages';
import { topicsListType } from '../../../types/topics';
import { usersListType } from '../../../types/users';
// style
import css from './searchField.module.css';

interface SearchFieldProps {
  searchCallback?: (search_value?: string, skip?: number, limit?: number, reported?: boolean) => void;
  disabled?: boolean;
  searchedList: messagesListType | usersListType | topicsListType;
  loading: boolean;
  setLoading?: (arg0: boolean) => void;
  placeholder: string;
  onFocus?: () => void;
  customInput?: string;
  customSetInput?: (arg0: string) => void;
  customLabel?: string;
  id: string;
  lastChangedInputId?: string;
}
let timerId: any = false;

const SearchField: FC<SearchFieldProps> = ({
  disabled,
  searchCallback,
  searchedList,
  loading,
  setLoading,
  placeholder,
  onFocus,
  customInput,
  customSetInput,
  customLabel,
  id,
  lastChangedInputId,
}: SearchFieldProps) => {
  const [input, setInput] = useState<string>('');

  const inputRef = useRef<any>();

  // remove disabled state from input when messages list changed (after we got any search results)
  useEffect(() => {
    setLoading && setLoading(false);
  }, [searchedList]);

  // set focus at input after input is not disabled
  useEffect(() => {
    (customInput ? customInput.length > 0 : input.length > 0 && searchCallback) &&
      !loading &&
      (lastChangedInputId ? inputRef.current.id === lastChangedInputId : false) &&
      inputRef.current.focus();
  }, [loading]);

  // search
  const handleSearch = (value: string) => {
    setLoading && setLoading(true);
    searchCallback && searchCallback(value);
    return true;
  };

  // input change logic
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    customSetInput ? customSetInput(value) : setInput(value);

    value.length === 0 && searchCallback && searchCallback();
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      value.length > 0 && handleSearch(value) && customSetInput && customSetInput(value);
    }, 1000);
  };
  return (
    <div className={css.searchField}>
      <label>{customLabel ? customLabel : 'Search'}: </label>
      <input
        placeholder={placeholder}
        value={customInput ? customInput : input}
        onChange={handleChange}
        ref={inputRef}
        disabled={disabled ? disabled : loading}
        onFocus={onFocus && onFocus}
        id={id}
        onBlur={() => inputRef.current.blur()}
      />
    </div>
  );
};

export default SearchField;
