import React, { FC, useEffect, useRef } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
// types
import { OktaSignInConfigType } from '../../../../../types/app';
// style
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import { getStringConstants } from '../../../../../config/localization';
import css from '../emailauth.module.css';
import { Link } from 'react-router-dom';
interface OktaWidgetProps {
  config: OktaSignInConfigType;
  onSuccess: () => void;
  onError: () => void;
  onSignUpClick: () => void;
}
const AllocareOktaSignInWidget: FC<any> = ({ config, onSuccess, onError, onSignUpClick }: OktaWidgetProps) => {
  const widgetRef = useRef<any>();
  const [forgotPassword, setForgotPassword] = React.useState(false);
  const [isMfaScreen, setIsMfaScreen] = React.useState(false);

  const strings = getStringConstants();

  useEffect(() => {
    const widget = new OktaSignIn(config);

    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(onSuccess)
      .catch(onError);

    widget.on('afterRender', function (context: any) {
      if (context?.controller == 'mfa-verify') {
        setIsMfaScreen(true);

        const oFormExplain = document.querySelector('.o-form-explain');
        if (oFormExplain) {
          oFormExplain.setAttribute('data-content', strings.SMS_AUTHENTICATION + '\n');
        }
        const authContainer = document.querySelector('.auth-content') as HTMLElement;
        if (authContainer) {
          authContainer.style.minHeight = '325px';
        }

        const title = document.querySelector('.okta-form-title');
        if (title) {
          title.classList.add('mfa');
          title.setAttribute('data-content', strings.CONFIRM_YOUR_PHONE);
        }
        const authFooter = document.querySelector('.auth-footer') as HTMLElement;
        if (authFooter) {
          authFooter.style.display = 'flex';
          authFooter.style.justifyContent = 'center';
        }
      } else {
        setIsMfaScreen(false);
        if (!document.querySelector('#login-required-el')) {
          const container = document.querySelector('.o-form-fieldset-container');
          if (container != null) {
            const reqEl = document.createElement('div');
            reqEl.innerHTML = `<span id="login-required-el" style="font-size: small; position: absolute; right: 0px; color: black !important;"><span style="color: red;">*</span>${strings.REQUIRED}</span>`;
            container.prepend(reqEl);
          }
        }

        const title = document.querySelector('.okta-form-title');
        if (title) {
          title.classList.add('login');
          title.setAttribute('data-content', strings.LOGIN);
        }

        const appleButton = document.querySelector('.social-auth-apple-button');
        if (appleButton != null) {
          appleButton.innerHTML = `<center>${strings.CONTINUE_WITH_APPLE}</center>`;
        }
        const googleButton = document.querySelector('.social-auth-google-button');
        if (googleButton != null) {
          googleButton.innerHTML = `<center>${strings.CONTINUE_WITH_GOOGLE}</center>`;
        }

        const userNameField = document.querySelector('[for=okta-signin-username]');
        if (userNameField) {
          userNameField.innerHTML = strings.EMAIL_SPACE;
        }

        const forgotPasswordButton = document.querySelector('.cokta-forgot-pass');
        if (!forgotPasswordButton) {
          const signinButtonContainer = document.querySelector('.o-form-button-bar');
          console.log(signinButtonContainer);
          if (signinButtonContainer != null) {
            const newDiv = document.createElement('DIV');
            newDiv.innerHTML = `<center><a class="cokta-forgot-pass">${strings.FORGOT_PASSWORD}</a></center>`;
            signinButtonContainer.appendChild(newDiv);

            document.querySelector('.cokta-forgot-pass')?.addEventListener('click', function () {
              setForgotPassword(true);
            });
          }
        }
      }
    });

    return () => widget.remove();
  }, [config, onSuccess, onError]);

  return (
    <>
      {forgotPassword && (
        <div
          style={{
            position: 'fixed',
            zIndex: 90,
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            background: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          <div
            style={{
              position: 'fixed',
              zIndex: 99,
              backgroundColor: 'white',
              top: '33%',
              right: '25%',
              left: '25%',
              height: '225px',
              minWidth: '200px',
              padding: '2.5em',
              fontFamily: 'Trade Gothic LT Std',
              fontWeight: 16,
            }}
          >
            <span>{strings.RESET_PASSWORD_DESC}</span>
            <button
              onClick={() => setForgotPassword(false)}
              style={{
                marginTop: '2em',
                alignSelf: 'stretch',
                maxWidth: '323px',
                minWidth: '100px',
                height: '40px',
                background: '#1F8ECC',
                borderRadius: '4px',
                color: 'white',
                borderColor: 'white',
                zIndex: 100,
              }}
            >
              {strings.DISMISS}
            </button>
          </div>
        </div>
      )}
      <div>
        <div ref={widgetRef} />
        {!isMfaScreen && (
          <div style={{ width: '361px', padding: '20px' }}>
            <div className={css.dontHaveAccountWrapper}>
              <div className={css.dontHaveAccountText}>{strings.DONT_HAVE_AN_ACCOUT}</div>
              <div className={css.dontHaveAccountLink}>
                <a style={{ cursor: 'pointer' }} onClick={onSignUpClick}>
                  {strings.SIGN_UP_HERE}
                </a>
              </div>
            </div>
            <div className={css.anyQuestionsText}>{strings.SIGNIN_ANY_QUESTIONS}</div>
          </div>
        )}
      </div>
    </>
  );
};
export default AllocareOktaSignInWidget;
