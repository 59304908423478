import React, { FC } from 'react';
import { Link } from 'react-router-dom';
// components
import UserDropdown from '../UserDropdown/UserDropdown';
// style
import css from './header.module.css';

interface HeaderProps {
  logout?: boolean;
}

const Header: FC<HeaderProps> = ({ logout }: HeaderProps) => {
  return (
    <header className={css.header}>
      <Link className={css.logo} to="/">
        CareDX
      </Link>
      {logout && <UserDropdown />}
    </header>
  );
};

export default Header;
