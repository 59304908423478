// types
import {
  reportActionType,
  getReportActionReturnType,
  getReportReturnType,
  clearReportReturnType,
  reportLoadingReturnType,
} from '../../types/actions/report';
import { reportType } from '../../types/reports';
// consts
import * as types from '../constants/actionConstants';

const reportAction: reportActionType = {
  getReportAction(id: string): getReportActionReturnType {
    return {
      type: types.GET_REPORT,
      id,
    };
  },
  getReport(report: reportType): getReportReturnType {
    return {
      type: types.REPORT,
      payload: { report },
    };
  },
  clearReport(): clearReportReturnType {
    return {
      type: types.REPORT,
      payload: { report: { id: '', category: '', type: '', reported_by: '', reported_at: '' } },
    };
  },
  reportLoading(status: boolean, progress: number): reportLoadingReturnType {
    const loading = {
      status: status,
      progress: progress,
    };
    return {
      type: types.REPORT_LOADING,
      payload: {
        loading: { ...loading },
      },
    };
  },
};

export default reportAction;
