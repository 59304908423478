import * as React from 'react';
import { getStringConstants } from '../../../../config/localization';
import css from './emailauth.module.css';

const strings = getStringConstants();

interface PasswordInputProps {
  value: string;
  onChange: (newValue: string) => void;
}

export default function PasswordInput(props: PasswordInputProps) {
  const [passwordVisible, setPasswordVisible] = React.useState<boolean>(false);

  return (
    <>
      <input
        className={css.passwordInputInput}
        type={passwordVisible ? 'text' : 'password'}
        name="pass"
        placeholder={strings.PASSWORD}
        value={props.value}
        onChange={(ev) => props.onChange(ev.target.value)}
      />
      <span className={css.passwordInputToggle} onClick={() => setPasswordVisible(!passwordVisible)}>
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="18" height="18" transform="translate(0.5)" fill="white" fillOpacity="0.01" />
          <g clipPath="url(#clip0_26585_730)">
            <rect width="16" height="16" transform="translate(1.5 1)" fill="white" fillOpacity="0.01" />
            <path
              d="M14.861 12.2392C16.562 10.7212 17.502 9.00122 17.502 9.00122C17.502 9.00122 14.502 3.50122 9.50195 3.50122C8.54155 3.50452 7.59201 3.70464 6.71195 4.08922L7.48195 4.86022C8.12939 4.62437 8.8129 4.5029 9.50195 4.50122C11.622 4.50122 13.381 5.66922 14.67 6.95822C15.2903 7.58191 15.8464 8.26634 16.33 9.00122C16.272 9.08822 16.208 9.18422 16.135 9.28922C15.8 9.76922 15.305 10.4092 14.67 11.0442C14.505 11.2092 14.333 11.3722 14.153 11.5302L14.861 12.2392Z"
              fill="#6C757D"
            />
            <path
              d="M12.799 10.1772C13.0221 9.55306 13.0634 8.87836 12.9181 8.23164C12.7728 7.58491 12.4468 6.99274 11.9781 6.52404C11.5094 6.05533 10.9173 5.72936 10.2705 5.58405C9.62381 5.43874 8.94911 5.48006 8.32495 5.70322L9.14795 6.52622C9.53226 6.47121 9.9241 6.50646 10.2924 6.62918C10.6607 6.7519 10.9954 6.95871 11.2699 7.23323C11.5445 7.50775 11.7513 7.84242 11.874 8.21075C11.9967 8.57907 12.032 8.97091 11.977 9.35522L12.799 10.1772ZM9.85595 11.4762L10.678 12.2982C10.0538 12.5214 9.3791 12.5627 8.73237 12.4174C8.08565 12.2721 7.49348 11.9461 7.02477 11.4774C6.55607 11.0087 6.23009 10.4165 6.08478 9.7698C5.93947 9.12307 5.9808 8.44837 6.20395 7.82422L7.02695 8.64722C6.97195 9.03153 7.0072 9.42337 7.12992 9.79169C7.25263 10.16 7.45945 10.4947 7.73396 10.7692C8.00848 11.0437 8.34316 11.2505 8.71148 11.3733C9.0798 11.496 9.47164 11.5312 9.85595 11.4762Z"
              fill="#6C757D"
            />
            <path
              d="M4.85195 6.47122C4.67195 6.63122 4.49895 6.79322 4.33395 6.95822C3.7136 7.58191 3.15747 8.26634 2.67395 9.00122L2.86895 9.28922C3.20395 9.76922 3.69895 10.4092 4.33395 11.0442C5.62295 12.3332 7.38295 13.5012 9.50195 13.5012C10.218 13.5012 10.892 13.3682 11.522 13.1412L12.292 13.9132C11.4119 14.2978 10.4624 14.4979 9.50195 14.5012C4.50195 14.5012 1.50195 9.00122 1.50195 9.00122C1.50195 9.00122 2.44095 7.28022 4.14295 5.76322L4.85095 6.47222L4.85195 6.47122ZM15.148 15.3552L3.14795 3.35522L3.85595 2.64722L15.856 14.6472L15.148 15.3552Z"
              fill="#6C757D"
            />
          </g>
          <defs>
            <clipPath id="clip0_26585_730">
              <rect width="16" height="16" fill="white" transform="translate(1.5 1)" />
            </clipPath>
          </defs>
        </svg>
      </span>
    </>
  );
}
