import { put, call, delay } from 'redux-saga/effects';
// api
import appAction from '../actions/app';
// actions
import reportAction from '../actions/report';
// types
import { getReportActionReturnType } from '../../types/actions/report';
import { apiReport } from '../../api/report';
// helpers
import { handleLoginRedirect } from './helpers';

export function* sagaGetReport(action: getReportActionReturnType): any {
  console.log(action);
  try {
    yield delay(1);
    yield put(reportAction.reportLoading(false, 0));
    yield put(reportAction.reportLoading(true, 90));
    const responseReport = yield call(apiReport.getReport);
    yield put(reportAction.reportLoading(true, 100));
    yield put(reportAction.getReport(responseReport.data));
    yield delay(1000);
    yield put(reportAction.reportLoading(false, 0));
  } catch (e) {
    if (e.response.status === 401) {
      handleLoginRedirect();
    } else {
      yield put(reportAction.reportLoading(true, 100));
      yield put(reportAction.reportLoading(false, 100));
      yield put(
        appAction.appNotification(
          true,
          {
            header: 'Error',
            message: e.response.data.message || e.response.data.errors[0] || e.response.data.errors.base[0] || 'Error',
          },
          'Error',
        ),
      );
      yield delay(1000);
      yield put(reportAction.reportLoading(false, 0));
    }
  }
}
