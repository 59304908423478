import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// actions
import appAction from '../../../redux/actions/app';
import topicsAction from '../../../redux/actions/topics';
// types
import { filtersType } from '../../../types/app';
import { loadingType } from '../../../types/actions/app';
// style
import css from './sortButton.module.css';

interface ButtonProps {
  sortValue: string;
}

const SortButton: FC<ButtonProps> = ({ sortValue }: ButtonProps) => {
  //store
  const filters = useSelector((state: { app: { filters: filtersType } }) => state.app.filters);
  const loading = useSelector((state: { topics: { loading: loadingType } }) => state.topics.loading);

  const dispatch = useDispatch();

  const handleSort = () => {
    const data = {
      skip: filters.skip,
      limit: filters.limit,
      search_value: filters.search_value,
      topic_type: filters.topic_type,
      name: filters.name,
      sort: {
        field_name: sortValue,
        value:
          filters.sort?.field_name === sortValue
            ? filters.sort.value === 'sort_asc'
              ? 'sort_desc'
              : 'sort_asc'
            : 'sort_desc',
      },
    };

    dispatch(appAction.queryString(data));
    dispatch(topicsAction.getTopicsListAction(data));
  };

  return <div className={loading.status ? css.sortArrowDisabled : css.sortArrow} onClick={handleSort} />;
};

export default SortButton;
