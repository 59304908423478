import React, { FC, useEffect, useState } from 'react';
import { loadingType } from '../../../types/actions/app';
// components
import LoadingBar from '../LoadingBar/LoadingBar';
// types
import { surveyType } from '../../../types/survey';
// style
import css from './surveyContainer.module.css';

interface SurveyContainerProps {
  className: string;
  survey: surveyType;
  loading: loadingType;
}

const SurveyContainer: FC<SurveyContainerProps> = ({ className, survey, loading }: SurveyContainerProps) => {
  const [loadingBarProgress, updateProgress] = useState<number>(0);
  const [isLoadingFinished, setIsLoadingFinished] = useState<boolean>(false);

  useEffect(() => {
    updateProgress(0);
    setIsLoadingFinished(false);
  }, []);

  useEffect(() => {
    updateProgress(loading.progress);
  }, [loading]);

  const totalVotes = survey.survey_votes.reduce((acc, curr) => acc + curr, 0);

  const handlePercentages = (total: number, votes: number) => {
    return total === 0 ? 0 : Math.round(100 / (total / votes));
  };

  return (
    <div className={`${className} ${css.surveyContainer}`}>
      <div className={css.surveyHeader}>
        <span>Survey results</span>
      </div>
      <div className={css.surveyResultLoading}>
        <LoadingBar
          loadingBarProgress={loadingBarProgress}
          onLoaderFinished={() => {
            updateProgress(0);
            setIsLoadingFinished(true);
          }}
        />
      </div>
      {isLoadingFinished && !loading.status && (
        <div className={css.surveyResultsWrapper}>
          {survey.survey_ans.map((item, index) => (
            <div className={css.surveyResultsItem} key={index}>
              <span key={index}>{item}</span>
              <div className={css.surveyResultProgressBarWrapper}>
                <div
                  className={css.surveyResultProgressBar}
                  style={{ width: handlePercentages(totalVotes, survey.survey_votes[index]) + '%' }}
                />
                <span>%{handlePercentages(totalVotes, survey.survey_votes[index])}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SurveyContainer;
