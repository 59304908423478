import React, { FC } from 'react';
import { Link } from 'react-router-dom';
// components
import UserDropdown from '../UserDropdown/UserDropdown';
// style
import css from './allocareheader.module.css';

interface AllocareHeaderProps {
  logout?: boolean;
}

const AllocareHeader: FC<AllocareHeaderProps> = ({ logout }: AllocareHeaderProps) => {
  return (
    <header className={css.header}>
      <div className={css.logo}></div>
    </header>
  );
};

export default AllocareHeader;
