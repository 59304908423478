import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_URL + '/';
// types
import { apiMessagesType } from '../types/api/messages';
import { messagesListType, messageType, sendMessageParamsType } from '../types/messages';

export const apiMessages: apiMessagesType = {
  getMessages: async (
    data: {
      dialog_id: string;
      search_value: string;
      reported: boolean;
      sender_id: string;
      skip: number;
      limit: number;
    },
    isTotal?: boolean,
  ): Promise<{ data: { items: messagesListType } }> => {
    return await axios({
      url: `${baseUrl}chat/Message.json?chat_dialog_id=${data.dialog_id}${
        data.search_value ? `&message[ctn]=${data.search_value}` : ''
      }${data.reported ? `&message[ctn]=Reported` : ''}${data.sender_id ? `&sender_id=${data.sender_id}` : ''}&skip=${
        data.skip
      }&limit=${data.limit}&sort_desc=date_sent${isTotal ? '&count=1' : ''}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
    });
  },
  deleteMessage: async (message_id: string): Promise<{ data: { SuccessfullyDeleted: { ids: Array<string> } } }> => {
    return await axios({
      url: `${baseUrl}chat/Message/${message_id}.json`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
      data: {
        force: 1,
      },
    });
  },
  sendMessage: async (data: sendMessageParamsType): Promise<{ data: messageType }> => {
    return await axios({
      url: `${baseUrl}chat/Message.json`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
      data: {
        chat_dialog_id: data.chat_dialog_id,
        message: data.message,
        type: data.type,
        send_to_chat: 1,
        markable: 1,
        reported: data.type === 'reported' ? true : false,
      },
    });
  },
};
