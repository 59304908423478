import * as React from 'react';
import { getStringConstants } from '../../../../config/localization';
import css from './emailauth.module.css';

export default function AMBadLink(props: any) {
  const strings = getStringConstants();
  return (
    <div style={{ width: '361px', padding: '1em', fontSize: 16 }}>
      <div>{strings.BL_OOPS}</div>
      <div>
        <div style={{ marginTop: '2em' }}>{strings.BL_YOU_CLICKED}</div>
        <div style={{ marginTop: '2em' }}>{strings.BL_PLEASE_CONTACT}</div>
        <div style={{ marginTop: '2em' }}>{strings.BL_HAVE_QUESTIONS}</div>
      </div>
    </div>
  );
}
