// types
import {
  usersActionType,
  getUsersListReturnType,
  clearUsersListReturnType,
  usersLoadingReturnType,
  userStatusLoadingReturnType,
  userStatusActionReturnType,
} from '../../types/actions/users';
import { searcUsersActionReturnType } from '../../types/actions/users';
import { getUsersListActionReturnType } from '../../types/actions/users';
import { usersListType } from '../../types/users';
// consts
import * as types from '../constants/actionConstants';

const usersAction: usersActionType = {
  getUsersListAction(data: Array<string>): getUsersListActionReturnType {
    return {
      type: types.GET_USERS_LIST,
      data,
    };
  },
  getUsersList(list: usersListType): getUsersListReturnType {
    return {
      type: types.USERS_LIST,
      payload: { list },
    };
  },
  searcUsersAction(callback: () => void): searcUsersActionReturnType {
    return {
      type: types.SEARCH_USERS,
      callback,
    };
  },
  userStatus(id: number, status: boolean, users: usersListType, callback: () => void): userStatusActionReturnType {
    return {
      type: types.USER_STATUS,
      id,
      status,
      users,
      callback,
    };
  },
  clearUsersList(): clearUsersListReturnType {
    return {
      type: types.USERS_LIST,
      payload: [],
    };
  },
  usersLoading(status: boolean, progress: number): usersLoadingReturnType {
    const loading = {
      status: status,
      progress: progress,
    };
    return {
      type: types.USERS_LOADING,
      payload: {
        loading: { ...loading },
      },
    };
  },
  userStatusLoading(status: boolean, progress: number): userStatusLoadingReturnType {
    const loading = {
      status: status,
      progress: progress,
    };
    return {
      type: types.USER_STATUS_LOADING,
      payload: {
        loading: { ...loading },
      },
    };
  },
};

export default usersAction;
