import React, { FC, useEffect, useRef, useState } from 'react';
// types
import { OktaSignInConfigType } from '../../../../../types/app';
// style
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import { getStringConstants, getLanguage } from '../../../../../config/localization';
import css from '../emailauth.module.css';
import { SignupFormState } from './SignupForm';
import { apiApp } from '../../../../../api/app';
import { oktaAuthConfig } from '../../../../../config/okta';
import { OktaAuth } from '@okta/okta-auth-js';

interface OktaWidgetProps {
  config: OktaSignInConfigType;
  formData: SignupFormState;
  patientId: string;
  onSuccess: (accessToken: string) => void;
  onError: (errorMessage: string) => void;
}

const oktaAnonymousHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Accept-Language': getLanguage(),
};
type CodeState = 'NOT_CLICKED' | 'SEND_CODE_CLICKED' | 'RESEND_CODE_CLICKED';
const AllocareOktaSignUpWidget: FC<any> = (props: OktaWidgetProps) => {
  const strings = getStringConstants();
  const [errorString, setErrorString] = React.useState('');
  const [codeState, setCodeState] = React.useState<CodeState>('NOT_CLICKED');
  const [factorRes, setFactorRes] = React.useState({});
  const [userCode, setUserCode] = React.useState('');
  const alertMessage = React.useRef('');
  async function handleUserClickSendCode() {
    if (codeState == 'NOT_CLICKED') {
      await attemptSignup();
      setCodeState('SEND_CODE_CLICKED');
    } else if (codeState == 'SEND_CODE_CLICKED') {
      if (typeof factorRes['_links'] !== 'undefined' && typeof factorRes['_links']['resend'] !== 'undefined') {
        const resendUrl = factorRes['_links']['resend'][0].href;
        const stateToken = factorRes['stateToken'];
        const resendResponse = await apiApp.oktaResendMfaFactor(oktaAnonymousHeaders, resendUrl, {
          factorType: 'sms',
          profile: {
            phoneNumber: props.formData.phoneNumber,
          },
          provider: 'OKTA',
          stateToken: stateToken,
        });
        if (typeof resendResponse['errorSummary'] !== 'undefined') {
          setErrorString(resendResponse['errorSummary']);
          //setErrorString(strings.SU_2F_INVALID_CODE);
        } else {
          setErrorString('');
          setCodeState('RESEND_CODE_CLICKED');
        }
      }
    } else {
      // should not hit here
    }
  }

  async function attemptSignup() {
    // setTimeout(async () => {
    const patientid = props.patientId;
    // TODO: move this api call to where the password validation is happening or on clicking Validate (in the future)
    const attemptProfileCreate = await apiApp.oktaUserProfileCreate({
      // api call
      email: props.formData.email,
      firstName: props.formData.firstName,
      lastName: props.formData.lastName,
      login: props.formData.email,
      mobilePhone: props.formData.phoneNumber,
      password: props.formData.password,
      pId: patientid,
      primaryPhone: props.formData.phoneNumber,
      secondEmail: props.formData.email,
      wpId: 0,
    });

    // Display error text in the console
    alertMessage.current = attemptProfileCreate.data.oktaErrorSummary;
    // check if the alertMessage is not empty => an error happened => display the alert
    if (alertMessage.current != null) {
      props.onError(alertMessage.current); // through useRef
    }

    const response = await apiApp.oktaSigninWithUserDetails(
      oktaAnonymousHeaders,
      props.formData.email,
      props.formData.password,
    );

    const response2 = await apiApp.oktaEnrollMfaFactor(oktaAnonymousHeaders, {
      factorType: 'sms',
      profile: {
        phoneNumber: props.formData.phoneNumber,
      },
      provider: 'OKTA',
      stateToken: response.stateToken,
    });

    setFactorRes(response2);
    // }, 0);
  }

  async function validateToken() {
    if (userCode.length > 0) {
      const response = await apiApp.oktaValidateMfaFactor(
        oktaAnonymousHeaders,
        factorRes['_embedded']['factor']['id'],
        factorRes['stateToken'],
        userCode,
      );

      if (typeof response['errorSummary'] !== 'undefined') {
        setErrorString(response['errorSummary']);
      } else {
        setErrorString('');
      }

      const sessionToken = response.sessionToken;

      const authClient = new OktaAuth(oktaAuthConfig);
      authClient.token
        .getWithoutPrompt({
          responseType: 'id_token',
          sessionToken: sessionToken,
        })
        .then(function (res) {
          const tokens = res.tokens;

          authClient.tokenManager.setTokens(tokens);
          props.onSuccess(tokens.accessToken.accessToken);
        })
        .catch(function (err) {
          console.error(err);
          // pass the error message in here, might have to use the useState hook - how exactly?
          // get the useState variable
          props.onError(alertMessage.current);
        });
    } else {
    }
  }

  const codeSendButtonText =
    codeState == 'NOT_CLICKED'
      ? strings.SU_2F_SEND_CODE
      : codeState == 'SEND_CODE_CLICKED'
      ? strings.SU_2F_RESEND_CODE
      : strings.SU_2F_SENT_CODE;
  return (
    <div className={css.su2FContainer}>
      <div className={css.su2FHiUser}>{`${strings.SU_2F_HI} ${props.formData.firstName}`}</div>
      <div className={css.su2WeWillText}>{strings.SU_2F_WE_WILL_TEXT_YOU}</div>
      <div className={css.su2SmsAuthText}>
        <div>{strings.SMS_AUTHENTICATION}</div>
        <div>
          <b>(+1 XXX-XXX-{props.formData.phoneNumber.substring(props.formData.phoneNumber.length - 4)})</b>
        </div>
      </div>
      <form
        className={css.su2fForm}
        onSubmit={function (ev) {
          ev.preventDefault();
          validateToken();
        }}
      >
        <label className={css.su2fEnterCodeLabel} htmlFor="user-code">
          {strings.SU_2F_ENTER_CODE} <div style={{ color: 'red' }}>{errorString}</div>
        </label>
        <div className={css.su2fEnterCodeFieldContainer}>
          <input
            name="user-code"
            type="text"
            value={userCode}
            className={css.su2fEnterCodeField}
            onChange={(ev) => setUserCode(ev?.target?.value)}
          ></input>
          <button
            disabled={codeState == 'RESEND_CODE_CLICKED'}
            className={css.su2fEnterCodeButton}
            onClick={(ev) => {
              ev.preventDefault();
              handleUserClickSendCode();
            }}
          >
            {codeSendButtonText}
          </button>
        </div>
        <div className={css.su2fStandardMessageApply}>{strings.SU_2F_STD_MSG_DATA}</div>
        <input className={css.su2fVerifyButton} type="submit" value={strings.SU_2F_VERIFY}></input>
      </form>
    </div>
  );
};
export default AllocareOktaSignUpWidget;
