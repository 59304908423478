import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// components
import TopicsTemplate from '../../templates/TopicsTemplate/TopicsTemplate';
import ITable from '../../containers/Table/Table';
import TopicsNavigation from '../../containers/navigation/TopicsNavigation/TopicsNavigation';
//actions
import topicsAction from '../../../redux/actions/topics';
import appAction from '../../../redux/actions/app';
// types
import { topicsListType } from '../../../types/topics';
import { loadingType } from '../../../types/actions/app';
import { filtersType } from '../../../types/app';
// configs
import { columns } from '../../../config/tables/topics';

const TopicsPage: FC = () => {
  const [name, setName] = useState<string>('');
  const [topicType, setTopicType] = useState<string>('');
  const [topicStatus, setTopicStatus] = useState<boolean | string>('');
  const [searchInput, setSearchInput] = useState<string>('');
  const [isLoadingFinished, setIsLoadingFinished] = useState<boolean>(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const data = {
    skip: 0,
    limit: 12,
    search_value: searchInput,
    topic_type: topicType,
    name,
    is_hidden: topicStatus,
    sort: {
      field_name: 'updated_at',
      value: 'sort_desc',
    },
  };

  // store
  const topics = useSelector(
    (state: { topics: { topics: { items: topicsListType; total_entries: number; skip: number; limit: number } } }) =>
      state.topics.topics,
  );

  const loading = useSelector((state: { topics: { loading: loadingType } }) => state.topics.loading);
  const appLoading = useSelector((state: { app: { loading: loadingType } }) => state.app.loading);
  const filters = useSelector((state: { app: { filters: filtersType } }) => state.app.filters);

  useEffect(() => {
    dispatch(appAction.queryString(filters));
    dispatch(topicsAction.getTopicsListAction(filters));

    return () => {
      dispatch(topicsAction.clearTopicsList());
    };
  }, []);

  // add event listener for each table row
  useEffect(() => {
    if (isLoadingFinished) {
      const tableRows = document.querySelectorAll('.rc-table-row');
      const tableRowsArr = Array.from(tableRows);

      const onClickEvent = (item: Element) => {
        history.push(
          `/topics/${topics.items.find((topic) => topic._id === item.getAttribute('data-row-key'))?._id}/${
            topics.items.find((topic) => topic._id === item.getAttribute('data-row-key'))?.public_dialog_id
          }/${topics.items.find((topic) => topic._id === item.getAttribute('data-row-key'))?.topic_type}`,
        );
      };

      tableRowsArr.map((item) => item.addEventListener('click', () => onClickEvent(item)));

      return () => {
        tableRowsArr.map((item) => item.removeEventListener('click', () => onClickEvent(item)));
      };
    }
  }, [isLoadingFinished]);

  // topics search handler
  const handleTopicsListSearch = (search_value?: string) => {
    data.search_value = search_value ? search_value : '';

    dispatch(appAction.queryString(data));
    dispatch(topicsAction.getTopicsListAction(data));
  };

  // topics type filter handler
  const handleTopicsType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTopicType(e.target.value);
    data.topic_type = e.target.value;

    dispatch(appAction.queryString(data));
    dispatch(topicsAction.getTopicsListAction(data));
  };

  // category filter handler
  const handleCategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setName(e.target.value);
    data.name = e.target.value;

    dispatch(appAction.queryString(data));
    dispatch(topicsAction.getTopicsListAction(data));
  };

  // topics status filter handler
  const handleTopicStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTopicStatus(e.target.value === '1' ? false : e.target.value === '0' ? true : e.target.value);
    data.is_hidden = e.target.value === '1' ? false : e.target.value === '0' ? true : e.target.value;

    dispatch(appAction.queryString(data));
    dispatch(topicsAction.getTopicsListAction(data));
  };

  return (
    <>
      <TopicsTemplate>
        <TopicsNavigation
          handleTopicsType={handleTopicsType}
          handleTopicsListSearch={handleTopicsListSearch}
          handleCategory={handleCategory}
          handleTopicStatus={handleTopicStatus}
          topics={topics}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          loading={loading}
          disabled={appLoading.status}
        />
        <ITable
          columns={columns}
          data={topics}
          loading={loading}
          type="topics"
          action={topicsAction.getTopicsListAction}
          filters={filters}
          customSetIsLoadingFinished={setIsLoadingFinished}
        />
      </TopicsTemplate>
    </>
  );
};

export default TopicsPage;
