import { put, call, delay } from 'redux-saga/effects';
// api
import appAction from '../actions/app';
// actions
import surveyAction from '../actions/survey';
// types
import { getSurveyActionReturnType } from '../../types/actions/survey';
import { apiSurvey } from '../../api/survey';
// helpers
import { handleLoginRedirect } from './helpers';

export function* sagaGetSurvey(action: getSurveyActionReturnType): any {
  try {
    yield delay(1);
    yield put(surveyAction.surveyLoading(false, 0));
    yield put(surveyAction.surveyLoading(true, 90));
    const responseSurvey = yield call(apiSurvey.getSurvey, action.id);
    yield put(surveyAction.surveyLoading(true, 100));
    yield put(surveyAction.getSurvey(responseSurvey.data.items[0]));
    yield delay(1000);
    yield put(surveyAction.surveyLoading(false, 0));
  } catch (e) {
    if (e.response.status === 401) {
      handleLoginRedirect();
    } else {
      yield put(surveyAction.surveyLoading(true, 100));
      yield put(surveyAction.surveyLoading(false, 100));
      yield put(
        appAction.appNotification(
          true,
          {
            header: 'Error',
            message: e.response.data.message || e.response.data.errors[0] || e.response.data.errors.base[0] || 'Error',
          },
          'Error',
        ),
      );
      yield delay(1000);
      yield put(surveyAction.surveyLoading(false, 0));
    }
  }
}
