import { combineReducers } from 'redux';
import app from './app';
import topic from './topic';
import topics from './topics';
import messages from './messages';
import users from './users';
import userProfile from './userProfile';
import reports from './reports';
import report from './report';
import moderatedMessages from './moderatedMessages';
import moderatedMessage from './moderatedMessage';
import survey from './survey';

export default combineReducers({
  app,
  topic,
  topics,
  survey,
  messages,
  users,
  reports,
  report,
  moderatedMessages,
  moderatedMessage,
  userProfile,
});
