import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'semantic-ui-react';
// components
import BanUserModal from '../modals/BanUserModal/BanUserModal';
// types
import { loadingType } from '../../../types/actions/app';
// style
import css from '../../containers/modals/BanUserModal/banUserModal.module.css';

interface BanUserProps {
  id: number;
  status: string;
  name: string;
}

const BanUser: FC<BanUserProps> = ({ id, status, name }: BanUserProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  // store
  const topicStatusLoading = useSelector(
    (state: { topic: { statusLoading: loadingType } }) => state.topic.statusLoading,
  );
  const messagesLoading = useSelector((state: { messages: { loading: loadingType } }) => state.messages.loading);
  const usersLoading = useSelector((state: { users: { loading: loadingType } }) => state.users.loading);
  const topicLoading = useSelector((state: { topic: { loading: loadingType } }) => state.topic.loading);
  const userStatusLoading = useSelector(
    (state: { users: { statusLoading: loadingType } }) => state.users.statusLoading,
  );

  const disabled =
    topicStatusLoading.status ||
    messagesLoading.status ||
    usersLoading.status ||
    topicLoading.status ||
    userStatusLoading.status;

  const handlerModal = () => {
    !disabled && setModalOpen(!modalOpen);
  };

  return (
    <>
      <div className={css.userDotsIcon} />
      <Modal
        size="tiny"
        open={modalOpen}
        onClose={handlerModal}
        className={css.banUserModal}
        closeOnDimmerClick={!disabled}
        trigger={
          <div className={css.userBan}>
            <button onClick={handlerModal} type="button" className={css.banUserBtn} disabled={disabled}>
              {status === 'Banned' ? 'Unban' : 'Ban'} user
            </button>
          </div>
        }
      >
        <div className={css.iconClose} onClick={handlerModal} />
        <BanUserModal
          id={id}
          handlerModal={handlerModal}
          status={status === 'Banned' ? true : false}
          loading={userStatusLoading}
          name={name}
        />
      </Modal>
    </>
  );
};

export default BanUser;
