import { loadingType } from '../../types/actions/app';
import { messagesListResponseType } from '../../types/messages';

const initialState = {
  messages: {
    items: [],
    total_entries: 0,
    skip: 0,
    limit: 100,
  },
  loading: {
    status: false,
    progress: 0,
  },
  sendMessageLoading: {
    status: false,
    progress: 0,
  },
};

const messages = (
  state = initialState,
  action: {
    type: string;
    payload: { messages: messagesListResponseType; loading: loadingType; sendMessageLoading: loadingType };
  },
): { messages: messagesListResponseType; loading: loadingType; sendMessageLoading: loadingType } => {
  switch (action.type) {
    case 'MESSAGES_LIST':
      return Object.assign({}, state, {
        messages: action.payload.messages,
      });
    case 'MESSAGES_LIST_LOADING':
      return Object.assign({}, state, {
        loading: action.payload.loading,
      });
    case 'SEND_MESSAGE_LOADING':
      return Object.assign({}, state, {
        sendMessageLoading: action.payload.sendMessageLoading,
      });
    default:
      return state;
  }
};

export default messages;
