import axios, { AxiosResponse } from 'axios';

import crypto from 'crypto';
import cryptoRandomString from 'crypto-random-string';
import jwt_decode from 'jwt-decode';
const baseUrl = process.env.REACT_APP_API_URL + '/';
// types
import { apiAppType } from '../types/api/api';
import { sessionResponseType } from '../types/app';
import { cidpLoginResponseType, moderatorCheckResponseType, smLoginResponseType } from '../types/responseTypes/api';
import { userType } from '../types/users';
import * as ACT from '../types/allocareTypes';

const allocareBaseUrl = process.env.REACT_APP_ALLOCARE_PATIENT_API;
const allocareMasterBaseUrl = process.env.REACT_APP_ALLOCARE_MASTER_API;
const mapBaseUrl = process.env.REACT_APP_ALLOCARE_MAP_API;
const allocareFhirUrl = process.env.REACT_APP_ALLOCARE_FHIR_API;
const oktaBaseUrl = process.env.REACT_APP_OKTA_BASE_URL;

export const apiApp: apiAppType = {
  decodeToken(token: string): string | boolean {
    try {
      let pId: any = {};

      ({ pId } = jwt_decode(token));

      return pId;
    } catch (err) {
      return false;
    }
  },
  getSmLogin: async (token: string): Promise<{ data: smLoginResponseType }> => {
    const patientId = apiApp.decodeToken(token);
    return await axios({
      url: `${allocareBaseUrl}/Profile?patientId=${patientId}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getAllocareFhirPatient: async (token: string, patientId: string): Promise<{ data: any }> => {
    return await axios({
      url: `${allocareFhirUrl}/Patient/${patientId}`,
      method: 'GET',
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getAllocareEmailExists: async (emailAddress: string): Promise<{ data: any }> => {
    return await axios({
      url: `${allocareBaseUrl}/Profile/OktaUserPresenceValidate?oktaUserEmail=${encodeURIComponent(emailAddress)}`,
      method: 'GET',
    });
  },
  getMapInfo: async (token: string): Promise<{ data: any }> => {
    return await axios({
      url: `${mapBaseUrl}?token=${token}`,
      method: 'GET',
    });
  },
  confirmMap: async (token: string): Promise<{ data: any }> => {
    return await axios({
      url: `${mapBaseUrl}/verify?token=${token}`,
      method: 'POST',
    });
  },
  manuallyLinkAllocareMap: async (token: string, mapId: string, patientId?: string) => {
    let patId = patientId;
    if (!patId) {
      patId = apiApp.decodeToken(token) as string;
    }
    return await axios({
      url: `${allocareBaseUrl}/Profile/ManuallyLinkAllocareMap?patientId=${patId}&mapId=${mapId}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  },
  cidpLogin: async (smLogin: string, token: string): Promise<{ data: cidpLoginResponseType }> => {
    return await axios({
      url: `${allocareBaseUrl}/SMIdentityProvider?SMLogin=${smLogin}&SMSessionData=${token}`,
      method: 'GET',
    });
  },
  moderatorCheck: async (): Promise<{ data: moderatorCheckResponseType }> => {
    return await axios({
      url: `${baseUrl}users/is_moderator`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
    });
  },
  logout: async (): Promise<{ data: userType }> => {
    return await axios({
      url: `${baseUrl}login.json`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
    });
  },
  deleteSession: async (): Promise<AxiosResponse> => {
    return await axios({
      url: `${baseUrl}session.json`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
    });
  },
  createSession: async (login: string, password: string): Promise<{ data: sessionResponseType }> => {
    const nonce = cryptoRandomString({ length: 4, type: 'numeric' });
    const id = process.env.REACT_APP_APPLICATION_ID;
    const key = process.env.REACT_APP_AUTH_KEY;
    const secret_key = process.env.REACT_APP_AUTH_SECRET;
    const time = Math.floor(Date.now() / 1000).toString();
    const query_string = `application_id=${id}&auth_key=${key}&nonce=${nonce}&timestamp=${time}&user[login]=${login}&user[password]=${password}`;

    const signature = (query_string: string) => {
      return crypto
        .createHmac('sha1', secret_key ? secret_key : '')
        .update(query_string)
        .digest('hex');
    };

    const newData = {
      application_id: process.env.REACT_APP_APPLICATION_ID ? process.env.REACT_APP_APPLICATION_ID : '',
      auth_key: process.env.REACT_APP_AUTH_KEY ? process.env.REACT_APP_AUTH_KEY : '',
      nonce: nonce,
      signature: signature(query_string),
      timestamp: time,
      user: {
        login,
        password,
      },
    };

    return await axios({
      url: `${baseUrl}session.json`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: newData,
    });
  },
  prepareFile: async (entries: any): Promise<{ data: any }> => {
    // creating file entity - blob
    return await axios({
      url: `${baseUrl}/blobs.json`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
      data: {
        blob: {
          content_type: entries.type,
          file: entries,
          name: entries.name,
          size: entries.size,
        },
      },
    });
  },
  uploadFile: async (id: any, params: any, entries: any): Promise<{ data: any }> => {
    // uploading file
    const formData = new FormData();
    const newData = params
      .replace('?', '&')
      .split('&')
      .map((item: any) => item.split('='));
    newData.slice(1, newData.length).map((item: any) => formData.append(item[0], item[1]));
    formData.append('file', entries);
    return await axios({
      url: `${baseUrl}blobs/${id}/upload`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
      data: formData,
    });
  },
  confirmFile: async (id: any, size: any): Promise<{ data: any }> => {
    // confirming that file was successfully uploaded
    return axios({
      url: `${baseUrl}blobs/${id}/complete.json`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
      data: {
        blob: {
          size: size,
        },
      },
    });
  },
  oktaUserProfileCreate: async function (payload: ACT.OktaUserProfileCreateReq): Promise<ACT.OktaUserProfileCreateRes> {
    // confirming that file was successfully uploaded
    return await axios({
      url: `${allocareBaseUrl}/Profile/OktaUserProfileCreate`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: payload,
    });
  },
  oktaSigninWithUserDetails: async function (
    headers: any,
    username: string,
    password: string,
  ): Promise<{ stateToken: string }> {
    const response = await (
      await fetch(oktaBaseUrl + '/api/v1/authn', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          username,
          password,
        }),
      })
    ).json();
    return response;
  },
  oktaEnrollMfaFactor: async function (headers: any, payload: ACT.FactorEnrollRequest): Promise<any> {
    return await (
      await fetch(oktaBaseUrl + '/api/v1/authn/factors', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      })
    ).json();
  },
  oktaResendMfaFactor: async function (headers: any, url: string, payload: ACT.FactorEnrollRequest): Promise<any> {
    return await (
      await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      })
    ).json();
  },
  oktaValidateMfaFactor: async function (
    headers: any,
    factorId: string,
    stateToken: string,
    passCode: string,
  ): Promise<any> {
    return (
      await fetch(`${oktaBaseUrl}/api/v1/authn/factors/${factorId}/lifecycle/activate`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          stateToken,
          passCode,
        }),
      })
    ).json();
  },
  registerWordpressUser: async function (token: string, email: string): Promise<any> {
    return await (
      await fetch(`${allocareMasterBaseUrl}/WordPress/RegisterWordPressUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userName: email,
          email,
        }),
      })
    ).json();
  },
  postAcProfile: async function (token: string, payload: ACT.PatientProfileRequest): Promise<any> {
    return await (
      await fetch(`${allocareBaseUrl}/Profile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify([payload]),
      })
    ).json();
  },
  createPatientProfile: async function (token: string, payload: ACT.CreatePatientProfileRequest): Promise<any> {
    return await (
      await fetch(`${allocareBaseUrl}/Profile/CreatePatientProfile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      })
    ).json();
  },
  saveUserEhealthConsentDetails: async function (
    token: string,
    payload: ACT.SaveUserEHealthConsentDetailsReqest,
  ): Promise<any> {
    return await (
      await fetch(`${allocareBaseUrl}/UsereHealthConsent/SaveUserEHealthConsentDetails`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      })
    ).json();
  },
  persistAllocareFhirPatient: async function (
    token: string,
    payload: ACT.PersistAllocareFhirPatientRequst,
  ): Promise<any> {
    return await (
      await fetch(`${allocareBaseUrl}/Fhir/PersistAlloCareFhirPatient`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      })
    ).json();
  },
  updateProfileLanguage: async function (token: string, payload: ACT.UpdateProfileLanguageRequest): Promise<any> {
    return await (
      await fetch(`${allocareBaseUrl}/Profile/UpdateProfileLanguage`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      })
    ).json();
  },
  updateProfile: async function (token: string, payload: ACT.UpdateSFAlloCareUserProfileReq): Promise<any> {
    return await (
      await fetch(`${allocareBaseUrl}/Profile/UpdateSFAlloCareUserProfile`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      })
    ).json();
  },
  postConsentUser: async function (token: string, payload: ACT.PostConsentRequest[]): Promise<any> {
    return await (
      await fetch(`${allocareBaseUrl}/ConsentUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      })
    ).json();
  },
  saveDetailsAndConsents: async function (token: string, payload: ACT.saveDetailsAndConsentsRequest) {
    return await (
      await fetch(`${allocareBaseUrl}/Profile/SaveDetailsAndConsents`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      })
    ).json();
  },
  getConsentMaster: async function (token: string): Promise<ACT.AdditionalConsents[]> {
    // try {
    //   const text = await (
    //     await fetch(`${allocareMasterBaseUrl}/ConsentMaster/`, {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     })
    //   ).text();
    //   console.log(text);
    //   return null;
    // } catch (ex) {
    //   debugger;
    // }
    return [
      {
        consentId: 1,
        consentDescription: 'I agree to allow my CareDx patient care manager to communicate with me through this app.',
        version: '1.0',
        boldText: 'PCM',
        isConsentMandatory: true,
      },
      {
        consentId: 2,
        consentDescription: 'I agree to grant this app access to my Wearable Device Data.',
        version: '1.0',
        boldText: 'Wearable Device Data',
        isConsentMandatory: true,
      },
      {
        consentId: 4,
        consentDescription:
          'I agree to allow CareDx to send me information regarding research studies that may be relevant to me.',
        version: '1.0',
        boldText: 'research studies',
        isConsentMandatory: false,
      },
    ];
  },
  getAcProfile: async function (token: string, patientId: string): Promise<ACT.GetProfileResponse[]> {
    return (await (
      await fetch(`${allocareBaseUrl}/Profile?patientId=${patientId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
    ).json()) as ACT.GetProfileResponse[];
  },
  getEhealthConsents: async function (token: string, patientId: string): Promise<any> {
    return await (
      await fetch(`${allocareBaseUrl}/UsereHealthConsent/GetUserEHealthConsentByPatientId?patientId=${patientId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
    ).json();
  },
  oktaUserProfileUpdate: async function (
    token: string,
    payload: ACT.OktaUserProfileUpdateReq,
    oktaEmail: string,
  ): Promise<any> {
    // confirming that file was successfully uploaded
    return await axios({
      url: `${allocareBaseUrl}/Profile/OktaUserProfileUpdate?oktaEmail=${encodeURIComponent(oktaEmail)}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: payload,
    });
  },
};
