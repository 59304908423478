import { loadingType } from '../../types/actions/app';
import { topicType } from '../../types/topics';

const initialState = {
  topic: {
    created_at: 0,
    created_time: '',
    last_message: '',
    last_message_date_sent: 0,
    last_message_id: '',
    last_message_user_id: 0,
    name: '',
    description: '',
    participants: [],
    photo: '',
    topic_type: 0,
    unread_messages_count: 0,
    updated_at: 0,
    updated_time: '',
    user_id: 0,
    xmpp_room_jid: 0,
    _id: '',
    public_dialog_id: '',
    is_hidden: false,
    survey: {
      name: '',
      answers: [],
    },
  },
  loading: {
    status: false,
    progress: 0,
  },
  statusLoading: {
    status: false,
    progress: 0,
  },
};

const topic = (
  state = initialState,
  action: {
    type: string;
    payload: {
      topic: topicType;
      loading: loadingType;
      statusLoading: loadingType;
    };
  },
): {
  topic: topicType;
  loading: loadingType;
  statusLoading: loadingType;
} => {
  switch (action.type) {
    case 'TOPIC':
      return Object.assign({}, state, {
        topic: action.payload.topic,
      });
    case 'TOPIC_LOADING':
      return Object.assign({}, state, {
        loading: action.payload.loading,
      });
    case 'TOPIC_STATUS_LOADING':
      return Object.assign({}, state, {
        statusLoading: action.payload.statusLoading,
      });
    default:
      return state;
  }
};

export default topic;
