import React, { FC } from 'react';
import LoadingBar from 'react-top-loading-bar';
// style
import css from './loadingBar.module.css';

interface ILoadingBarProps {
  loadingBarProgress: number;
  onLoaderFinished: () => void;
}

const ILoadingBar: FC<ILoadingBarProps> = ({ loadingBarProgress, onLoaderFinished }: ILoadingBarProps) => {
  return (
    <LoadingBar
      className={css.loadingBar}
      progress={loadingBarProgress}
      height={6}
      color="#0080FF"
      onLoaderFinished={onLoaderFinished}
    />
  );
};

export default ILoadingBar;
