import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_URL + '/';
// types
import { apiUsersType } from '../types/api/users';
import { userType } from '../types/users';

export const apiUsers: apiUsersType = {
  getUser: async (user_id: number): Promise<{ data: { user: userType } }> => {
    return await axios({
      url: `${baseUrl}users/${user_id}.json`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
    });
  },
  getBannedUsers: async (): Promise<{ data: Array<string> }> => {
    return await axios({
      url: `${baseUrl}data/BannedUsers/6178059d5cff370019326362.json`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
    });
  },
  changeUserStatus: async (list: Array<string>): Promise<{ data: Array<string> }> => {
    return await axios({
      url: `${baseUrl}data/BannedUsers/6178059d5cff370019326362.json`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
      data: {
        list,
      },
    });
  },
};
