import { prepareFileType } from './../../types/actions/app';
import { put, call, delay } from 'redux-saga/effects';
// api
import { apiApp } from '../../api/app';
import { emailLandingReturnType, loginReturnType, logoutReturnType } from '../../types/actions/app';
// action
import appAction from '../actions/app';
// helpers
import { handleLoginRedirect } from './helpers';

export function* sagaAppLogin(action: loginReturnType): any {
  try {
    yield put(appAction.appNotificationClear());
    yield delay(1);
    const smLoginResponse = yield call(apiApp.getSmLogin, action.token);
    const smLogin = smLoginResponse.data[0].smLogin;
    const cidpLoginResponse = yield call(apiApp.cidpLogin, smLogin, action.token);
    const cidpLogin = cidpLoginResponse.data.user.login;
    const responseLogin = yield call(apiApp.createSession, cidpLogin, action.token);
    yield call(appAction.createToken, responseLogin.data.session.token);
    const isModeratorResponse = yield call(apiApp.moderatorCheck);
    const isModerator = isModeratorResponse.data.is_moderator;
    if (!isModerator) {
      action.loginRedirect();
      yield put(
        appAction.appNotification(
          true,
          {
            header: 'Error',
            message: 'You do not have moderator permissions. Please, contact your administrator.',
          },
          'Error',
        ),
      );
    } else {
      yield delay(500);
      action.callback();
    }
  } catch (e) {
    action.loginRedirect();
    yield put(
      appAction.appNotification(
        true,
        {
          header: 'Error',
          message:
            (e.response &&
              (e.response.data.message ||
                (e.response.data.errors && e.response.data.errors[0]) ||
                (e.response.data.errors && e.response.data.errors.base && e.response.data.errors.base[0]) ||
                (e.response.status === 401 &&
                  'You do not have moderator permissions. Please, contact your administrator.'))) ||
            'Internal server error',
        },
        'Error',
      ),
    );
  }
}

export function* sagaEmailLanding(action: emailLandingReturnType): any {
  //yield console.log(action);
}

export function* sagaAppLogout(action: logoutReturnType): any {
  try {
    yield put(appAction.appNotificationClear());
    yield delay(1);
    yield put(appAction.appLoading(false, 0));
    yield put(appAction.appLoading(true, 70));
    yield call(apiApp.logout);
    yield call(apiApp.deleteSession);
    yield call(appAction.deleteToken);
    yield put(appAction.appLoading(true, 90));
    yield put(appAction.appLoading(true, 100));
    yield delay(500);
    yield put(appAction.appLoading(false, 0));
    action.callback();
  } catch (e) {
    if (e.response.status === 401) {
      handleLoginRedirect();
    } else {
      yield put(appAction.appLoading(true, 100));
      yield delay(1000);
      yield put(appAction.appLoading(false, 0));
      yield put(
        appAction.appNotification(
          true,
          {
            header: 'Error',
            message: e.response.data.message || e.response.data.errors[0] || e.response.data.errors.base[0],
          },
          'Error',
        ),
      );
    }
  }
}

export function* sagaUploadFile(action: prepareFileType): any {
  try {
    const responcePrepareFile = yield call(apiApp.prepareFile, action.payload.entries, action.payload.callback);
    yield call(
      apiApp.uploadFile,
      responcePrepareFile.data.blob.blob_object_access.blob_id,
      responcePrepareFile.data.blob.blob_object_access.params,
      action.payload.entries,
    );
    yield call(
      apiApp.confirmFile,
      responcePrepareFile.data.blob.blob_object_access.blob_id,
      action.payload.entries.size,
    );
    action.payload.callback(responcePrepareFile.data.blob.blob_object_access.blob_id);
  } catch (e) {
    console.log(e);
  }
}
