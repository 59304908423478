import { loadingType } from '../../types/actions/app';
import { moderatedMessageType } from '../../types/moderatedMessages';

const initialState = {
  moderatedMessage: {
    message: '',
    sender_id: '',
    recipient_id: '',
    attachments: '',
    custom_parameters: '',
    date_sent: '',
  },
  loading: {
    status: false,
    progress: 0,
  },
};

const moderatedMessage = (
  state = initialState,
  action: {
    type: string;
    payload: {
      moderatedMessage: moderatedMessageType;
      loading: loadingType;
    };
  },
): {
  moderatedMessage: moderatedMessageType;
  loading: loadingType;
} => {
  switch (action.type) {
    case 'MODERATED_MESSAGE':
      return Object.assign({}, state, {
        moderatedMessage: action.payload.moderatedMessage,
      });
    case 'MODERATED_MESSAGE_LOADING':
      return Object.assign({}, state, {
        loading: action.payload.loading,
      });

    default:
      return state;
  }
};

export default moderatedMessage;
