import React, { FC } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import { oktaAuthConfig, oktaSignInConfig } from './config/okta';
// components
import AuthPage from './components/pages/authPage/AuthPage';
import AMEmailPage from './components/pages/allocareMap/allocareMapEmailPage/AMEmailPage';
import TopicsPage from './components/pages/topicsPage/TopicsPage';
import TopicPage from './components/pages/topicPage/TopicPage';
import ProfilePage from './components/pages/profilePage/ProfilePage';
import CreateTopicPage from './components/pages/createTopicPage/CreateTopicPage';
// style
import './App.css';
import 'semantic-ui-css/semantic.min.css';

const App: FC = () => {
  const oktaAuth = new OktaAuth(oktaAuthConfig);
  const history = useHistory();

  const customAuthHandler = () => {
    history.push('/login');
  };

  const restoreOriginalUri = async () => {
    history.push('/topics');
  };

  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <Route exact path="/login" render={() => <AuthPage config={oktaSignInConfig} />} />
        <Route
          exact
          path={['/emailauth/map/:mapid', '/emailauth', '/emailauth/map']}
          render={(a) => <AMEmailPage urlParams={a.match.params} config={oktaSignInConfig} />}
        />
        <Route exact path="/login/callback" component={LoginCallback} />
        <SecureRoute exact path={['/topics', '/']} component={TopicsPage} />
        <SecureRoute exact path="/topics/:id/:name/:type" component={TopicPage} />
        <SecureRoute exact path="/new_topic/:id" component={CreateTopicPage} />
        <SecureRoute exact path="/profile" component={ProfilePage} />
      </Switch>
    </Security>
  );
};

export default App;
