import { put, call, delay } from 'redux-saga/effects';
// api
import { apiTopics } from '../../api/topics';
// actions
import appAction from '../actions/app';
import topicsAction from '../actions/topics';
// types
import { getTopicsListActionReturnType } from '../../types/actions/topics';
// helpers
import { handleLoginRedirect } from './helpers';

export function* sagaGetTopics(action: getTopicsListActionReturnType): any {
  try {
    yield delay(1);
    yield put(topicsAction.topicsListLoading(false, 0));
    yield put(topicsAction.clearTopicsList());
    yield put(topicsAction.topicsListLoading(true, 90));
    const totalEntries = yield call(apiTopics.getTopics, action.data, true);
    const responseTopics = yield call(apiTopics.getTopics, action.data);
    yield put(topicsAction.topicsListLoading(true, 100));
    yield put(topicsAction.getTopicsList({ ...responseTopics.data, total_entries: totalEntries.data.items.count }));
    yield delay(1000);
    yield put(topicsAction.topicsListLoading(false, 0));
  } catch (e: any) {
    if (e.response.status === 401) {
      handleLoginRedirect();
    } else {
      yield put(topicsAction.topicsListLoading(true, 100));
      yield put(topicsAction.topicsListLoading(false, 100));
      yield put(
        appAction.appNotification(
          true,
          {
            header: 'Error',
            message: e.response.data.message || e.response.data.errors[0] || e.response.data.errors.base[0] || 'Error',
          },
          'Error',
        ),
      );
    }
  }
}
