// types
import {
  reportsActionType,
  getReportsListActionReturnType,
  getReportsListReturnType,
  clearReportsListReturnType,
  reportsListLoadingReturnType,
} from '../../types/actions/reports';
import { reportsListResponseType } from '../../types/reports';
// consts
import * as types from '../constants/actionConstants';

const reportsAction: reportsActionType = {
  getReportsListAction(data: {
    skip: number;
    limit: number;
    filter: { type: string; category: string };
  }): getReportsListActionReturnType {
    return {
      type: types.GET_REPORTS_LIST,
      data,
    };
  },
  getReportsList(reports: reportsListResponseType): getReportsListReturnType {
    return {
      type: types.REPORTS_LIST,
      payload: { reports },
    };
  },
  clearReportsList(): clearReportsListReturnType {
    return {
      type: types.REPORTS_LIST,
      payload: { reports: { items: [], total_entries: 0, skip: 0, limit: 12 } },
    };
  },
  reportsListLoading(status: boolean, progress: number): reportsListLoadingReturnType {
    const loading = {
      status: status,
      progress: progress,
    };
    return {
      type: types.REPORTS_LIST_LOADING,
      payload: {
        loading: { ...loading },
      },
    };
  },
};

export default reportsAction;
