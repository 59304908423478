import React, { useState, FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// components
import Header from '../../containers/Header/Header';
import MessagesContainer from '../../containers/MessagesContainer/MessagesContainer';
import ParticipantsContainer from '../../containers/ParticipantsContainer/ParticipantsContainer';
import SurveyContainer from '../../containers/SurveyContainer/SurveyContainer';
import LoadingBar from '../../containers/LoadingBar/LoadingBar';
import EditTopicForm from '../../containers/EditTopicForm/EditTopicForm';
import Notification from '../../containers/Notification/Notification';
import ChangeTopicStatus from '../../containers/ChangeTopicStatus/ChangeTopicStatus';
// actions
import topicAction from '../../../redux/actions/topic';
import usersAction from '../../../redux/actions/users';
import surveyAction from '../../../redux/actions/survey';
// types
import { paramsType } from '../../../types/app';
import { loadingType } from '../../../types/actions/app';
import { topicType } from '../../../types/topics';
import { usersListType } from '../../../types/users';
import { surveyType } from '../../../types/survey';
// style
import css from './topic.module.css';
//helpers
import { handleGoBack, topicTypeHandler } from '../../../config/helpers';

interface TopicPageProps {
  match: paramsType;
}

const TopicPage: FC<TopicPageProps> = ({ match }: TopicPageProps) => {
  const [loadingBarProgress, updateProgress] = useState<number>(0);
  const [isReset, setIsReset] = useState<boolean>(false);

  const dispatch = useDispatch();

  // store
  const users = useSelector((state: { users: { list: usersListType } }) => state.users.list);
  const topic = useSelector((state: { topic: { topic: topicType } }) => state.topic.topic);
  const survey = useSelector((state: { survey: { survey: surveyType } }) => state.survey.survey);
  const topicStatusLoading = useSelector(
    (state: { topic: { statusLoading: loadingType } }) => state.topic.statusLoading,
  );
  const messagesLoading = useSelector((state: { messages: { loading: loadingType } }) => state.messages.loading);
  const usersLoading = useSelector((state: { users: { loading: loadingType } }) => state.users.loading);
  const topicLoading = useSelector((state: { topic: { loading: loadingType } }) => state.topic.loading);
  const surveyLoading = useSelector((state: { survey: { loading: loadingType } }) => state.survey.loading);
  const userStatusLoading = useSelector(
    (state: { users: { statusLoading: loadingType } }) => state.users.statusLoading,
  );

  const customTopicId = match.params.id;
  const publicTopicId = match.params.name;
  const topicType = match.params.type;
  const disabled =
    topicStatusLoading.status ||
    messagesLoading.status ||
    usersLoading.status ||
    topicLoading.status ||
    userStatusLoading.status ||
    surveyLoading.status;

  useEffect(() => {
    updateProgress(0);
    dispatch(topicAction.getTopicAction(customTopicId));
    topicType === '2' && dispatch(surveyAction.getSurveyAction(customTopicId));

    return () => {
      dispatch(usersAction.clearUsersList());
      dispatch(topicAction.clearTopic());
    };
  }, []);

  return (
    <div className={css.topicPage}>
      <Notification />
      <Header logout />
      <div className={css.topicPageLoading}>
        <LoadingBar loadingBarProgress={loadingBarProgress} onLoaderFinished={() => updateProgress(0)} />
      </div>
      <div className={css.topicPageTop}>
        <div className={css.topicPageNav}>
          <Link to={() => handleGoBack(match.path)}>
            <div className={css.topicPageArrowBtn} />
          </Link>
          <span className={css.topicPageGoBack}>{topicTypeHandler(Number(topicType))}</span>
        </div>
        <div>
          {/* <button
            disabled={topicStatusLoading.status || topicLoading.status}
            className={css.topicPageTopBtn}
            type="submit"
            form="edit-topic-form"
          >
            Save changes
          </button> */}
          {!topicLoading.status && (
            <ChangeTopicStatus disabled={disabled} isHidden={topic.is_hidden} topicId={customTopicId} />
          )}
          {/* <button
            disabled={topicStatusLoading.status || topicLoading.status}
            className={css.topicPageTopBtn}
            onClick={() => setIsReset(true)}
          >
            Cancel
          </button> */}
        </div>
      </div>
      <div className={css.topicPageContentWrapper}>
        <EditTopicForm
          topic={topic}
          loading={topicLoading}
          isReset={isReset}
          setIsReset={setIsReset}
          className={css.topicPageContentItem}
        />
        {topicType === '2' && (
          <SurveyContainer className={css.topicPageContentItem} survey={survey} loading={surveyLoading} />
        )}
        <ParticipantsContainer
          users={users}
          participants={topic.participants}
          mainLoading={topicStatusLoading.status}
          loading={usersLoading}
          className={css.topicPageContentItem}
        />
        <MessagesContainer
          customTopicId={customTopicId}
          topicId={publicTopicId}
          mainLoading={topicStatusLoading.status}
          messagesLoading={messagesLoading}
          className={css.topicPageContentItem}
        />
      </div>
    </div>
  );
};

export default TopicPage;
