import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_URL + '/';
// types
import { apiTopicsType } from '../types/api/topics';
import { topicsListResponseType } from '../types/topics';
import { filtersType } from '../types/app';
// mapping
import { mapping } from '../config/mapping';

export const apiTopics: apiTopicsType = {
  getTopics: async (data: filtersType, isTotal?: boolean): Promise<{ data: topicsListResponseType }> => {
    const params = mapping.createFiltersQuery(data);
    return await axios({
      url: `${baseUrl}data/ChatTopic.json${params}${isTotal ? '&count=1' : ''}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'qb-token': `${localStorage.getItem('token')}`,
      },
    });
  },
};
