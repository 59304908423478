import React, { useState, useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
// components
import LoadingBar from '../../LoadingBar/LoadingBar';
// actions
import topicAction from '../../../../redux/actions/topic';
// types
import { loadingType } from '../../../../types/actions/app';
//style
import css from './changeTopicStatusModal.module.css';

interface ChangeTopicStatusModalProps {
  handlerModal: () => void;
  topicId: string;
  isHidden: boolean;
}

const ChangeTopicStatusModal: FC<ChangeTopicStatusModalProps> = ({
  handlerModal,
  topicId,
  isHidden,
}: ChangeTopicStatusModalProps) => {
  const [loadingBarProgress, updateProgress] = useState<number>(0);

  const dispatch = useDispatch();
  const history = useHistory();

  const callback = () => {
    history.push('/');
  };

  //store
  const topicStatusLoading = useSelector(
    (state: { topic: { statusLoading: loadingType } }) => state.topic.statusLoading,
  );

  useEffect(() => {
    updateProgress(topicStatusLoading.progress);
  }, [topicStatusLoading]);

  const handleTopicStatus = (topicId: string) => {
    dispatch(topicAction.topicStatus(topicId, callback, !isHidden));
  };

  return (
    <div className={css.changeTopicStatusModalWrapper}>
      <LoadingBar
        loadingBarProgress={loadingBarProgress}
        onLoaderFinished={() => {
          updateProgress(0);
        }}
      />
      <div className={css.changeTopicStatusModalInner}>
        <h3>Are you sure you want to {`${isHidden ? 'restore' : 'delete'}`} the topic?</h3>
        <div>
          <button
            disabled={topicStatusLoading.status}
            onClick={() => handleTopicStatus(topicId)}
            className={css.topicStatusChangeBtn}
          >
            {isHidden ? 'Restore' : 'Delete'}
          </button>
          <button disabled={topicStatusLoading.status} onClick={handlerModal} className={css.topicStatusChangeBtn}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangeTopicStatusModal;
