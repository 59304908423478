import { loadingType } from '../../types/actions/app';
import { moderatedMessagesListResponseType } from '../../types/moderatedMessages';

const initialState = {
  moderatedMessages: {
    items: [],
    total_entries: 0,
    skip: 0,
    limit: 12,
  },
  loading: {
    status: false,
    progress: 0,
  },
};

const moderatedMessages = (
  state = initialState,
  action: {
    type: string;
    payload: {
      moderatedMessages: moderatedMessagesListResponseType;
      loading: loadingType;
    };
  },
): {
  moderatedMessages: moderatedMessagesListResponseType;
  loading: loadingType;
} => {
  switch (action.type) {
    case 'MODERATED_MESSAGES_LIST':
      return Object.assign({}, state, {
        moderatedMessages: action.payload.moderatedMessages,
      });
    case 'MODERATED_MESSAGES_LIST_LOADING':
      return Object.assign({}, state, {
        loading: action.payload.loading,
      });
    default:
      return state;
  }
};

export default moderatedMessages;
