import React, { FC } from 'react';
import { Link } from 'react-router-dom';
//components
import SearchField from '../../SearchField/SearchField';
// types
import { loadingType } from '../../../../types/actions/app';
import { topicsListResponseType } from '../../../../types/topics';
// style
import css from './topicsNavigation.module.css';
// helpers
import { options } from '../../../../config/topicNames';

interface TopicsNavigationProps {
  handleTopicsType: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleCategory: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleTopicStatus: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleTopicsListSearch: (search_value?: string) => void;
  topics: topicsListResponseType;
  searchInput: string;
  setSearchInput: (arg0: string) => void;
  loading: loadingType;
  disabled: boolean;
}

const TopicsNavigation: FC<TopicsNavigationProps> = ({
  handleTopicsType,
  handleCategory,
  handleTopicStatus,
  handleTopicsListSearch,
  topics,
  searchInput,
  setSearchInput,
  loading,
  disabled,
}: TopicsNavigationProps) => {
  return (
    <div className={css.navigationWrapper}>
      <h2 className={css.navigationHeader}>Topics</h2>
      <div className={css.navigationFormWrapper}>
        {/* <Link to="/reports">
          <div className={css.navigationBtn}>Reports</div>
        </Link>
        <Link to="/messages">
          <div className={css.navigationBtn}>Moderation</div>
        </Link> */}
        <div className={css.navigationFormWrapperItem}>
          <label htmlFor="topics">Filter by type: </label>
          <select
            onChange={handleTopicsType}
            id="topics"
            disabled={loading.status || disabled}
            className={css.navigationSelect}
          >
            <option value={''}>All</option>
            <option value={'2'}>Survey</option>
            <option value={'1'}>Chat</option>
          </select>
        </div>
        <div className={css.navigationFormWrapperItem}>
          <label htmlFor="topics">Filter by category: </label>
          <select
            onChange={handleCategory}
            id="topics"
            disabled={loading.status || disabled}
            className={css.navigationSelect}
          >
            <option value={''}>All</option>
            {options.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className={css.navigationFormWrapperItem}>
          <label htmlFor="topics">Filter by status: </label>
          <select
            onChange={handleTopicStatus}
            id="topics"
            disabled={loading.status || disabled}
            className={css.navigationSelect}
          >
            <option value={''}>All</option>
            <option value={'1'}>Active</option>
            <option value={'0'}>Hidden</option>
          </select>
        </div>
        <div className={css.navigationFormWrapperItem}>
          <SearchField
            loading={loading.status}
            searchCallback={handleTopicsListSearch}
            searchedList={topics.items}
            placeholder="by Topic"
            customInput={searchInput}
            customSetInput={setSearchInput}
            disabled={disabled}
            id="topicListSearch"
          />
        </div>
        <Link to="/new_topic/1">
          <div className={css.navigationBtn}>
            <div className={css.navigationPlusIcon} />
            <span>Chat</span>
          </div>
        </Link>
        <Link to="/new_topic/2">
          <div className={css.navigationBtn}>
            <div className={css.navigationPlusIcon} />
            <span>Survey</span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default TopicsNavigation;
