export const APP_NOTIFICATION = 'APP_NOTIFICATION';
export const APP_LOGIN = 'APP_LOGIN';
export const APP_EMAIL_LANDING = 'APP_EMAIL_LANDING';
export const APP_LOGOUT = 'APP_LOGOUT';
export const APP_LOADING = 'APP_LOADING';
export const GET_TOPICS_LIST = 'GET_TOPICS_LIST';
export const TOPICS_LIST = 'TOPICS_LIST';
export const GET_TOPIC = 'GET_TOPIC';
export const TOPIC = 'TOPIC';
export const TOPIC_EDIT = 'TOPIC_EDIT';
export const TOPIC_CREATE = 'TOPIC_CREATE';
export const TOPIC_STATUS = 'TOPIC_STATUS';
export const TOPIC_LOADING = 'TOPIC_LOADING';
export const TOPICS_LIST_LOADING = 'TOPICS_LIST_LOADING';
export const TOPIC_STATUS_LOADING = 'TOPIC_STATUS_LOADING';
export const TOPICS_NOTIFICATION = 'TOPICS_NOTIFICATION';
export const GET_SURVEY = 'GET_SURVEY';
export const SURVEY = 'SURVEY';
export const SURVEY_LOADING = 'SURVEY_LOADING';
export const GET_MESSAGES_LIST = 'GET_MESSAGES_LIST';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const MESSAGES_LIST = 'MESSAGES_LIST';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_LOADING = 'SEND_MESSAGE_LOADING';
export const MESSAGES_LIST_LOADING = 'MESSAGES_LIST_LOADING';
export const MESSAGES_NOTIFICATION = 'MESSAGES_NOTIFICATION';
export const GET_USERS_LIST = 'GET_USERS_LIST';
export const SEARCH_USERS = 'SEARCH_USERS';
export const USERS_LIST = 'USERS_LIST';
export const USER_STATUS = 'USER_STATUS';
export const USERS_SEARCH_RESULT = 'USERS_SEARCH_RESULT';
export const USERS_LOADING = 'USERS_LOADING';
export const USER_STATUS_LOADING = 'USER_STATUS_LOADING';
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const GET_USER_ACTIVITY = 'GET_USER_ACTIVITY';
export const USER_ACTIVITY = 'USER_ACTIVITY';
export const USER_LOADING = 'USER_LOADING';
export const GET_REPORTS_LIST = 'GET_REPORTS_LIST';
export const REPORTS_LIST = 'REPORTS_LIST';
export const REPORTS_LIST_LOADING = 'REPORTS_LIST_LOADING';
export const GET_REPORT = 'GET_REPORT';
export const REPORT = 'REPORT';
export const REPORT_LOADING = 'REPORT_LOADING';
export const FILE_PREPARE = 'FILE_PREPARE';
export const FILE_UPLOAD = 'FILE_UPLOAD';
export const FILE_CONFIRM = 'FILE_CONFIRM';
export const GET_MODERATED_MESSAGES_LIST = 'GET_MODERATED_MESSAGES_LIST';
export const MODERATED_MESSAGES_LIST = 'MODERATED_MESSAGES_LIST';
export const MODERATED_MESSAGES_LIST_LOADING = 'MODERATED_MESSAGES_LIST_LOADING';
export const GET_MODERATED_MESSAGE = 'GET_MODERATED_MESSAGE';
export const MODERATED_MESSAGE = 'MODERATED_MESSAGE';
export const MODERATED_MESSAGE_LOADING = 'MODERATED_MESSAGE_LOADING';
export const GET_CURRENT_AVATAR = 'GET_CURRENT_AVATAR';
