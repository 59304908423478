import { takeEvery } from 'redux-saga/effects';
// sagas
import { sagaAppLogin, sagaEmailLanding, sagaAppLogout, sagaUploadFile } from './app';
import { sagaGetTopics } from './topics';
import { sagaCreateTopic, sagaChangeTopicStatus, /*sagaEditTopic,*/ sagaGetTopic } from './topic';
import { sagaDeleteMessage, sagaGetMessages, sagaSendMessage } from './messages';
import { sagaChangeUserStatus, sagaGetUsers, sagaSearchUsers } from './users';
import { sagaGetReports } from './reports';
import { sagaGetReport } from './report';
import { sagaGetModeratedMessages } from './moderatedMessages';
import { sagaGetModeratedMessage } from './moderatedMessage';
import { sagaGetUserActivity, sagaGetCurrentAvatar, sagaGetUser, sagaUpdateUser } from './userProfile';
import { sagaGetSurvey } from './survey';

export function* sagaWatcher(): Generator {
  yield takeEvery('APP_LOGIN', sagaAppLogin);
  yield takeEvery('APP_EMAIL_LANDING', sagaEmailLanding);
  yield takeEvery('APP_LOGOUT', sagaAppLogout);
  yield takeEvery('GET_TOPICS_LIST', sagaGetTopics);
  yield takeEvery('GET_TOPIC', sagaGetTopic);
  // yield takeEvery('TOPIC_EDIT', sagaEditTopic);
  yield takeEvery('TOPIC_STATUS', sagaChangeTopicStatus);
  yield takeEvery('TOPIC_CREATE', sagaCreateTopic);
  yield takeEvery('GET_SURVEY', sagaGetSurvey);
  yield takeEvery('GET_MESSAGES_LIST', sagaGetMessages);
  yield takeEvery('SEND_MESSAGE', sagaSendMessage);
  yield takeEvery('DELETE_MESSAGE', sagaDeleteMessage);
  yield takeEvery('GET_USERS_LIST', sagaGetUsers);
  yield takeEvery('SEARCH_USERS', sagaSearchUsers);
  yield takeEvery('USER_STATUS', sagaChangeUserStatus);
  yield takeEvery('GET_USER', sagaGetUser);
  yield takeEvery('UPDATE_USER', sagaUpdateUser);
  yield takeEvery('GET_USER_ACTIVITY', sagaGetUserActivity);
  yield takeEvery('GET_REPORTS_LIST', sagaGetReports);
  yield takeEvery('GET_REPORT', sagaGetReport);
  yield takeEvery('GET_MODERATED_MESSAGES_LIST', sagaGetModeratedMessages);
  yield takeEvery('GET_MODERATED_MESSAGE', sagaGetModeratedMessage);
  yield takeEvery('FILE_PREPARE', sagaUploadFile);
  yield takeEvery('GET_CURRENT_AVATAR', sagaGetCurrentAvatar);
}
