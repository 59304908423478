import { loadingType } from '../../types/actions/app';
import { reportsListResponseType } from '../../types/reports';

const initialState = {
  reports: {
    items: [],
    total_entries: 0,
    skip: 0,
    limit: 12,
  },
  loading: {
    status: false,
    progress: 0,
  },
};

const reports = (
  state = initialState,
  action: {
    type: string;
    payload: {
      reports: reportsListResponseType;
      loading: loadingType;
    };
  },
): {
  reports: reportsListResponseType;
  loading: loadingType;
} => {
  switch (action.type) {
    case 'REPORTS_LIST':
      return Object.assign({}, state, {
        reports: action.payload.reports,
      });
    case 'REPORTS_LIST_LOADING':
      return Object.assign({}, state, {
        loading: action.payload.loading,
      });
    default:
      return state;
  }
};

export default reports;
