import React, { FC, useEffect, useState } from 'react';
import Header from '../../containers/Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import userProfileAction from '../../../redux/actions/userProfile';
import appAction from '../../../redux/actions/app';
import css from './profile.module.css';
const baseUrl = process.env.REACT_APP_API_URL + '/';

const ProfilePage: FC = () => {
  const dispatch = useDispatch();
  const data = 'QBUser_1'; // current user's login to edit
  const user = useSelector((state: { users: { user: any } }) => state.users.user);

  useEffect(() => {
    dispatch(userProfileAction.getCurrentUser(data));
  }, []);

  const [imagePreview, setImagePreview] = useState({ file: '' });
  const [avatar, setAvatar] = useState<number>();
  const [currentAvatar, setCurrentAvatar] = useState<number>();
  const [entries, setEntries] = useState({
    name: '',
  });

  useEffect(() => {
    setEntries({
      name: user.full_name || entries.name,
    });
    const callback = (uid: any) => {
      setCurrentAvatar(uid);
    };
    if (user.blob_id != undefined) {
      dispatch(userProfileAction.getCurrentAvatar(user.blob_id, callback));
    }
  }, [user]);

  const handleForm = (e: any) => {
    const { name, value } = e.target;
    setEntries({
      ...entries,
      [name]: value,
    });
  };

  const userData = {
    user: {
      full_name: entries.name,
      id: user.id,
      blob_id: avatar,
    },
  };

  const updateUser = (e: any) => {
    e.preventDefault();
    dispatch(userProfileAction.updateUser(userData));
  };

  const uploadFile = (e: any) => {
    const entries = e.target.files[0];
    setImagePreview({
      file: URL.createObjectURL(entries),
    });
    const callback = (blob_id: any) => {
      setAvatar(blob_id);
    };
    dispatch(appAction.prepareFile(entries, callback));
  };

  return (
    <>
      <Header logout />
      <section className={css.profile}>
        <h2>My profile</h2>
        <div className={css.wrapper}>
          <div className={css.half}>
            <ul className={css.profileInfo}>
              <li>
                {currentAvatar ? (
                  <img
                    className={css.profileImg}
                    src={`${baseUrl}blobs/${currentAvatar}?token=${localStorage.getItem('token')}`}
                    alt="current_avatar"
                  />
                ) : (
                  <div className={css.blankImgBox}>
                    <span>no avatar set yet</span>
                  </div>
                )}
              </li>
              <li>
                <span>id: {user.id}</span>
              </li>
              <li>
                <span>name: {user.full_name}</span>
              </li>
              <li>
                <span>email: {user.email}</span>
              </li>
              <li>
                <span>login: {user.login}</span>
              </li>
            </ul>
          </div>
          <div className={css.half}>
            <form className={css.profileInfo} onSubmit={(e) => updateUser(e)}>
              <label className={css.imageLabel}>
                {imagePreview.file ? (
                  <img className={css.profileImg} src={imagePreview.file} alt="profile_avatar" />
                ) : (
                  <div className={css.blankImgBox}>
                    <span>choose new avatar</span>
                  </div>
                )}
                <input id="profile-avatar" name="fileParams" type="file" onChange={(e) => uploadFile(e)} />
              </label>
              <label htmlFor="profile-name">
                Change name:{' '}
                <input id="profile-name" type="text" name="name" value={entries.name} onChange={handleForm} />
              </label>
              <button type="submit" disabled={user.full_name != entries.name || imagePreview.file ? false : true}>
                Save changes
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfilePage;
