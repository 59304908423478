import queryString from 'query-string';
// types
import { filtersType } from '../types/app';
import { tableDataType } from '../types/app';
import { customSurveyType } from '../types/survey';
import { customTopicType } from '../types/topics';
// helpers
import { handleDateFormat, topicTypeHandler } from './helpers';

export const mapping = {
  buildTableByType(data: tableDataType, type: string): any {
    switch (type) {
      case 'topics':
        return data.items.map((item: any) => {
          return {
            topic_name: item.name,
            author: item.user_name ? item.user_name : '',
            participants: (item.participants && item.participants.length) || 0,
            comments: item.comments,
            description: item.description ? item.description : '',
            type: item.topic_type ? topicTypeHandler(item.topic_type) : '',
            likes: item.likes ? item.likes.length : 0,
            is_hidden: item.is_hidden ? 'Hidden' : 'Active',
            created_at: item.created_at ? handleDateFormat(item.created_at) : '',
            updated_at: item.updated_at ? handleDateFormat(item.updated_at) : '',
            key: item._id,
          };
        });
      case 'participants':
        return data.items.map((item: any) => {
          return {
            id: item.id,
            email: item.email ? item.email : '',
            name: item.full_name ? item.full_name : 'user',
            status: item.status ? 'Banned' : '',
            key: item.id,
          };
        });
      case 'reports':
        return data.items.map((item: any) => {
          return { ...item, key: item.id };
        });
      case 'moderation':
        return data.items.map((item: any) => {
          return { ...item, key: item.message };
        });

      default:
        break;
    }
  },
  // make this method more reusable later for all api requests params
  createFiltersQuery(data: filtersType): string {
    const newData: any = {
      limit: data.limit,
      skip: data.skip,
      description: data.search_value,
      topic_type: data.topic_type,
      name: data.name,
      is_hidden: data.is_hidden,
      sort_desc: data.sort?.value === 'sort_desc' ? data.sort?.field_name : '',
      sort_asc: data.sort?.value === 'sort_asc' ? data.sort?.field_name : '',
    };

    Object.keys(newData).map((key) => {
      return newData[key] === '' ? delete newData[key] : newData[key];
    });

    const result = `?${queryString.stringify(newData)}`;

    return result;
  },
  buildCustomTopic(
    name: string,
    description: string,
    patient_id: string,
    topic_type: string,
    public_dialog_id: string,
    user_id: string,
    user_name: string,
  ): customTopicType {
    const data = {
      name,
      description,
      patient_id,
      topic_type,
      public_dialog_id,
      comments: 0,
      user_id,
      user_name,
      is_hidden: false,
    };
    return data;
  },
  buildCustomSurvey(survey_question: string, survey_ans: Array<string>, parent_id: string): customSurveyType {
    const survey_votes = survey_ans.map(() => 0);

    const data = {
      survey_question,
      survey_ans,
      survey_votes,
      parent_id,
    };
    return data;
  },
};
