import React, { useState, useEffect, FC, useLayoutEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { Redirect, useHistory } from 'react-router-dom';
// components
import AllocareHeader from '../../../containers/AllocareHeader/AllocareHeader';
import Notification from '../../../containers/Notification/Notification';

// types
import { OktaSignInConfigType } from '../../../../types/app';
import css from './emailauth.module.css';
import authTransition from '../../../transitions/authTransition.module.css';
import { apiApp } from '../../../../api/app';
import AMAlreadyLinked from './SignIn/AMAlreadyLinked';
import AllocareSignin from './SignIn/AllocareSignin';
import AllocareSignup from './Signup/AllocareSignup';
import CareDxLogo from './CareDxLogo.svg';
import AMBadLink from './AMBadLink';

import { getStringConstants } from '../../../../config/localization';

interface AuthPageProps {
  config: OktaSignInConfigType;
  urlParams: any;
}

interface LandingPageState {
  allocareEmailExists: any;
  allocareMapConnected: any;
  mapInfo: any;
}

const AuthPage: FC<AuthPageProps> = ({ config, urlParams }: AuthPageProps) => {
  const [forcedScenario, setForcedScenario] = React.useState<false | 'SIGN_IN' | 'SIGN_UP'>(false);
  const [landingData, setLandingData] = useState<LandingPageState>({
    allocareEmailExists: null,
    allocareMapConnected: null,
    mapInfo: null,
  });

  const strings = getStringConstants();
  const { oktaAuth } = useOktaAuth();
  const dispatch = useDispatch();
  const history = useHistory();

  async function getLandingData(mapToken: string) {
    const mapInfo = (await apiApp.getMapInfo(mapToken))?.data;
    if (!mapInfo.expired) {
      const allocareInfo = (await apiApp.getAllocareEmailExists(mapInfo.email))?.data;
      return {
        allocareEmailExists: allocareInfo?.exists,
        allocareMapConnected: allocareInfo?.isMap,
        mapInfo,
      };
    } else {
      return {
        allocareEmailExists: false,
        allocareMapConnected: false,
        mapInfo,
      };
    }
  }
  useLayoutEffect(() => {
    (async () => {
      const data = await getLandingData(urlParams.mapid);
      setLandingData(data);
      if (await oktaAuth.session.exists()) {
        await oktaAuth.closeSession();
        oktaAuth.tokenManager.clear();
      }
    })();
  }, []);

  const SCENARIO = (function () {
    const stillLoading = landingData.allocareEmailExists == null;
    const alreadyLinked = landingData.allocareMapConnected;
    const scenario = {
      STILL_LOADING: stillLoading,
      ALREADY_LINKED: alreadyLinked,
      SIGN_UP: !landingData?.mapInfo?.expired && !stillLoading && !alreadyLinked && !landingData.allocareEmailExists,
      SIGN_IN: !landingData?.mapInfo?.expired && !stillLoading && !alreadyLinked && landingData.allocareEmailExists,
      BAD_LINK: !stillLoading && !alreadyLinked && landingData.mapInfo.expired,
    };
    if (forcedScenario == 'SIGN_IN') {
      scenario.SIGN_IN = true;
      scenario.SIGN_UP = false;
    } else if (forcedScenario == 'SIGN_UP') {
      scenario.SIGN_UP = true;
      scenario.SIGN_IN = false;
    }

    return scenario;
  })();
  return (
    <div className={css.authWrapper}>
      <AllocareHeader />
      <Notification />
      <section className={css.authSection}>
        {SCENARIO.STILL_LOADING && <div>{strings.LOADING}</div>}
        {SCENARIO.ALREADY_LINKED && (
          <AMAlreadyLinked firstName={landingData.mapInfo.firstName} lastName={landingData.mapInfo.lastName} />
        )}
        {SCENARIO.SIGN_IN && (
          <AllocareSignin
            oktaConfig={config}
            mapToken={urlParams.mapid}
            mapInfo={landingData.mapInfo}
            signUp={() => {
              setForcedScenario('SIGN_UP');
            }}
          />
        )}
        {SCENARIO.SIGN_UP && (
          <AllocareSignup
            mapToken={urlParams.mapid}
            mapInfo={landingData.mapInfo}
            oktaConfig={config}
            signIn={() => {
              setForcedScenario('SIGN_IN');
            }}
          />
        )}
        {SCENARIO.BAD_LINK && <AMBadLink />}
      </section>
      <div className={css.broughtByCareDxWrapper}>
        <div className={css.broughtByCareDxText}>{strings.BROUGHT_TO_YOU_BY}</div>
        <img className={css.broughtByCareDxLogo} src={CareDxLogo}></img>
      </div>
    </div>
  );
};

export default AuthPage;
