import { all, call, delay, put } from 'redux-saga/effects';
// api
import { apiUsers } from '../../api/users';
// actions
import appAction from '../actions/app';
import usersAction from '../actions/users';
// types
import {
  getUsersListActionReturnType,
  searcUsersActionReturnType,
  userStatusActionReturnType,
} from '../../types/actions/users';
// helpers
import { handleLoginRedirect } from './helpers';

export function* sagaGetUsers(action: getUsersListActionReturnType): any {
  try {
    yield put(appAction.appNotificationClear());
    yield delay(1);
    yield put(usersAction.usersLoading(false, 0));
    yield put(usersAction.clearUsersList());
    yield put(usersAction.usersLoading(true, 90));
    const filteredUsersIds = Array.from(new Set(action.data));
    const responseUsers = yield all(
      filteredUsersIds.map((item: any) => {
        const response = apiUsers.getUser(item);
        return response;
      }),
    );
    const responseBannedUsers = yield call(apiUsers.getBannedUsers);
    const filteredResponseUsers = responseUsers.map((item: any) => {
      return {
        ...item.data.user,
        status: responseBannedUsers.data.list.find((bannedUser: string) => bannedUser === String(item.data.user.id))
          ? true
          : false,
      };
    });

    yield put(usersAction.usersLoading(true, 100));
    yield put(usersAction.getUsersList(filteredResponseUsers));
    yield delay(1000);
    yield put(usersAction.usersLoading(false, 0));
  } catch (e) {
    console.log(e);
    if (e.response.status === 401) {
      handleLoginRedirect();
    } else {
      yield put(usersAction.usersLoading(true, 100));
      yield put(usersAction.usersLoading(false, 100));
      yield put(
        appAction.appNotification(
          true,
          {
            header: 'Error',
            message:
              e.response.data.message ||
              (e.response.data.errors && e.response.data.errors[0]) ||
              (e.response.data.errors.base && e.response.data.errors.base[0]) ||
              (e.response.data.errors.id && e.response.data.errors.id[0]) ||
              'Error',
          },
          'Error',
        ),
      );
      yield delay(1000);
      yield put(usersAction.usersLoading(false, 0));
    }
  }
}

export function* sagaSearchUsers(action: searcUsersActionReturnType): any {
  yield delay(1);
  yield put(usersAction.usersLoading(false, 0));
  yield put(usersAction.usersLoading(true, 90));
  yield delay(1);
  yield put(usersAction.usersLoading(true, 100));
  yield delay(750);
  action.callback();
  yield delay(100);
  yield put(usersAction.usersLoading(false, 0));
}

export function* sagaChangeUserStatus(action: userStatusActionReturnType): any {
  try {
    yield delay(1);
    yield put(usersAction.userStatusLoading(false, 0));
    yield put(appAction.appNotificationClear());
    yield put(usersAction.userStatusLoading(true, 90));
    const responseBannedUsers = yield call(apiUsers.getBannedUsers);
    // check if user is already banned/unbanned
    const isSameStatus =
      (action.status && responseBannedUsers.data.list.find((item: any) => item === String(action.id))) ||
      (!action.status && !responseBannedUsers.data.list.find((item: any) => item === String(action.id)));
    // if user is not already banned/unbanned
    if (!isSameStatus) {
      const result = action.status
        ? [...responseBannedUsers.data.list, String(action.id)]
        : responseBannedUsers.data.list.filter((item: string) => item !== String(action.id));

      apiUsers.changeUserStatus(result);
    }
    yield put(usersAction.userStatusLoading(true, 100));
    yield delay(500);
    yield put(usersAction.userStatusLoading(false, 0));
    yield put(
      appAction.appNotification(
        true,
        {
          header: !isSameStatus ? 'Success' : 'Warning',
          message: !isSameStatus
            ? `User ${action.status ? 'banned' : 'unbanned'} successfully`
            : `User is already ${action.status === true ? 'banned' : 'unbanned'}`,
        },
        !isSameStatus ? 'Success' : 'Warning',
      ),
    );
    // change user user status for redux store
    const usersList = action.users.map((item) => {
      return { ...item, status: item.id === action.id ? action.status : item.status };
    });
    yield delay(10);
    action.callback();
    yield delay(10);
    yield put(usersAction.getUsersList(usersList));
  } catch (e) {
    if (e.response.status === 401) {
      handleLoginRedirect();
    } else {
      yield put(usersAction.usersLoading(true, 100));
      yield put(usersAction.usersLoading(false, 100));
      yield put(
        appAction.appNotification(
          true,
          {
            header: 'Error',
            message:
              e.response.data.message ||
              (e.response.data.errors && e.response.data.errors[0]) ||
              (e.response.data.errors.base && e.response.data.errors.base[0]) ||
              (e.response.data.errors.id && e.response.data.errors.id[0]) ||
              'Error',
          },
          'Error',
        ),
      );
      yield delay(1000);
      yield put(usersAction.usersLoading(false, 0));
    }
  }
}
