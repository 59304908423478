import React from 'react';
// components
import SortButton from '../../../components/buttons/sortButton/SortButton';
// style
import css from '../../../components/containers/Table/table.module.css';

export const columns = [
  {
    className: css.topicType,
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    className: css.topicName,
    title: 'Category',
    dataIndex: 'topic_name',
    key: 'topic_name',
    ellipsis: true,
  },
  {
    className: css.topicDescription,
    title: 'Topic',
    dataIndex: 'description',
    key: 'description',
  },
  {
    className: css.topicAuthor,
    title: 'Creator',
    dataIndex: 'author',
    key: 'author',
  },
  {
    className: css.topicParticipants,
    title: 'Participants',
    dataIndex: 'participants',
    key: 'participants',
  },
  {
    className: css.topicComments,
    title: 'Comments',
    dataIndex: 'comments',
    key: 'comments',
  },
  {
    className: css.topicLikes,
    title: 'Likes',
    dataIndex: 'likes',
    key: 'likes',
  },
  {
    className: css.topicIsHidden,
    title: 'Status',
    dataIndex: 'is_hidden',
    key: 'is_hidden',
  },
  {
    className: css.topicCreatedAt,
    title: (
      <div className={css.columnHeaderWrapper}>
        <span className={css.columnHeader}>Created at</span>
        <SortButton sortValue={'created_at'} />
      </div>
    ),
    dataIndex: 'created_at',
    key: 'created_at',
  },
  {
    className: css.topicUpdatedAt,
    title: (
      <div className={css.columnHeaderWrapper}>
        <span className={css.columnHeader}>Last updated</span>
        <SortButton sortValue={'updated_at'} />
      </div>
    ),
    dataIndex: 'updated_at',
    key: 'updated_at',
  },
];
