import React, { FC } from 'react';
// types
import { messageType } from '../../../../types/messages';
import { loadingType } from '../../../../types/actions/app';
// style
import css from './message.module.css';

interface MessageProps {
  message: messageType;
  loading: loadingType;
  mainLoading: boolean;
  deleteMessageCallback: (id: string) => void;
}

const Message: FC<MessageProps> = ({ message, loading, mainLoading, deleteMessageCallback }: MessageProps) => {
  // formatting time for message
  const handleTime = (time: number) => {
    const date = new Date(parseInt((time * 1000).toString()));
    return date.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <div className={css.messageWrapper}>
      <div className={`${message.reported === 'true' ? css.reported : message.type ? css[message.type] : css.message}`}>
        <div className={css.messageTop}>
          <span className={css.messageUserName}>
            {message.type === 'admin' ? 'System message' : message.sender_name}
          </span>
          <span>{handleTime(message.date_sent)}</span>
        </div>
        <div className={`${message.reported === 'true' ? css.messageTextReported : css.messageText}`}>
          <span>{message.message}</span>
        </div>
      </div>
      <div
        className={`${loading.status || mainLoading ? css.messageDeleteIconDisabled : css.messageDeleteIcon}`}
        onClick={() => (loading.status || mainLoading ? null : deleteMessageCallback(message._id))}
      />
    </div>
  );
};

export default Message;
