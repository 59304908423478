import { put, call, all, delay } from 'redux-saga/effects';
// api
import { apiMessages } from '../../api/messages';
import { apiUsers } from '../../api/users';
import { apiTopic } from '../../api/topic';
// actions
import appAction from '../actions/app';
import messagesAction from '../actions/messages';
// types
import {
  deleteMessageActionReturnType,
  getMessagesListActionReturnType,
  sendMessageActionReturnType,
} from '../../types/actions/messages';
// helpers
import { handleLoginRedirect } from './helpers';

export function* sagaGetMessages(action: getMessagesListActionReturnType): any {
  try {
    yield put(appAction.appNotificationClear());
    yield put(messagesAction.messagesListLoading(false, 0));
    yield put(messagesAction.messagesListLoading(true, 0));
    yield delay(1);
    yield put(messagesAction.messagesListLoading(true, 70));
    const responseMessages = yield call(apiMessages.getMessages, action.data);
    const filteredIds = Array.from(new Set(responseMessages.data.items.map((item: any) => item.sender_id)));
    const responseTotalMessages = action.data.total_entries
      ? { data: { items: { count: action.data.total_entries } } }
      : yield call(apiMessages.getMessages, action.data, true);
    yield put(messagesAction.messagesListLoading(true, 90));
    const responseUserNames = yield all(
      filteredIds.map((item: any) => {
        const response = apiUsers.getUser(item);
        return response;
      }),
    );
    yield put(messagesAction.messagesListLoading(true, 100));
    yield delay(1000);
    const messagesList = responseMessages.data.items.map((item: any) => {
      return {
        ...item,
        sender_name: responseUserNames.find((user: any) => {
          return item.sender_id == user.data.user.id;
        }).data.user.full_name,
      };
    });
    const resultMessagesList = action.data.messages ? [...action.data.messages, ...messagesList] : messagesList;
    yield put(
      messagesAction.getMessagesList({
        skip: action.data.skip,
        limit: 100,
        items: resultMessagesList,
        total_entries: responseTotalMessages.data.items.count,
      }),
    );
    yield put(messagesAction.messagesListLoading(false, 0));
  } catch (e: any) {
    if (e.response.status === 401) {
      handleLoginRedirect();
    } else {
      yield put(messagesAction.messagesListLoading(true, 100));
      yield put(messagesAction.messagesListLoading(true, 100));
      yield put(
        appAction.appNotification(
          true,
          {
            header: 'Error',
            message: e.response.data.message || e.response.data.errors[0] || e.response.data.errors.base[0],
          },
          'Error',
        ),
      );
      yield delay(1000);
      yield put(messagesAction.messagesListLoading(false, 0));
    }
  }
}

export function* sagaSendMessage(action: sendMessageActionReturnType): any {
  try {
    yield put(appAction.appNotificationClear());
    yield delay(1);
    yield put(messagesAction.sendMessageLoading(false, 0));
    yield put(messagesAction.sendMessageLoading(true, 90));
    yield call(apiMessages.sendMessage, action.data);
    yield put(messagesAction.sendMessageLoading(true, 100));
    yield delay(1000);
    action.callback();
    yield put(
      messagesAction.getMessagesListAction({
        dialog_id: action.data.chat_dialog_id,
        search_value: '',
        reported: false,
        sender_id: '',
        skip: 0,
        limit: 100,
        total_entries: 0,
      }),
    );
    yield put(messagesAction.sendMessageLoading(false, 0));
    yield put(appAction.appNotification(true, { header: 'Success', message: 'Message sent' }, 'Success'));
  } catch (e: any) {
    if (e.response.status === 401) {
      handleLoginRedirect();
    } else {
      yield put(messagesAction.sendMessageLoading(true, 100));
      yield put(messagesAction.sendMessageLoading(true, 100));
      yield put(
        appAction.appNotification(
          true,
          {
            header: 'Error',
            message: e.response.data.message || e.response.data.errors[0] || e.response.data.errors.base[0],
          },
          'Error',
        ),
      );
      yield delay(1000);
      yield put(messagesAction.sendMessageLoading(false, 0));
    }
  }
}

export function* sagaDeleteMessage(action: deleteMessageActionReturnType): any {
  try {
    yield put(appAction.appNotificationClear());
    yield delay(1);
    yield put(messagesAction.messagesListLoading(false, 0));
    yield put(messagesAction.messagesListLoading(true, 80));
    yield call(apiMessages.deleteMessage, action.messageData.message_id);
    yield put(messagesAction.messagesListLoading(true, 90));
    const totalMessages = yield call(apiMessages.getMessages, action.data, true);
    yield call(apiTopic.editTopic, action.customTopicId, { comments: totalMessages.data.items.count });
    yield put(messagesAction.messagesListLoading(true, 100));
    const responseMessage = yield call(apiMessages.getMessages, {
      ...action.data,
      skip: action.data.total_entries > action.data.skip + 99 ? action.data.skip + 99 : action.data.total_entries,
      limit: 1,
    });
    const userInfo = responseMessage.data.items[0]
      ? yield call(apiUsers.getUser, responseMessage.data.items[0].sender_id)
      : '';
    const newItems = responseMessage.data.items[0]
      ? [
          ...action.messageData.messages.filter((item: any) => item._id !== action.messageData.message_id),
          { ...responseMessage.data.items[0], sender_name: userInfo.data.user.full_name },
        ]
      : action.messageData.messages.filter((item: any) => item._id !== action.messageData.message_id);
    yield put(
      messagesAction.getMessagesList({
        skip: action.data.skip,
        limit: 100,
        total_entries: action.data.total_entries,
        items: newItems,
      }),
    );
    yield delay(1000);
    yield put(messagesAction.messagesListLoading(false, 0));
    yield put(appAction.appNotification(true, { header: 'Success', message: 'Message deleted' }, 'Success'));
  } catch (e: any) {
    if (e.response.status === 401) {
      handleLoginRedirect();
    } else {
      yield put(messagesAction.messagesListLoading(true, 100));
      yield put(messagesAction.messagesListLoading(true, 100));
      yield put(
        appAction.appNotification(
          true,
          {
            header: 'Error',
            message: e.response.data.message || e.response.data.errors[0] || e.response.data.errors.base[0],
          },
          'Error',
        ),
      );
      yield delay(1000);
      yield put(messagesAction.messagesListLoading(false, 0));
      e.response.data.errors[0] === "The resource wasn't found" && action.callback(action.data);
    }
  }
}
