import * as React from 'react';
import css from '../emailauth.module.css';
import { getStringConstants } from '../../../../../config/localization';
import { Link } from 'react-router-dom';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input/input';
import PasswordInput from '../PasswordInput';
import { last } from 'lodash';

const strings = getStringConstants();

export interface SignupFormProps {
  initialFormValues: SignupFormState;
  onComplete: (formValues: SignupFormState) => void;
  onAlreadyRegisteredClick: () => void;
}

export interface SignupFormState {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string;
  phoneNumber: string;
  password: string;
  consents: boolean;
}

function formatCamelToHumanCase(inStr: string) {
  let outputString = inStr.charAt(0).toUpperCase();
  for (let i = 1; i < inStr.length; i++) {
    const cChar = inStr.charAt(i);
    if (/A-Z/.test(cChar)) {
      outputString += ` ${cChar}`;
    } else {
      outputString += cChar;
    }
  }
  return outputString;
}

function formatToEnglishDate(dateString: string) {
  const splitDateString = dateString.split('-');
  const yearValue = parseInt(splitDateString[0]);
  const monthValue = parseInt(splitDateString[1]) - 1;
  const dateValue = parseInt(splitDateString[2]);
  const retDate = new Date();
  retDate.setUTCFullYear(yearValue);
  retDate.setUTCMonth(monthValue);
  retDate.setUTCDate(dateValue);
  return retDate.toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
}

function validatePasswordRequirements(password: string, firstName: string, lastName: string) {
  const containsOneUppercase = password.split('').some((x) => x.toUpperCase() === x);
  const containsOneLowercase = password.split('').some((x) => x.toLowerCase() === x);
  const containsOneSpecialChar = /[#?!@$%^&*\-]/.test(password);
  const containsOneNumericChar = /\d/.test(password);

  let retMessage = '';
  if (password.length < 8) {
    retMessage = strings.SU_PW_NEED_BLANK_MORE_CHARS.replace('${0}', `${8 - password.length}`);
  } else if (password.length > 55) {
    retMessage = strings.SU_PW_NEED_BLANK_LESS_CHARS.replaceAll('${0}', `${password.length - 55}`);
  } else if (!containsOneUppercase) {
    retMessage = strings.SU_PW_NEED_UPPERCASE;
  } else if (!containsOneLowercase) {
    retMessage = strings.SU_PW_NEED_LOWERCASE;
  } else if (!containsOneSpecialChar) {
    retMessage = strings.SU_PW_NEED_SPECIAL;
  } else if (!containsOneNumericChar) {
    retMessage = strings.SU_PW_NEED_NUMERIC;
  } else if (passwordContainsName(password, firstName, lastName))
    // strings.SU_PW_CONTAINS_NAME
    retMessage = strings.SU_PW_CONTAINS_NAME;
  if (retMessage != '') {
    retMessage = `${strings.PASSWORD_REQUIREMENTS_NOT_MET} ${retMessage}`;
  }
  return retMessage;
}

function passwordContainsName(password: string, firstName: string, lastName: string) {
  // turn password to lowercase
  const passwordLC = password.toLowerCase();
  // turn first and lastname to lower case
  let firstNameLC = '';
  if (firstName != '') {
    firstNameLC = firstName.toLowerCase();
  }
  let lastNameLC = '';
  if (lastName != '') {
    lastNameLC = lastName.toLowerCase();
  }
  // check if password includes firstName or lastName
  return passwordLC.includes(firstNameLC) || passwordLC.includes(lastNameLC);
}

export default function SignupForm(props: SignupFormProps) {
  const [formState, setFormState] = React.useState<SignupFormState>(props.initialFormValues);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const firstName = formState.firstName;
  const lastName = formState.lastName;

  function validate(ev: any) {
    ev.preventDefault();

    const validation = Object.keys(formState).map((formStateKey) => {
      const formStateValue = formState[formStateKey];
      const ret = { key: formStateKey, value: formStateValue, valid: false, errorMessage: '' };
      if (typeof formStateValue == 'string') {
        if (formStateValue.length > 0) {
          if (formStateKey == 'phoneNumber') {
            if (formStateValue.length < 9) {
              ret.valid = false;
              ret.errorMessage = strings.CONF_PLEASE_ENTER_PHONE;
            } else {
              ret.valid = true;
            }
          } else if (formStateKey == 'password') {
            const validatePassword = validatePasswordRequirements(formStateValue, firstName, lastName);
            ret.valid = validatePassword == '';
            ret.errorMessage = validatePassword;
          } else {
            ret.valid = true;
          }
        } else {
          ret.valid = false;
          ret.errorMessage = strings.REQUIRED + ' ' + formatCamelToHumanCase(formStateKey);
        }
      } else if (formStateKey == 'consents') {
        if (formStateValue) {
          ret.valid = true;
        } else {
          ret.valid = false;
          ret.errorMessage = `${strings.REQUIRED} ${strings.TERMS_OF_USE_AGREEMENT}`;
        }
      } else {
        ret.valid = true;
      }
      return ret;
    });

    if (validation.every((x) => x.valid)) {
      props.onComplete(formState);
    } else {
      // validation is an array, filtering out "Required"
      const required = `${strings.REQUIRED}: `;
      const errorMessageA = validation
        .filter((x) => x.errorMessage.includes(strings.REQUIRED))
        .reduce((acc, curr, i) => {
          if (curr.errorMessage.length > 0) {
            acc += `${i == 0 ? '' : ','}${curr.errorMessage.replace(strings.REQUIRED, '')}`;
          }
          return acc;
        }, required);
      const errorMessageB = validation
        .filter((x) => !x.errorMessage.includes(strings.REQUIRED))
        .reduce((acc, curr) => {
          if (curr.errorMessage.length > 0) {
            acc += `${curr.errorMessage}`;
          }
          return acc;
        }, '');
      // fix displaying a dot in here
      const errorMessage = errorMessageA != required ? `${errorMessageA}. ${errorMessageB}` : errorMessageB;
      setErrorMessage(errorMessage);
    }
  }

  return (
    <div className={css.cInputArea}>
      <div className={css.titleSubtitle} style={{ height: '45px' }}>
        <div className={css.yesIWantToConnect}>
          <div style={{ paddingLeft: '10px' }}>{strings.SIGN_UP_FOR_ALLOCARE}</div>
        </div>
      </div>
      <form onSubmitCapture={validate} style={{ marginLeft: '20px' }}>
        <div className={css.formItem}>
          <label className={css.formItemLabel} htmlFor="firstName">
            {strings.FIRST_NAME}
          </label>
          <div className={css.formItemValue}>
            <input
              style={{ width: '100%', border: 'none', fontSize: 'large' }}
              type="text"
              name="firstName"
              value={formState.firstName}
              onChange={(x) => setFormState({ ...formState, firstName: x.target.value })}
            />
          </div>
        </div>
        <div className={css.formItem}>
          <label className={css.formItemLabel} htmlFor="lastName">
            {strings.LAST_NAME}
          </label>
          <div className={css.formItemValue}>
            <input
              style={{ width: '100%', border: 'none', fontSize: 'large' }}
              type="text"
              name="lastName"
              value={formState.lastName}
              onChange={(x) => setFormState({ ...formState, lastName: x.target.value })}
            />
          </div>
        </div>

        <div className={css.formItem}>
          <label className={css.formItemLabel} htmlFor="email">
            {strings.EMAIL_ADDRESS}
          </label>
          <div className={css.formItemValue}>
            <input
              style={{ width: '100%', border: 'none', fontSize: 'large' }}
              type="text"
              name="email"
              value={formState.email}
              onChange={(x) => setFormState({ ...formState, email: x.target.value })}
            />
          </div>
        </div>
        <div className={css.formItem}>
          <label className={css.formItemLabel} htmlFor="dob">
            {strings.DATE_OF_BIRTH}
          </label>
          <div className={css.formItemValue}>
            <input
              className={`CONF_DOB_INPUT`}
              style={{ width: '100%', border: 'none', fontSize: 'large' }}
              type="date"
              name="dob"
              placeholder={strings.CONF_PLEASE_ENTER_DOB}
              value={formState.dateOfBirth}
              onChange={(x) => setFormState({ ...formState, dateOfBirth: x.target.value })}
              onFocus={() => {
                const input = document.querySelector('.CONF_DOB_INPUT');
                if (input) {
                  (input as HTMLInputElement).showPicker();
                }
              }}
            />
          </div>
        </div>
        <div className={css.formItem}>
          <label className={css.formItemLabel} htmlFor="phone">
            {strings.PHONE_NUMBER}
          </label>
          <div className={css.formItemValue}>
            <PhoneInput
              style={{ width: '100%', border: 'none', fontSize: 'large' }}
              country="US"
              value={'+1' + formState.phoneNumber}
              onChange={(x) => {
                if (x && typeof x == 'string' && x.length > 0) {
                  setFormState({ ...formState, phoneNumber: x?.replace('+1', '') });
                } else {
                  setFormState({ ...formState, phoneNumber: '' });
                }
              }}
            />
          </div>
        </div>
        <div className={css.formItem}>
          <label className={css.formItemLabel} htmlFor="pass">
            {strings.PASSWORD}
          </label>
          <div className={css.formItemValue}>
            <PasswordInput
              value={formState.password}
              onChange={(newValue) => setFormState({ ...formState, password: newValue })}
            />
            {/* <input
              style={{ width: '100%', border: 'none', fontSize: 'large' }}
              type="password"
              name="pass"
              placeholder={strings.PASSWORD}
              value={formState.password}
              onChange={(x) => setFormState({ ...formState, password: x.target.value })}
            /> */}
          </div>
        </div>
        <div className={css.formItem} style={{ height: '60px' }}>
          <div className={css.formItemLabel} style={{ fontSize: '15px', display: 'inline-block' }}>
            <input
              style={{ border: 'none' }}
              type="checkbox"
              value={formState.consents ? 'checked' : ''}
              onChange={(x) => {
                setFormState({ ...formState, consents: !formState.consents });
              }}
            />

            {`  ${strings.SU_BY_SIGNING} `}
            <a
              onClick={() => {
                window.open('https://caredx.com/terms-allocare/');
              }}
              style={{ cursor: 'pointer', fontSize: '15px' }}
            >
              {`${strings.TERMS_OF_USE} `}
            </a>
            {` ${strings.AND} `}
            <a
              onClick={() => {
                window.open('https://caredx.com/privacy-allocare/');
              }}
              style={{ cursor: 'pointer', fontSize: '15x' }}
            >
              {`${strings.PRIVACY_POLICY} `}
            </a>
          </div>
        </div>
        <div className={css.titleSubtitle} style={{ height: '80px' }}>
          {errorMessage.length == 0 && (
            <div className={css.weRetrieved}>
              <div style={{ paddingLeft: '10px' }}>{strings.SU_WE_RETRIEVED}</div>
            </div>
          )}
          {errorMessage.length > 0 && (
            <div style={{ color: 'red' }}>
              <div style={{ paddingLeft: '10px' }}>{errorMessage}</div>
            </div>
          )}
        </div>
        <div className={css.formItem}>
          <input
            className={css.formItemButton}
            style={{ color: 'white', border: 'none', cursor: 'pointer' }}
            type="submit"
            value={strings.CREATE_AN_ACCOUNT}
          />
        </div>
        <div className={css.formItem} style={{ height: 'unset', textAlign: 'center', marginTop: '-10px' }}>
          <span style={{ width: '100%' }}>
            <a style={{ cursor: 'pointer' }} onClick={props.onAlreadyRegisteredClick}>
              {strings.ALREADY_REGISTERED}
            </a>
          </span>
        </div>
        <div className={css.formItem} style={{ paddingLeft: '2em', fontSize: 'small' }}>
          {strings.SIGNIN_ANY_QUESTIONS}
        </div>
      </form>
    </div>
  );
}
