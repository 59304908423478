import { loadingType, notificationType } from '../../types/actions/app';
import { filtersType } from '../../types/app';

const initialState = {
  loading: {
    status: false,
    progress: 0,
  },
  notification: {
    status: false,
    type: '',
    message: {
      header: '',
      body: '',
    },
  },
  filters: {
    skip: 0,
    limit: 12,
    search_value: '',
    topic_type: '',
    name: '',
    description: '',
    sort: null,
  },
};

const app = (
  state = initialState,
  action: {
    type: string;
    payload: { notification: notificationType; loading: loadingType; filters: filtersType };
  },
): { notification: notificationType; loading: loadingType; filters: filtersType } => {
  switch (action.type) {
    case 'APP_NOTIFICATION':
      return Object.assign({}, state, {
        notification: action.payload.notification,
      });
    case 'APP_FILTERS':
      return Object.assign({}, state, {
        filters: action.payload.filters,
      });
    case 'APP_LOADING':
      return Object.assign({}, state, {
        loading: action.payload.loading,
      });
    default:
      return state;
  }
};

export default app;
