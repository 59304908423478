import * as React from 'react';
import Confirmation from '../Confirmation';
import AllocareOktaSignUpWidget from './AllocareOktaSignUpWidget';
import AllocareSignupLoading from './AllocareSignupLoading';
import AMThankYou from './AMThankyou';

import SignupForm from './SignupForm';
import { SignupFormState } from './SignupForm';

function generateUUID() {
  // Public Domain/MIT
  let d = new Date().getTime(); //Timestamp
  let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

interface AllocareSignupProps {
  signIn: () => void;
  oktaConfig: any;
  mapToken: string;
  mapInfo: any;
}
export type FilledFormData = false | SignupFormState;

export default function AllocareSignup(props: AllocareSignupProps) {
  const [hasConfirmed, setHasConfirmed] = React.useState(false);
  const [filledFormData, setFilledFormData] = React.useState<FilledFormData>(false);
  const [accessToken, setAccessToken] = React.useState('');
  const [patientId, setPatientId] = React.useState(generateUUID());
  const [completed, setCompleted] = React.useState(false);

  function onFormFilled(formData: FilledFormData) {
    setFilledFormData(formData);
  }

  function onError(message: string) {
    alert(message);
    setFilledFormData(false);
    console.log({
      completed, //false
      accessToken, // should be false
      hasConfirmed, //should be true
      filledFormData, //should be false/0/''
    });
  }

  return (
    <div>
      {!completed && !accessToken && !hasConfirmed && (
        <Confirmation
          isSignup={true}
          allocareDob={''}
          allocarePhone={''}
          mapDob={props.mapInfo.dateOfBirth}
          mapPhone={props.mapInfo.phoneNumber}
          onConfirmed={() => setHasConfirmed(true)}
        />
      )}
      {!completed && !accessToken && hasConfirmed && filledFormData == false && (
        <SignupForm
          initialFormValues={{
            consents: false,
            password: '',
            ...props.mapInfo,
          }}
          onComplete={onFormFilled}
          onAlreadyRegisteredClick={props.signIn}
        />
      )}
      {!completed && !accessToken && filledFormData != false && (
        <AllocareOktaSignUpWidget
          config={props.oktaConfig}
          formData={filledFormData}
          onError={onError}
          onSuccess={setAccessToken}
        />
      )}
      {!completed && accessToken && (
        <AllocareSignupLoading
          filledForm={filledFormData as SignupFormState}
          mapInfo={props.mapInfo}
          mapToken={props.mapToken}
          onCompleted={function () {
            setCompleted(true);
          }}
          patientId={patientId}
          token={accessToken}
        />
      )}
      {completed && <AMThankYou />}
    </div>
  );
}
